import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(private commonHttpService: CommonHttpService) { }

    getNotifications(page, limit) {
        return this.commonHttpService.get('/notifications/user?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit)).then(res => res);
    }
}