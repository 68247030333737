
<div class="appContent">
    <app-header></app-header>
    <div class="contentWrapper">
        <app-module-header title="Organisations" isBackEnabled="false" [menuItems]="bulkMenuItems" (onMenuClicked)="onMenuClicked($event)" (onAdd)="onAdd()" showBulkMode="true" showAddButton="true" showSearch=
            "true" (onSearch)="onSearch($event)"></app-module-header>
            <mat-grid-list cols="12" class="tableHead" rowHeight="50px">
                <mat-grid-tile class="mat-grid-tile-checkbox">
                    <mat-checkbox color="primary" (change)="doSelectAll()" [checked]="isAllChecked()"></mat-checkbox> </mat-grid-tile>
                <mat-grid-tile colspan="3">Name </mat-grid-tile>
                <mat-grid-tile colspan="2">Contact Person</mat-grid-tile>
                <mat-grid-tile colspan="2">Email Address</mat-grid-tile>
                <mat-grid-tile colspan="2">Contact (Primary)</mat-grid-tile>
                <mat-grid-tile colspan="1">Subscription</mat-grid-tile>
                <mat-grid-tile colspan="1" class="tableAction">Action</mat-grid-tile> </mat-grid-list>
            <mat-grid-list cols="12" class="tableRow" rowHeight="60px" *ngFor="let data of organisations">
                <mat-grid-tile class="mat-grid-tile-checkbox">
                    <mat-checkbox color="primary" [checked]="isChecked(data._id)" (change)="doSelect(data._id)"></mat-checkbox> </mat-grid-tile>
                <mat-grid-tile colspan="3">
                    <h2>{{data.name}}</h2>
                </mat-grid-tile>
                <mat-grid-tile colspan="2">{{data.contact_person}}</mat-grid-tile>
                <mat-grid-tile colspan="2">{{data['email']}}</mat-grid-tile>
                <mat-grid-tile colspan="2">{{data.primary_contact}}</mat-grid-tile>
                <mat-grid-tile colspan="1">{{data.subscription_plan ? data.subscription_plan : 'Not Subscribed'}}</mat-grid-tile>
                <mat-grid-tile colspan="1" class="tableAction">
                    <div *ngIf='data.status == "A"'> <button mat-button class="mat-button-custom" (click)="onEdit(data._id)">            <img src="assets/edit.png" alt="" width="16" />          </button> </div>
                    <div *ngIf='data.status == "P"'> <button mat-button class="mat-button-custom" (click)="onActivateOrg(data._id)">            <img src="assets/edit.png" alt="" width="16" />          </button> </div> <button mat-button class=
                        "mat-button-custom" (click)="openDialog(data._id)">          <img src="assets/delete.png" alt="" width="16" />        </button>  </mat-grid-tile> </mat-grid-list>
            <app-paginate [length]='totalCount'  pageSize="10" (onPageChange)="onPageChange($event)"></app-paginate> </div></div>