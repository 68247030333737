
<div class="modal-content">
    <div class="modal-header">
        <div class="pull-left">
            <h2>Add New Scenes</h2> <small>Search and add new scenes</small> </div>
        <div class="pull-right">
            <div class="searchBar">
                <form (ngSubmit)="onSearch()">
                    <mat-form-field class="example-full-width"> <input type="text" matInput placeholder="Search" [(ngModel)]=searchQry name="searchQry" />
                        <mat-icon matSuffix>search</mat-icon> </mat-form-field> </form> </div> </div> </div>
    <div class="clearfix"></div>
    <div class="sceneModalFilter">
        <div class="pull-left filter">
            <mat-label>Dimension</mat-label>
            <mat-select [ngModel]="dimension" (ngModelChange)="onChangeDimension($event)">
                <mat-option value="all">All</mat-option>
                <mat-option [value]="item.name" *ngFor="let item of dimensions">{{item.name + ' - ' + item.width + 'px by ' + item.height + 'px'}}</mat-option> </mat-select> </div>
        <div class="pull-left filter">
            <mat-label>Sort By</mat-label>
            <mat-select [ngModel]="sort" (ngModelChange)="onChangeSort($event)">
                <mat-option value="dateDesc">Date Newer</mat-option>
                <mat-option value="dateAsc">Date Oldest</mat-option>
                <mat-option value="name">Name</mat-option>
                <mat-option value="durationAsc">Shorter Duration</mat-option>
                <mat-option value="durationDesc">Long Duration</mat-option> </mat-select> </div>
        <div class="clearfix"></div> </div>
    <div class="clearfix"></div>
    <mat-progress-bar mode="indeterminate" *ngIf='isLoading'></mat-progress-bar>
    <div class="modal-body" mat-dialog-content>
        <mat-grid-list cols="6" rowHeight="180px" gutterSize="24">
            <mat-grid-tile colSpan="1" class="matGridTile" *ngFor="let scene of sceneItems">
                <div class="scene" (click)="selectScene(scene)">
                    <div class="scene-thumb" [ngStyle]="{'background-image': 'url(' + scene.thumbnail + ')' }" style=
                        "background-size: 100%; background-repeat: no-repeat; background-position: center; border: #dcdcdc solid 1px; background-color: #ebebeb;">
                        <mat-icon class="inactive" *ngIf="!hasArrayValue(selectedScenes, scene)">check_circle_outline</mat-icon>
                        <mat-icon class="active" *ngIf="hasArrayValue(selectedScenes, scene)">check_circle</mat-icon> </div>
                    <div class="scene-info">
                        <h3 class="pull-left">{{ scene.name }}</h3> <span class="pull-right">{{ scene.duration | minuteSeconds }}</span> </div> </div> </mat-grid-tile> </mat-grid-list>
        <div class="clearfix"></div>
        <app-paginate *ngIf='sceneItems.length > 0'  [length]=totalCount pageSize="10" (onPageChange)="onPageChange($event)"></app-paginate> </div>
    <div mat-dialog-actions> <button mat-flat-button color="secondary" [mat-dialog-close]="false">      Cancel    </button> <button mat-flat-button color="primary" [mat-dialog-close]="selectedScenes">      Add Scenes    </button> </div></div>