import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { PageEvent } from "@angular/material/paginator";
import { NgxSpinnerService } from 'ngx-spinner';

import { SceneAddModalComponent } from "../modal/modal.component";
import { ScenesService } from "../services/scenes.services";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-scenes",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class SceneListComponent implements OnInit {
  bulkMenuItems: Array<string>;
  crumbItems: Array<Object>;
  tabComponents: Array<Object>;
  data: Array<Object>;
  currentTab: Object;

  searchQry: string = '';
  tabData: Array<Object> = [];
  totalCount: Number = 0;

  constructor(
    private _route: Router,
    public dialog: MatDialog,
    private _service: ScenesService,
    private spinner: NgxSpinnerService
  ) {
    this.bulkMenuItems = ["Delete"];
  }

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Home",
        path: null,
        current: false
      },
      {
        label: "Scenes",
        path: "manage/scene-editor",
        current: true
      }
    ];

    this.tabComponents = [{
      id: 1,
      label: "All",
      query: "all",
      component: "teste"
    },
    {
      id: 2,
      label: "Portrait",
      query: "Portrait"
    },
    {
      id: 3,
      label: "Landscape",
      query: "Landscape"
    }];

    this.currentTab = this.tabComponents[0];

    this.getTabComponents(this.currentTab["query"], 1, 1000, this.searchQry);
  }

  getTabComponents(query, page, limit, searchQry = '') {
    this.spinner.show();
    this._service.getScenes(query, page, limit, searchQry).then((res) => {
      this.tabData = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  tabChange(tab: MatTabChangeEvent) {
    this.currentTab = this.tabComponents[tab.index];
    this.getTabComponents(this.currentTab["query"], 1, 1000);
  }

  onSearch(event: string) {
    this.searchQry = event;
    this.getTabComponents(this.currentTab["query"], 1, 1000, event);
  }

  onPageChange(event: PageEvent) {
    this.getTabComponents(this.currentTab["query"], ++event.pageIndex, event.pageSize, this.searchQry);
  }

  openDialog() {
    const dialogRef = this.dialog.open(SceneAddModalComponent, {
      width: "500px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._route.navigate(["/manage/scene-editor/editor"]);
      }
    });
  }

  openDeleteConfirmationDialog(sceneId: string[]) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let postBody = {
          ids: Array.isArray(sceneId) ? sceneId : [sceneId],
        };
        this._service.deleteScene(postBody);

        this.getTabComponents(this.currentTab["query"], 1, 1000, this.searchQry);
      }
    });
  }

  editScene(sceneId: any) {
    this._route.navigate(["/manage/scene-editor/edit/" + sceneId]);
  }
}
