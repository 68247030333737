import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { SessionStorage } from "../../../services/session-storage";
import { ADMIN, ORG_ADMIN } from "../../../../utils/constants/role-maps";

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})

export class DrawerComponent implements OnInit {

  menuItems: Array<Object>;
  @Input('activeMenu') activeMenu: string; // shud be a label
  currentUrl: string = '';
  permissions: Array<Number> = [];
  userRole: string = '';

  constructor(private _router: Router, private sessionStorageService: SessionStorage) {

    this.userRole = sessionStorageService.getUserRole();
    let permissions = sessionStorageService.getUserPermissions() != 'null' && sessionStorageService.getUserPermissions() != null ? sessionStorageService.getUserPermissions().split(',') : null;
    if(permissions != null && permissions.length > 0) {
      permissions.map((elem, index) => {
        this.permissions.push(parseInt(elem));
      });
    } else {
      this.permissions = null;
    }

    if (this.userRole === ADMIN) {
      this.menuItems = [{
        id: 1,
        label: "Dashboard",
        route: "dashboard",
        icon: "assets/ic-dashboard.png",
        path: '/admin/organisations'
      },
      {
        id: 5,
        label: "Slabs",
        route: "slabs",
        icon: "assets/ic-user.png",
        path: '/admin/slabs'
      },
      {
        id: 2,
        label: "Activity",
        route: "activity",
        icon: "assets/ic-activity.png",
        path: '/activity/admin'
      },
      {
        id: 3,
        label: "Account",
        route: "account",
        icon: "assets/ic-user.png",
        path: '/account/admin'
      },
      {
        id: 4,
        label: "Notifications",
        route: "notification",
        icon: "assets/ic-notifications.png",
        path: '/notification/admin'
      }];
    }

    if (this.userRole === ORG_ADMIN) {
      this.menuItems = [{
        id: null,
        label: "Dashboard",
        route: "dashboard",
        icon: "assets/ic-dashboard.png",
        path: '/dashboard/analytics'
      },
      {
        id: null,
        label: "Manage",
        route: "manage",
        icon: "assets/ic-manage.png",
        path: '/manage/dimensions'
      },
      {
        id: 9,
        label: "Activity",
        route: "activity",
        icon: "assets/ic-activity.png",
        path: '/activity/user'
      },
      {
        id: null,
        label: "Account",
        route: "account",
        icon: "assets/ic-user.png",
        path: '/account/user'
      },
      {
        id: 10,
        label: "Notifications",
        route: "notification",
        icon: "assets/ic-notifications.png",
        path: '/notification/user'
      }];
    }

    let currentUrl = this._router.url.split('/');
    this.currentUrl = currentUrl.length > 0 ? currentUrl[1] : '';
  }

  ngOnInit() { }

}
