import {
  Component,
  OnChanges,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";

@Component({
  selector: "app-html-viewer-widget",
  templateUrl: "./html-viewer.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class HTMLViewerWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Input() widget: Object;

  htmlViewerWidgetProps = {
    name: "HTML Viewer ",
    url: "",
    refreshRate: 60,
  };

  constructor() {}

  ngOnChanges() {
    this.htmlViewerWidgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "HTML Viewer " + this.widget["index"],
      url: (this.widget["props"] && this.widget["props"]["url"]) || "",
      refreshRate:
        (this.widget["props"] && this.widget["props"]["refreshRate"]) || 60,
    };

    this.onSaveProps.emit({
      props: this.htmlViewerWidgetProps,
      showAlert: false,
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.htmlViewerWidgetProps,
      showAlert: true,
    });
  }
}
