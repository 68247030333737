import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class PlayListService {

    constructor(private commonHttpService: CommonHttpService) { }

    getPlaylists(page, limit, searchQry = '') {
        return this.commonHttpService.get('/playlists?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + encodeURIComponent(searchQry)).then(res => res);
    }

    setPlaylist(postBody) {
        return this.commonHttpService.post('/playlists', postBody).then(res => res);
    }

    isPlaylistExists(postBody) {
        return this.commonHttpService.post('/playlists/is_exists', postBody).then(res => res);
    }

    getPlaylist(Id) {
        return this.commonHttpService.get('/playlists/' + encodeURI(Id)).then(res => res);
    }

    updatePlaylist(postBody, Id) {
        return this.commonHttpService.put('/playlists/' + encodeURI(Id) , postBody).then(res => res);
    }

    deletePlaylist(postBody) {
        return this.commonHttpService.delete('/playlists', postBody).then(res => res);
    }

    getScenesBySearch(postBody) {
        return this.commonHttpService.post('/scenes/search', postBody).then(res => res);
    }
}