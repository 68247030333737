import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import * as _ from "underscore";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs/Subject";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs/ReplaySubject";
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from "moment";

import { SchedulerService } from "../services/scheduler.service";

interface Playlist {
  _id: string;
  name: string;
}

@Component({
  selector: "app-scheduler-modal",
  templateUrl: "./scheduler-modal.component.html",
  styleUrls: ["./scheduler-modal.component.scss"],
})
export class SchedulerModal implements OnInit {
  setPeriod = "no-expiry";
  options: Array<Object> = [];
  plOptions: Playlist[] = [];
  filteredPlayerData: Observable<Array<Object>>;
  filteredPLData: Observable<Array<Object>>;
  startsOn: Date = new Date();
  endsOn: Date = new Date();
  recurringStartTime: any = null;
  recurringEndTime: any = null;
  repeatPlaylist: Boolean = true;
  recurringEndDate: any = null;
  recurringStartDate: any = null;

  playlistSelected: Object = {};
  playerSelected: Object = {};
  checkedWeekdays: Array<string> = [];
  hasWeekdayError: boolean = false;
  alertMessage: Object = {
    message: null,
    error: false,
  };
  selectedPlaylists: Array<Object> = [];
  totalDuration: number = 0;
  sortOrder: Array<string> = [];
  isSubmitButtonDisabled: Boolean = false;
  errDummyPlaylist: Boolean = false;

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    player: new UntypedFormControl("", [Validators.required]),
    playlist: new UntypedFormControl("", [Validators.required]),
    playlistMultiCtrl: new UntypedFormControl(""),
    hasExpiry: new UntypedFormControl("no-expiry", [Validators.required]),
    hasTouch: new UntypedFormControl(false, [Validators.required]),
    isDummyPlaylist: new UntypedFormControl(false, [Validators.required]),
    isPublished: new UntypedFormControl(true, [Validators.required]),
    repeat: new UntypedFormControl(true, [Validators.required])
  });

  private _onDestroy = new Subject<void>();
  public filteredPlOptions: ReplaySubject<Playlist[]> = new ReplaySubject<
    Playlist[]
  >(1);

  ngOnInit() {
    Promise.all([
      this._service.getPlayers(1, 100000),
      this._service.getPlaylists(1, 100000),
    ]).then((res) => {
      //  Player Options
      this.options = res[0]["data"] || [];
      this.filteredPlayerData = this.myForm.get("player").valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value === "string" ? value : value["name"])),
        map((name) => (name ? this._filterPlayers(name) : this.options.slice()))
      );

      // Playlist options
      this.plOptions = res[1]["data"] || [];
      this.filteredPlOptions.next(this.plOptions.slice());
    });

    this.myForm
      .get("playlistMultiCtrl")
      .valueChanges.pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPlOptions();
      });

    this.myForm.valueChanges.subscribe((value) => {
      this.selectedPlaylists = (value.playlist != '') ? value.playlist : [];
      this.totalDuration = 0;

      if (this.selectedPlaylists.length > 0) {
        this.selectedPlaylists.map((elem, index) => {
          this.totalDuration = this.totalDuration + elem['duration'];
        });
      }

      if (this.selectedPlaylists && this.selectedPlaylists.length > 1 && value.isDummyPlaylist) {
        this.isSubmitButtonDisabled = true;
        this.errDummyPlaylist = true;
      } else {
        this.isSubmitButtonDisabled = false;
        this.errDummyPlaylist = false;
      }

      this.repeatPlaylist = (this.myForm.value.hasExpiry === "recurring");
      if (this.repeatPlaylist && this.checkedWeekdays.length === 0) {
        this.hasWeekdayError = true;
      }
      this.ref.detectChanges();
    });
  }

  constructor(
    private _service: SchedulerService,
    private dialogRef: MatDialogRef<SchedulerModal>,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) { }

  displayFn(item: Object): string {
    return item && item["name"] ? item["name"] : "";
  }

  private _filterPlayers(name: string): Array<Object> {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      (option) => option["name"].toLowerCase().indexOf(filterValue) === 0
    );
  }

  onPlayerSelected(event) {
    this.playerSelected = event.value;
  }

  onStartDateChange(event) {
    this.startsOn = event.value;
  }

  onEndDateChange(event) {
    this.endsOn = event.value;
  }

  onRecurringStartTimeChange(event) {
    this.recurringStartTime = event.value;
  }

  onRecurringEndTimeChange(event) {
    this.recurringEndTime = event.value;
  }

  onRecurringStartDateChange(event) {
    this.recurringStartDate = event.value;
  }

  onRecurringEndDateChange(event) {
    this.recurringEndDate = event.value;
  }

  isWeekdayPresent(id) {
    return this.checkedWeekdays.includes(id);
  }

  doSelectWeekday(id) {
    if (this.isWeekdayPresent(id)) {
      this.checkedWeekdays = _.without(this.checkedWeekdays, id);
    } else {
      this.checkedWeekdays.push(id);
    }
    this.hasWeekdayError =
      this.checkedWeekdays.length === 0 && this.repeatPlaylist ? true : false;
  }

  onSave(): void {

    let playlistsArray = [];
    this.hasWeekdayError = false;
    this.alertMessage["message"] = '';
    this.alertMessage["error"] = false;

    if (this.myForm.value.playlist.length > 0) {
      playlistsArray = this.myForm.value.playlist.map((elem) => {
        return elem._id;
      })
    }
    this.sortOrder = [];
    var sortOrder = document.getElementsByClassName("sort-order-required");
    for (var i = 0; i < sortOrder.length; i++) {
      if (sortOrder[i]['value'] != '' && sortOrder[i]['value']) {
        this.sortOrder.push(sortOrder[i]['value']);
      }
    }

    let postBody = {
      name: this.myForm.value.name,
      player: this.playerSelected["_id"],
      playlist: playlistsArray,
      has_expiry: (this.myForm.value.hasExpiry === "period" || this.myForm.value.hasExpiry === "recurring"),
      repeat: this.myForm.value.repeat,
      has_touch: this.myForm.value.hasTouch,
      is_dummy_playlist_screen: this.myForm.value.isDummyPlaylist,
      weekdays: [],
      start_on: this.startsOn,
      ends_on: this.endsOn,
      allocation_from: 'players',
      is_recurring: false,
      playlist_sort: this.sortOrder,
      is_published: this.myForm.value.isPublished,
      recurring_start_date: (this.recurringStartDate) ? moment(this.recurringStartDate).format('YYYY-MM-DD') : null,
      recurring_end_date: (this.recurringEndDate) ? moment(this.recurringEndDate).format('YYYY-MM-DD') : null,
      recurring_start_time: (this.recurringStartTime) ? moment(this.recurringStartTime).format('HH:mm:ss') : null,
      recurring_end_time: (this.recurringEndTime) ? moment(this.recurringEndTime).format('HH:mm:ss') : null
    };

    if (this.myForm.value.hasExpiry === "no-expiry") {
      delete postBody.start_on;
      delete postBody.ends_on;
    }

    if (this.myForm.value.hasExpiry === "period") {
      // check if start date less than end date
      let mStart = moment(postBody.start_on);
      let mEnd = moment(postBody.ends_on);
      if (!mStart.isBefore(mEnd)) {
        this.alertMessage["message"] = 'End date should be greater than start date';
        this.alertMessage["error"] = true;
        return;
      }
    }

    if (this.myForm.value.hasExpiry === "recurring") {
      postBody.weekdays = this.checkedWeekdays;
      postBody.is_recurring = true;
      delete postBody.start_on;
      delete postBody.ends_on;
    }

    this.repeatPlaylist = (this.myForm.value.hasExpiry === "recurring");

    this._clearErrors();

    if (!this.repeatPlaylist) {
      delete postBody.weekdays;
      delete postBody.recurring_start_date;
      delete postBody.recurring_end_date;
      delete postBody.recurring_start_time;
      delete postBody.recurring_end_time;
    } else if (this.repeatPlaylist && (postBody.weekdays.length === 0 || !this.isValidRecurringSchedule(postBody))) {
      this.hasWeekdayError = true;
      this.alertMessage["message"] = 'Choose weekday, end date and submit the scheduler';
      this.alertMessage["error"] = true;
      return;
    }

    this.spinner.show();
    this._service.setSchedule(postBody).then((res) => {
      if (res["OK"]) {

        this.alertMessage["message"] = res["message"];
        this.alertMessage["error"] = false;

        setTimeout(() => {
          this.dialogRef.close(true);
        }, 100);
      } else {
        this.alertMessage["message"] = res["message"];
        this.alertMessage["error"] = true;
      }
      this.spinner.hide();
    });
  }

  private _clearErrors() {
    this.alertMessage["message"] = '';
    this.alertMessage["error"] = false;
    this.hasWeekdayError = false;
  }

  private isValidRecurringSchedule(postBody: any) {
    return (postBody.recurringStartDate !== null && postBody.recurringEndDate !== null && postBody.recurringStartTime !== null && postBody.recurringEndTime !== null);
  }

  private filterPlOptions() {
    if (!this.plOptions) {
      return;
    }

    let search = this.myForm.get("playlistMultiCtrl").value;
    if (!search) {
      this.filteredPlOptions.next(this.plOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredPlOptions.next(
      this.plOptions.filter(
        (option) => option["name"].toLowerCase().indexOf(search) > -1
      )
    );
  }
}
