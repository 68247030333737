import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { ForgotPasswordService } from "./services/forgot-password.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  
  alertMessage = {
    message: null,
    error: null
  };

  myForm = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required, Validators.email])
  });

  constructor(private _router: Router,  private _service: ForgotPasswordService) { }

  ngOnInit() {
  }

  doLogin(): void {
    this._router.navigate(['/']);
  } 
  
  doCreateAccount(): void {
    this._router.navigate(['/create']);
  }

  doResetPassword() {

    // Validate current password
    this._service.resetUserPassword(this.myForm.value.email).subscribe(res => {
      if(res['OK']) {
        this.alertMessage.message = res['message'];
        this.alertMessage.error = false;
      } else {
        this.alertMessage.message = res['message'];
        this.alertMessage.error = true;
      }
    });
  }

}
