<div class="row">
  <div class="col-xs-12">
    <h2>Slideshow Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="clearfix"></div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" [(ngModel)]="widgetProps.name" />
    </mat-form-field>
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select
        placeholder="Transition Type"
        [(ngModel)]="widgetProps.transition"
      >
        <mat-option value="animate__zoomOut">Zoom</mat-option>
        <mat-option value="animate__fadeOut">Fade</mat-option>
        <mat-option value="animate__fadeOutUp">Fade Up</mat-option>
        <mat-option value="animate__fadeOutTopRight">Fade Top Right</mat-option>
        <mat-option value="animate__bounceOutLeft">Bounce Out Left</mat-option>
        <mat-option value="animate__bounceOutUp">Bounce Out Up</mat-option>
        <mat-option value="animate__bounceOutDown">Bounce Out Down</mat-option>
        <mat-option value="animate__flipOutX">Flip</mat-option>
        <mat-option value="animate__lightSpeedOutLeft">Speed Out</mat-option>
        <mat-option value="animate__slideOutLeft">Slide</mat-option>
        <mat-option value="animate__slideOutDown">Slide Out Down</mat-option>
        <mat-option value="animate__rotateOut">Rotate Out</mat-option>
        <mat-option value="animate__rotateOutUpLeft"
          >Rotate Out Up Left</mat-option
        >
        <mat-option value="animate__zoomOut">Zoom Out</mat-option>
        <mat-option value="animate__zoomOutUp">Zoom Out Up</mat-option>
        <mat-option value="animate__zoomOutDown">Zoom Out Down</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Delay</mat-label>
      <input
        matInput
        placeholder="Delay (in seconds)"
        [(ngModel)]="widgetProps.transition_delay"
      />
    </mat-form-field>
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12 mt10 mb40" *ngIf="widgetProps.bgColor">
    <mat-label class="widget-mat-label">Choose Background Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.bgColor"
      [style.background]="widgetProps.bgColor"
      class="color-picker"
    />
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12 mb10">
    <div class="row">
      <div class="col-xs-6">
        <strong> <mat-label>Add Images</mat-label> </strong>
      </div>
      <div class="col-xs-6 text-right">
        <button
          class="widget-button"
          mat-button
          color="secondary"
          (click)="openAssetsModal()"
        >
          Add
        </button>
      </div>
    </div>
    <div class="row" *ngIf="widgetProps.images.length">
      <div class="col-xs-12">
        <hr class="widget-header mt10 mb10" />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="row" *ngFor="let item of widgetProps.images; let i = index">
          <div class="col-xs-2">
            <img
              class="thumb-bordered"
              [src]="item.thumbnail"
              alt="Alt image..."
            />
          </div>
          <div class="col-xs-8 pt10">{{ item.originalname }}</div>
          <div class="col-xs-2 text-right">
            <button
              mat-button
              class="mat-button-custom"
              (click)="deleteSlideItem(i)"
              title="Delete"
            >
              <img src="assets/delete.png" alt="" width="16" />
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="col-xs-12">
            <hr class="widget-header mt5 mb5" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>