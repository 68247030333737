import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "../../../config";

@Injectable({
  providedIn: "root",
})

export class RegisterUserService {
  constructor(private http: HttpClient) {}

  createUser(postBody: {}) {
    return this.http.post(API_URL + "/register", postBody);
  }
}
