import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';

import { LoginService } from "./services/login.service";
import { SessionStorage } from "../../services/session-storage";
import { USER_DASHBOARD, ADMIN_DASHBOARD, USER_TWO_FACTOR_AUTH } from "../../config";
import { ADMIN, ORG_ADMIN } from "../../../utils/constants/role-maps";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  email: String;
  password: String;
  alertMessage = {
    message: null,
    error: null,
  };

  myForm = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required]), //Validators.email
    password: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _router: Router,
    private loginService: LoginService,
    private sessionStorageService: SessionStorage,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() { }

  doLogin(): void {
    this.spinner.show();
    this.loginService.getUser(this.email, this.password).subscribe((res) => {
      if (res["OK"]) {
        this.sessionStorageService.setIdToken(
          res["data"]["userTokens"]["refreshToken"]
        );
        this.sessionStorageService.setUserData(JSON.stringify(res["data"]));
        if (res['data']['is_admin']) {
          this.sessionStorageService.setUserRole(ADMIN);
          this.sessionStorageService.setUserPermissions(null);
          this._router.navigate([ADMIN_DASHBOARD]);
        } else {
          this.sessionStorageService.setUserRole(ORG_ADMIN);
          this.sessionStorageService.setUserPermissions(res['data']['permissions'] == null ? 'null' : res['data']['permissions']);
          
          if (res['data']['is_two_factor_enabled']) {
            this.sessionStorageService.setUserTwoFactorValidated(false);
            this._router.navigate([USER_TWO_FACTOR_AUTH]);
          } else {
            this.sessionStorageService.setUserTwoFactorValidated(true);
            this._router.navigate([USER_DASHBOARD]);
          }
        }
      } else {
        this.alertMessage.message = 'Invalid Login Credentials';
        this.alertMessage.error = true;
      }
      this.spinner.hide();
    });
  }

  doReset(): void {
    this._router.navigate(["/reset"]);
  }

  doCreateAccount(): void {
    this._router.navigate(["/create"]);
  }
}
