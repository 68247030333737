import { Component, OnChanges, Output, EventEmitter, Input } from "@angular/core";
import { AssetModalWidgetComponent } from "../assets/assets.modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-image-widget",
  templateUrl: "./image.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class ImageWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Output() onAddAsset = new EventEmitter();
  @Input() widget: Object;

  imageWidgetProps = {
    name: "Image ",
    src: "",
    type: "internal",
    align: "",
    fit: "",
    hasBorder: false,
    borderColor: "#333",
    borderWidth: "0",
    backgroundColor: "#333",
    coords: {
      left: 0,
      top: 0,
    },
  };

  constructor(public dialog: MatDialog) {
    //
  }

  ngOnChanges() {
    this.imageWidgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Image " + this.widget["index"],
      src: (this.widget["props"] && this.widget["props"]["src"]) || "",
      type: (this.widget["props"] && this.widget["props"]["type"]) || "internal",
      align: (this.widget["props"] && this.widget["props"]["align"]) || "",
      fit: (this.widget["props"] && this.widget["props"]["fit"]) || "",
      hasBorder:
        (this.widget["props"] && this.widget["props"]["hasBorder"]) || true,
      backgroundColor:
        (this.widget["props"] && this.widget["props"]["backgroundColor"]) || "#333",
      borderColor:
        (this.widget["props"] && this.widget["props"]["borderColor"]) || "#333",
      borderWidth:
        (this.widget["props"] && this.widget["props"]["borderWidth"]) || "0",
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top: (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
    };

    this.onSaveProps.emit({
      props: this.imageWidgetProps,
      showAlert: false,
    });
  }

  openAssetsModal() {
    const dialogRef = this.dialog.open(AssetModalWidgetComponent, {
      width: "900px",
      data: {
        assetType: 'images'
      }
    });

    dialogRef.afterClosed().subscribe((assetObj) => {
      if (assetObj && assetObj.mediaUrl !== '') {
        this.imageWidgetProps.src = assetObj.mediaUrl;
        this.onAddAsset.emit(assetObj.mediaUrl);
      }
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.imageWidgetProps,
      showAlert: true,
    });
  }
}
