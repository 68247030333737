import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";

import { AdminActivityComponent } from "./pages/activity.component";
import { AdminActivityItemComponent } from "./pages/item/activity-item.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    AdminActivityComponent,
    AdminActivityItemComponent
  ] 
})
export class AdminActivityModule {}
