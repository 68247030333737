import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "../../../config";

@Injectable({
  providedIn: "root",
})

export class AccountVerifyService {
  constructor(private http: HttpClient) {}

  verifyAccount(code) {
    return this.http.post(API_URL + "/account/verify", {
      code,
    });
  }
}
