<mat-grid-list cols="2" rowHeight="100%">
  <mat-grid-tile class="loginBg">
    <div>
      <h1 class="white__color">Hello. Welcome!</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown.
      </p>
      <button class="btnAction" (click)="doCreateAccount()">
        Create an account
      </button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="loginForm">
    <div>
      <h1 class="txtBlack">Issue signing in?</h1>
      <p class="txtBlack">
        Forgot your password, don't worry, we are here to help you
      </p>
      <app-alert
        *ngIf="alertMessage['message']"
        [message]="alertMessage['message']"
        [error]="alertMessage['error']"
      ></app-alert>
      <form
        class="formWrapper"
        [formGroup]="myForm"
        (ngSubmit)="doResetPassword()"
      >
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="Email address"
            name="email"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="myForm.get('email').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('email').hasError('email')"
            >Valid email is required</mat-error
          >
        </mat-form-field>
        <div class="btnFormAction">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            Reset Password
          </button>
          <button mat-flat-button color="secondary" (click)="doLogin()">
            Log In
          </button>
        </div>
      </form>
    </div>
  </mat-grid-tile>
</mat-grid-list>
