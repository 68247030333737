import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { Router } from '@angular/router';
import * as _ from "underscore";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';

import { ZoneEditorService } from "../services/zone-editor.services";
import { CustomModalComponent } from "../../../../../../shared/components/custom-modal/custom-modal.component";

@Component({
  selector: 'app-zone-editor-lists',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ZoneEditorListComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = [
    "id",
    "name",
    "dimension",
    "orientation",
    "action"
  ];

  data: Array<Object>;
  totalCount: number;
  dataSource = new MatTableDataSource();
  checkedList: Array<string> = [];
  searchQry: string = '';

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false
      },
      {
        label: "Zone Editor",
        path: "manage/zone-editor",
        current: true
      }
    ];

    this.getAllZones(1, 10);
  }

  constructor(private _router: Router, private _service: ZoneEditorService, private dialog: MatDialog, private spinner: NgxSpinnerService) { }

  getAllZones(page, limit, searchQry = '') {
    this.spinner.show();
    this._service.getZones(page, limit, searchQry).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  onPageChange(event: PageEvent) {
    this.getAllZones(++event.pageIndex, event.pageSize);
  }

  onSearch(searchQry: any) {
    this.searchQry = searchQry;
    this.getAllZones(1, 10, searchQry);
  }

  onAdd() {
    this._router.navigate(['/manage/zone-editor/create/']);
  }

  openDialog(id: Array<string>) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content:
          "Do you want to delete this zone from listing?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deleteZone(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllZones(1, 10, this.searchQry);
      }
      this.spinner.hide();
    });
  }
}
