import {
  Component,
  OnChanges,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { AssetModalWidgetComponent } from "../assets/assets.modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-text-widget",
  templateUrl: "./text.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class TextWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Output() onAddAsset = new EventEmitter();
  @Input() widget: Object;

  widgetProps = {
    name: "Video ",
    fontSize: 12,
    fontFamily: "Arial, sans-serif",
    text: "",
    color: "#333",
    bold: false,
    fontStyle: "normal",
    underline: false,
    align: "start",
    valign: "start",
    backgroundSrc: "",
    backgroundColor: "#333",
    coords: {
      left: 0,
      top: 0,
    },
  };

  constructor(public dialog: MatDialog) {}

  ngOnChanges() {
    this.widgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Text " + this.widget["index"],
      fontFamily:
        (this.widget["props"] && this.widget["props"]["fontFamily"]) ||
        "Arial, sans-serif",
      fontSize:
        (this.widget["props"] && this.widget["props"]["fontSize"]) || 12,
      text:
        this.widget["props"] && this.widget["props"]["text"]
          ? this.widget["props"]["text"]
          : "",
      color: (this.widget["props"] && this.widget["props"]["color"]) || "#333",
      bold: (this.widget["props"] && this.widget["props"]["bold"]) || false,
      fontStyle:
        (this.widget["props"] && this.widget["props"]["fontStyle"]) || false,
      underline:
        (this.widget["props"] && this.widget["props"]["underline"]) || false,
      align: (this.widget["props"] && this.widget["props"]["align"]) || "start",
      valign:
        (this.widget["props"] && this.widget["props"]["align"]) || "start",
      backgroundSrc:
        (this.widget["props"] && this.widget["props"]["backgroundSrc"]) || "",
      backgroundColor:
        (this.widget["props"] && this.widget["props"]["backgroundColor"]) ||
        "#333",
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top:
          (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
    };

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false,
    });
  }

  openAssetsModal() {
    const dialogRef = this.dialog.open(AssetModalWidgetComponent, {
      width: "800px",
      data: {
        assetType: "images",
      },
    });

    dialogRef.afterClosed().subscribe((assetObj) => {
      if (assetObj && assetObj.mediaUrl !== "") {
        this.widgetProps.backgroundSrc = assetObj.mediaUrl;
        this.onAddAsset.emit(assetObj.mediaUrl);
      }
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true,
    });
  }
}
