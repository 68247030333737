import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';

import { RolesService } from "../services/roles.service";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-user-roles-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class RolesListComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = [
    "_id",
    "name",
    "role_code",
    "permissions",
    "action",
  ];
  data: Array<Object>;
  totalCount: number;
  dataSource = new MatTableDataSource();
  checkedList: Array<string> = [];

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false,
      },
      {
        label: "User Roles",
        path: "manage/user-role",
        current: true,
      },
    ];

    this.getAllRoles(1, 10);
  }

  constructor(
    private _router: Router,
    private _service: RolesService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  getAllRoles(page, limit) {
    this.spinner.show();
    this._service.getRoles(page, limit).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onPageChange(event: PageEvent) {
    this.getAllRoles(++event.pageIndex, event.pageSize);
  }

  doEditRole(roleId): void {
    this._router.navigate(["/manage/user-role/edit/" + roleId]);
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content:
          "Do you want to delete this entry from listing?  You can keep the license for this particular player",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deleteRole(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllRoles(1, 10);
      }
      this.spinner.hide();
    });
  }

  onAdd() {
    this._router.navigate(["/manage/user-role/add"]);
  }
}
