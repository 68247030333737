import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from "@angular/router";
import { SessionStorage } from "../services/session-storage";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorage
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.isLoggedIn()) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }

  public isLoggedIn(): boolean {
    let status = false;
    if (this.sessionStorageService.getIdToken()) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }
}
