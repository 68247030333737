<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Add New - Location"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<form autocomplete="on" [formGroup]="myForm" (ngSubmit)="save()">
  <mat-grid-list cols="12" gutterSize="16">
    <mat-grid-tile colspan="4">
      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="Name"
          name="name"
          formControlName="name"
          autocomplete="off"
        />
        <mat-error *ngIf="myForm.get('name').hasError('required')"
          >Field is required</mat-error
        >
        <mat-error *ngIf="myForm.get('name').hasError('minlength')"
          >Min length should be 5</mat-error
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="Code"
          name="code"
          formControlName="code"
          autocomplete="off"
        />
        <mat-error *ngIf="myForm.get('code').hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="12" rowHeight="50px">
    <mat-grid-tile colspan="4"
      ><button mat-button color="primary" [disabled]="!myForm.valid">
        Submit
      </button></mat-grid-tile
    >
  </mat-grid-list>
</form>