import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class UsersService {

    constructor(private commonHttpService: CommonHttpService) { }

    getUsers(page, limit, searchQry = '') {
        return this.commonHttpService.get('/org_users?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry).then(res => res);
    }

    setUser(postBody) {
        return this.commonHttpService.post('/org_users', postBody).then(res => res);
    }

    getUser(Id) {
        return this.commonHttpService.get('/org_users/' + encodeURI(Id)).then(res => res);
    }

    updateUser(postBody, Id) {
        return this.commonHttpService.put('/org_users/' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteUser(postBody) {
        return this.commonHttpService.delete('/org_users', postBody).then(res => res);
    }
}