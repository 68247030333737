<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  [title]="'Attachment Details -' + assetData['originalname']"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<mat-grid-list cols="12" class="assetSingleRow">
  <mat-grid-tile colspan="8" class="assetViewer">
    <app-file-viewer
      [mimeType]="assetData['mimetype']"
      [filePath]="assetData['mediaUrl']"
      [fileName]="assetData['originalname']"
    ></app-file-viewer>
  </mat-grid-tile>
  <mat-grid-tile colspan="4" class="assetInfo">
    <ul>
      <li><strong>File name:</strong> {{ assetData['originalname'] }}</li>
      <li><strong>File type:</strong> {{ assetData['mimetype'] }}</li>
      <li>
        <strong>Uploaded on:</strong>
        {{ assetData['created_on'] | date: "mediumDate" }}
      </li>
      <li><strong>File size:</strong> {{ assetData['size'] | formatFileSize }}</li>
      <li
        *ngIf="
          assetData['mimetype'] === 'image/jpeg' ||
          assetData['mimetype'] === 'image/jpg' ||
          assetData['mimetype'] === 'image/png'
        "
      >
        <strong>Dimensions:</strong> {{ assetData['width'] }} by
        {{ assetData['height'] }} pixels
      </li>
      <li *ngIf="assetData['mimetype'] === 'video/mp4'">
        <strong>Duration:</strong> 12s
      </li>
    </ul>
    <form class="formWrapper" [formGroup]="myForm" (ngSubmit)="save()">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Asset Name" formControlName="name" />
        <mat-error *ngIf="myForm.get('name').hasError('required')"
          >Field is required</mat-error
        >
        <mat-error *ngIf="myForm.get('name').hasError('minLength')"
          >Minimum length is 5</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-select
          placeholder="Supported Dimension"
          name="dimension"
          formControlName="dimension"
        >
          <mat-option [value]="item._id" *ngFor="let item of dimensions">{{
            item.name
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="myForm.get('dimension').hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <textarea
          matInput
          placeholder="Description"
          formControlName="description"
        ></textarea>
      </mat-form-field>
      <div class="btnFormAction">
        <button mat-flat-button color="primary" [disabled]="!myForm.valid">
          Update Asset
        </button>
        <button
          type="button"
          mat-flat-button
          color="error"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </div>
    </form>
  </mat-grid-tile></mat-grid-list
>
