<mat-grid-list cols="2" rowHeight="100%">
  <mat-grid-tile class="loginBg">
    <div>
      <h1 class="white__color">Hello. Welcome User!</h1>
      <p style="margin: 0px">Get the most out of your Digital Signage.</p>
      <p>Easily create engaging Digital Experience on your Screens.</p>
      <button class="btnAction" (click)="doCreateAccount()">
        Create an account
      </button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="loginForm">
    <div>
      <h1 class="txtBlack">Sign in</h1>
      <p class="txtBlack">Welcome back, user. Hope you had a great time!</p>
      <app-alert
        *ngIf="alertMessage['message']"
        [message]="alertMessage['message']"
        [error]="alertMessage['error']"
      ></app-alert>
      <form class="formWrapper" [formGroup]="myForm" (ngSubmit)="doLogin()">
        <mat-form-field>
          <input
            matInput
            placeholder="Username or email address"
            [(ngModel)]="email"
            name="email"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="myForm.get('email').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('email').hasError('email')"
            >Should be a valid email format, eg: johndoe@example.com</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="Password"
            [(ngModel)]="password"
            name="password"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="myForm.get('password').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
        <div class="btnFormAction">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            Log In
          </button>
          <button mat-flat-button color="secondary" (click)="doReset()">
            Trouble Logging In?
          </button>
        </div>
      </form>
    </div>
  </mat-grid-tile></mat-grid-list
>
