import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class RolesService {

    constructor(private commonHttpService: CommonHttpService) { }

    getRoles(page, limit, searchQry = '') {
        return this.commonHttpService.get('/roles?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry).then(res => res);
    }

    setRole(postBody) {
        return this.commonHttpService.post('/roles', postBody).then(res => res);
    }

    getRole(Id) {
        return this.commonHttpService.get('/roles/' + encodeURI(Id)).then(res => res);
    }

    updateRole(postBody, Id) {
        return this.commonHttpService.put('/roles/' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteRole(postBody) {
        return this.commonHttpService.delete('/roles', postBody).then(res => res);
    }
}