import { Component, OnChanges, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-scene-widget",
  templateUrl: "./scene.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class SceneWidgetComponent implements OnInit, OnChanges {
  @Input() sceneInfo: Object;
  @Output() onSaveProps = new EventEmitter();

  widgetProps = {
    title: '',
    backgroundColor: "#333"
  };

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  ngOnChanges() {
    this.widgetProps = {
      title: (this.widgetProps["props"] && this.widgetProps["props"]["title"]) || '',
      backgroundColor:
        (this.widgetProps["props"] && this.widgetProps["props"]["backgroundColor"]) || '#333',
    };
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false,
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true,
    });
  }
}
