<div class="appContent">
  <app-header></app-header>
  <div class="contentWrapper m0">
    <form autocomplete="on" [formGroup]="myForm" (ngSubmit)="save()">
      <div class="pt20 pl20 pr20 pb20">
        <div class="row">
          <div class="col-xs-6">
            <mat-progress-bar
              *ngIf="isLoading"
              mode="indeterminate"
            ></mat-progress-bar>
            <h3>Profile Information</h3>
            <p>
              You are going to activate this organisation. Once the information
              is set, the user can start using their account
            </p>
            <div *ngIf="!isLoading">
              <app-alert
                *ngIf="alertMessage['message']"
                [message]="alertMessage['message']"
                [error]="alertMessage['error']"
              ></app-alert>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Name"
                      name="name"
                      formControlName="name"
                    />
                    <mat-error *ngIf="myForm.get('name').hasError('required')"
                      >Field is required
                    </mat-error>
                    <mat-error *ngIf="myForm.get('name').hasError('minlength')"
                      >Min length should be 5</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-label>Email Address</mat-label>
                  <p class="strong">{{ data['email'] }}</p>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Organisation ID"
                      name="organisation_id"
                      formControlName="organisation_id"
                    />
                    <mat-error
                      *ngIf="myForm.get('organisation_id').hasError('required')"
                      >Field is required
                    </mat-error>
                    <mat-error
                      *ngIf="
                        myForm.get('organisation_id').hasError('minlength')
                      "
                      >Min length should be 5</mat-error
                    >
                    <mat-error
                      *ngIf="
                        myForm.get('organisation_id').hasError('alreadyExists')
                      "
                      >This Org ID is already exists.</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-12">
                  <h3>Contact Details</h3>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Name"
                      name="contact_person"
                      formControlName="contact_person"
                    />
                    <mat-error
                      *ngIf="myForm.get('contact_person').hasError('required')"
                      >Field is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Primary Contact"
                      name="primary_contact"
                      formControlName="primary_contact"
                    />
                    <mat-error
                      *ngIf="myForm.get('primary_contact').hasError('required')"
                      >Field is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Secondary Contact"
                      name="secondary_contact"
                      formControlName="secondary_contact"
                    />
                    <mat-error
                      *ngIf="
                        myForm.get('secondary_contact').hasError('required')
                      "
                      >Field is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-12">
                  <h3>License Details</h3>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <mat-form-field>
                    <mat-select
                      (selectionChange)="onSlabSelect($event.value)"
                      placeholder="Choose A Slab"
                      name="subscription"
                      formControlName="subscription"
                    >
                      <mat-option [value]="item" *ngFor="let item of slabs"
                        >{{ item.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="myForm.get('subscription').hasError('required')"
                      >Field is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Number of Licenses"
                      name="licence_count"
                      formControlName="licence_count"
                      readonly
                    />
                    <mat-error
                      *ngIf="myForm.get('licence_count').hasError('required')"
                      >Field is required</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb10 mt10" *ngIf="selectedSlab">
                <div class="col-xs-12">
                  <div class="slab-selected-wrapper">
                    <p><strong>Name: </strong> {{ selectedSlab['name'] }}</p>
                    <p>
                      <strong>Users: </strong> {{ selectedSlab['users_count'] }}
                    </p>
                    <p>
                      <strong>Total Licenses: </strong>
                      {{ selectedSlab['license_count'] }}
                    </p>
                    <p>
                      <strong>Storage Allocated: </strong>
                      {{ selectedSlab['cloud_storage'] }}GB
                    </p>
                    <p>
                      <strong>Auto Update: </strong>
                      {{ selectedSlab['is_auto_update_enabled'] }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row mb10 mt10">
                <div class="col-xs-6">
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="!myForm.valid"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Account information-->
          <div class="col-xs-6">
            <div class="row mb10">
              <div class="col-xs-12">
                <h3>Account Details</h3>
              </div>
            </div>
            <div class="row mb10">
              <div class="col-xs-6">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Username for this user"
                    name="username"
                    formControlName="username"
                  />
                  <mat-error *ngIf="myForm.get('username').hasError('required')"
                    >Field is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col-xs-6">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Default Password"
                    name="password"
                    formControlName="password"
                    readonly
                  />
                  <mat-error *ngIf="myForm.get('password').hasError('required')"
                    >Field is required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb10">
              <div class="col-xs-12">
                <h3>Storage Details</h3>
              </div>
            </div>
            <div class="row mb10">
              <div class="col-xs-12">
                <mat-checkbox
                  color="primary"
                  class="pr10 cb-font"
                  formControlName="is_ftp"
                >
                  Enable FTP Storage
                </mat-checkbox>
              </div>
              <div class="col-xs-12" *ngIf="myForm.get('is_ftp').value">
                <div class="row">
                  <div class="col-xs-12">
                    <div class="alert alert-success">
                      Please provide the FTP information
                    </div>
                  </div>
                </div>
                <div class="row mt10 mb10">
                  <div class="col-xs-6">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="FTP Host"
                        name="ftp_host"
                        formControlName="ftp_host"
                      />
                      <mat-error
                        *ngIf="myForm.get('ftp_host').hasError('required')"
                        >Field is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mt10 mb10">
                  <div class="col-xs-6">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="FTP Username"
                        name="ftp_username"
                        formControlName="ftp_username"
                      />
                      <mat-error
                        *ngIf="myForm.get('ftp_username').hasError('required')"
                        >Field is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-xs-6">
                    <mat-form-field>
                      <input
                        type="password"
                        matInput
                        placeholder="FTP Password"
                        name="ftp_password"
                        formControlName="ftp_password"
                      />
                      <mat-error
                        *ngIf="myForm.get('ftp_password').hasError('required')"
                        >Field is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mt10 mb10">
                  <div class="col-xs-6">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="FTP Port"
                        name="ftp_port"
                        formControlName="ftp_port"
                      />
                      <mat-error
                        *ngIf="myForm.get('ftp_port').hasError('required')"
                        >Field is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-xs-12" *ngIf="!myForm.get('is_ftp').value">
                <div class="alert alert-success">
                  If no FTP is chosen, we will go with Google Cloud Storage. The
                  storage size would be based on the subscription chosen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>