import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";

import { ActivityComponent } from "./pages/activity.component";
import { ActivityItemComponent } from "./pages/item/activity-item.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    ActivityComponent,
    ActivityItemComponent
  ] 
})
export class UserActivityModule {}
