
<div class="appContent">
    <app-header></app-header>
    <div class="contentWrapper">
        <app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
        <app-module-header title="Slab Settings" isBackEnabled="true" showBulkMode="false" showAddButton="false"> </app-module-header>
        <div class="app-form-wrapper">
            <div class="row">
                <div class="col-xs-8">
                    <form [formGroup]="myForm" (ngSubmit)="save()">
                        <div class="row mt10 mb10">
                            <div class="col-xs-6">
                                <mat-form-field class="example-full-width"> <input matInput placeholder="Name" name="name" formControlName="name" />
                                    <mat-error *ngIf="myForm.get('name').hasError('required')">Field is required </mat-error>
                                    <mat-error *ngIf="myForm.get('name').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div> </div>
                        <div class="row mt10 mb10">
                            <div class="col-xs-12">
                                <div>
                                    <mat-label>Permissions Required</mat-label> </div>
                                <mat-checkbox [checked]="isChecked(i)" (change)="togglePermissions(i)" color="primary" class="pr10 cb-font" formControlName="permissions" *ngFor=
                                    "let p of permissionsAllowed; let i = index"> {{p.name}} </mat-checkbox>
                                <mat-error *ngIf='permissionError'>Field is required</mat-error> </div> </div>
                        <div class="row mt10 mb10">
                            <div class="col-xs-12">
                                <h3>Cloud Information</h3> </div>
                            <div class="col-xs-12">
                                <div class="row mt10 mb10">
                                    <div class="col-xs-6">
                                        <mat-form-field class="example-full-width"> <input matInput placeholder="Cloud Traffic/Month" name="cloud_traffic" formControlName="cloud_traffic" />
                                            <mat-error *ngIf="myForm.get('cloud_traffic').hasError('required')">Field is required </mat-error>
                                            <mat-error *ngIf="myForm.get('cloud_traffic').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div>
                                    <div class="col-xs-6">
                                        <mat-form-field class="example-full-width"> <input matInput placeholder="Cloud Storage" name="cloud_storage" formControlName="cloud_storage" />
                                            <mat-error *ngIf="myForm.get('cloud_storage').hasError('required')">Field is required </mat-error>
                                            <mat-error *ngIf="myForm.get('cloud_storage').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div> </div>
                                <div class="row mt10 mb10">
                                    <div class="col-xs-6">
                                        <mat-form-field class="example-full-width"> <input matInput placeholder="Cloud Download/Month" name="cloud_monthly_download" formControlName="cloud_monthly_download" />
                                            <mat-error *ngIf=
                                                "myForm.get('cloud_monthly_download').hasError('required')">Field is required </mat-error>
                                            <mat-error *ngIf="myForm.get('cloud_monthly_download').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div> </div> </div> </div>
                        <div class="row mt20 mb10">
                            <div class="col-xs-12">
                                <h3>License Information</h3> </div>
                            <div class="col-xs-12">
                                <div class="row mt10 mb10">
                                    <div class="col-xs-4">
                                        <mat-form-field class="example-full-width"> <input matInput placeholder="License Required (Count)" name="license_count" formControlName="license_count" />
                                            <mat-error *ngIf="myForm.get('license_count').hasError('required')">Field is required </mat-error>
                                            <mat-error *ngIf="myForm.get('license_count').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div>
                                    <div class="col-xs-4">
                                        <mat-checkbox color="primary" class="pr10 cb-font" formControlName="is_autosync_enabled"> Auto Sync </mat-checkbox> </div>
                                    <div class="col-xs-4">
                                        <mat-checkbox color="primary" class="pr10 cb-font" formControlName="is_device_status_enabled"> Device Status </mat-checkbox> </div> </div>
                                <div class="row mt10 mb10">
                                    <div class="col-xs-4">
                                        <mat-form-field class="example-full-width"> <input matInput placeholder="Total Users (Count)" name="users_count" formControlName="users_count" />
                                            <mat-error *ngIf="myForm.get('users_count').hasError('required')">Field is required </mat-error>
                                            <mat-error *ngIf="myForm.get('users_count').hasError('minlength')">Min length should be 5 </mat-error> </mat-form-field> </div>
                                    <div class="col-xs-4">
                                        <mat-checkbox color="primary" class="pr10 cb-font" formControlName="is_analytical_tool_enabled"> Analytical Tool </mat-checkbox> </div>
                                    <div class="col-xs-4">
                                        <mat-checkbox color="primary" class="pr10 cb-font" formControlName="is_auto_update_enabled"> Auto Update </mat-checkbox> </div> </div> </div> </div>
                        <div class="row mt20 mb10">
                            <div class="col-xs-12">
                                <div class="row mt10 mb10">
                                    <div class="col-xs-4">
                                        <mat-checkbox color="primary" class="pr10 cb-font" formControlName="status"> Enable this Slab? </mat-checkbox> </div> </div> </div> </div>
                        <div class="row mt10 mb10">
                            <div class="col-xs-12"> <button mat-button color="primary" [disabled]="!myForm.valid">{{saveBtnText}}</button> </div> </div> </form> </div> </div> </div> </div></div>