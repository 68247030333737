import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import otpGenerator from "otp-generator";
import { NgxSpinnerService } from 'ngx-spinner';

import { SessionStorage } from "../../services/session-storage";
import { TwoFactorAuthService } from "./services/two-factor.service";
import { USER_DASHBOARD } from "../../config";

@Component({
    selector: 'app-two-factor-auth',
    templateUrl: './two-factor.component.html',
    styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {

    alertMessage = {
        message: null,
        error: null
    };

    code: string = '';

    myForm = new UntypedFormGroup({
        code: new UntypedFormControl("", [Validators.required])
    });

    constructor(private _router: Router, private _service: TwoFactorAuthService, private sessionStorageService: SessionStorage, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.spinner.show();
        this.code = otpGenerator.generate(10, { upperCase: true, specialChars: false });
        let userData = JSON.parse(this.sessionStorageService.getUserData());

        if (this.sessionStorageService.isUserTwoFactorValidated() == 'null' && userData) {
            this._service.sendOtp(userData['email'], this.code).subscribe(res => {
                if (res['OK']) {
                    this.alertMessage.message = res['message'];
                    this.alertMessage.error = false;
                } else {
                    this.alertMessage.message = res['message'];
                    this.alertMessage.error = true;
                }
                this.spinner.hide();
            });
        }
    }

    doVerifyCode() {
        if (this.myForm.value.code === this.code) {
            this._router.navigate([USER_DASHBOARD]);
            this.alertMessage.message = 'Your OTP is matching';
            this.alertMessage.error = false;
            this.sessionStorageService.setUserTwoFactorValidated(true);
        } else {
            this.alertMessage.message = 'Please check the OTP, its not matching';
            this.alertMessage.error = true;
        }
    }

}
