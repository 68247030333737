import { Injectable } from "@angular/core";

import { SessionStorage } from "./session-storage";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private sessionStorageService: SessionStorage
  ) {}

  logOut(): void {
    this.sessionStorageService.clearSessionData();
  }
}
