import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../services/common-http";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})

export class AdminOrganisationService {

    constructor(private commonHttpService: CommonHttpService, private httpService: HttpClient) { }

    getOrganisations(page, limit, searchQry = '') {
        return this.commonHttpService.get('/organisations?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry).then(res => res);
    }

    setOrganisation(postBody) {
        return this.commonHttpService.post('/organisations', postBody).then(res => res);
    }

    activateOrganisation(postBody: any, Id: string) {
        return this.commonHttpService.put('/organisations/activate/' + encodeURI(Id) , postBody).then(res => res);
    }

    getOrganisation(Id) {
        return this.commonHttpService.get('/organisations/' + encodeURI(Id)).then(res => res);
    }

    updateOrganisationProfile(postBody, Id: any) {
        return this.commonHttpService.put('/organisations/profile/' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteOrganisation(postBody) {
        return this.commonHttpService.delete('/organisations', postBody).then(res => res);
    }

    validateOrganisationID(Id: any) {
        return this.commonHttpService.get('/organisations/findId/' + encodeURI(Id));
    }
}