import { Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "app-playlist-scene-duration",
  templateUrl: "./duration.component.html",
  styleUrls: ["../../form/form.component.scss"],
})
export class PlaylistSceneSetDurationComponent implements OnInit {
  @Input("data") data: Object;
  @Input("index") index: number;
  @Input("duration") duration: number;

  ngOnInit(): void {
    if (typeof this.duration == "undefined" || this.duration == null) {
      this.duration = 0;
    }
  }
}
