import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";

import { ActivityService } from "../services/activity.services";

@Component({
  selector: "app-user-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"]
})
export class ActivityComponent implements OnInit,OnDestroy{

  activities: Array<Object> = [];
  totalCount: number = 0;

  constructor(
    private _route: Router,
    private _service: ActivityService,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, "activity-body");
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, "activity-body");
  }

  ngOnInit() {
    this.getActivities(1, 10);
  }

  getActivities(page, limit) {
    this._service.getActivities(page, limit).then((res) => {
      this.activities = res["data"];
      this.totalCount = res["count"];
    });
  }

  onPageChange(event: PageEvent) {
    this.getActivities(++event.pageIndex, event.pageSize);
  }
}