import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { AssetsService } from "../services/assets.service";
import { DimensionService } from "../../dimensions/services/dimensions.service";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-asset-view-component",
  templateUrl: "./asset-view.component.html",
  styleUrls: ["./asset-view.component.scss"]
})
export class AssetViewComponent implements OnInit {
  crumbItems: Array<Object>;
  assetData: Object;
  id: any;
  dimensions: Array<Object> = [];

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
    dimension: new UntypedFormControl("", [Validators.required]),
    description: new UntypedFormControl("")
  });

  constructor(
    private _route: Router,
    private _service: AssetsService,
    private _dimensionService: DimensionService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false
      },
      {
        label: "Media Library",
        path: "dashboard/assets",
        current: true
      }
    ];

    this.assetData = {}
    this.id = this.route.snapshot.params.id;

    if (this.id) {

      this._service.getAsset(this.id).then(res => {
        this.myForm.get("name").setValue(res['data']['originalname']);
        this.myForm.get("dimension").setValue(res['data']['dimension_id']);
        this.myForm.get("description").setValue(res['data']['description']);

        this.assetData = res['data'];
      });

      this._dimensionService.getDimensions(1, 10000).then((res) => {
        if (res['data'].length > 0) {
          this.dimensions = res['data'];
        }
      });
    }
  }

  save(): void {

    let postBody = {
      name: this.myForm.value.name,
      dimension_id: this.myForm.value.dimension,
      description: this.myForm.value.description
    };

    this._service.updateAsset(postBody, this.id).then((res) => {
      if (res["status"] === 200) {
        this._route.navigate(["/dashboard/assets"]);
      } else {
        alert(res["message"]);
      }
    });
  }

  onDelete(): void {

    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([this.id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds: any[]) {

    let postBody = {
      ids: arrayOfIds,
    };

    this._service.deleteAsset(postBody).then((res) => {
      if (res["status"] === 200) {
        this._route.navigate(["/dashboard/assets"]);
      }
    });
  }
}