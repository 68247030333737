import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { PlayersService } from "../services/players.service";
import { LocationsService } from "../../../../manage/pages/location/services/locations.service";
import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";

@Component({
  selector: "app-player-form-approval",
  templateUrl: "./form.component.html"
})

export class PlayerFormApprovalComponent implements OnInit {
  crumbItems: Array<Object>;
  id: any;
  saveBtnText = "Approve";
  locations: Array<Object>;
  dimensions: Array<Object>;

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
    playerID: new UntypedFormControl("", [Validators.required]),
    location: new UntypedFormControl("", [Validators.required]),
    dimension: new UntypedFormControl("", [Validators.required]),
    storage: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _route: Router,
    private _service: PlayersService,
    private _lservice: LocationsService,
    private _dservice: DimensionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Players",
        path: "/manage/players",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;

    Promise.all([
      this._dservice.getDimensions(0, 1000000),
      this._lservice.getLocations(0, 1000000)
    ]).then((res) => {
      this.dimensions = res[0]["data"] || [];
      this.locations = res[1]["data"] || [];
    });

    if (this.id) {
        this._service.getPlayer(this.id).then((res) => {
            this.myForm.get("name").setValue(res["data"]["name"]);
            this.myForm.get("playerID").setValue(res["data"]["playerId"]);
            this.myForm.get("location").setValue(res["data"]["location"]);
            this.myForm.get("dimension").setValue(res["data"]["dimension"]);
            this.myForm.get("storage").setValue(res["data"]["storage"]);
        });
    }

  }

  onApprove() {

    let postBody = {
      name: this.myForm.value.name,
      playerID: this.myForm.value.playerID,
      location: this.myForm.value.location,
      storage: this.myForm.value.storage,
      dimension: this.myForm.value.dimension,
      status: 'A'
    };

    this._service.updatePlayer(postBody, this.id).then((res) => {
      if (res["status"] === 200) {
        this._route.navigate(["/manage/players"]);
      } else {
        alert(res["message"]);
      }
    });
  }
}
