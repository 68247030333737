
<div class="row">
    <div class="col-xs-12">
        <h2> Button Widget Properties </h2>
        <hr class="widget-header mt0 mb0" /> </div></div>
<div class="row mt30">
    <div class="col-xs-12 mb10">
        <mat-form-field appearance="legacy">
            <mat-label>Button Name</mat-label> <input matInput placeholder="Button Name" [(ngModel)]="widgetProps.name" /> </mat-form-field> </div>
    <div class="col-xs-12 mb10">
        <mat-form-field appearance="legacy">
            <mat-label>Button Label</mat-label> <input matInput placeholder="Button label" [(ngModel)]="widgetProps.label" /> </mat-form-field> </div>
    <div class="col-xs-12 mb10">
        <mat-form-field appearance="legacy">
            <mat-select placeholder="Navigation to" [(ngModel)]="widgetProps.action">
                <mat-option value="previous_scene">Previous Scene</mat-option>
                <mat-option value="next_scene">Next Scene</mat-option>
                <mat-option value="launch_external_url">Launch App / Website</mat-option> </mat-select> </mat-form-field> </div>
    <div class="col-xs-6 mb10" *ngIf="widgetProps.action === 'launch_external_url'">
        <mat-form-field appearance="legacy">
            <mat-label>Action URL</mat-label> <input matInput placeholder="Action URL" [(ngModel)]="widgetProps.action_url" /> </mat-form-field> </div>
<div class="col-xs-6 mb10" *ngIf=
    "widgetProps.action === 'launch_external_url'">
    <mat-form-field appearance="legacy">
        <mat-select placeholder="Opens URL in" [(ngModel)]="widgetProps.target">
            <mat-option value="_self">Opens In Same Window (Default)</mat-option>
            <mat-option value="_blank">Open In New Window</mat-option> </mat-select> </mat-form-field> </div>
    <div class="clearfix"></div>
    <div class="col-xs-4 mb10">
        <mat-form-field appearance="legacy">
            <mat-select placeholder="Alignment" [(ngModel)]="widgetProps.align">
                <mat-option value="center">Center</mat-option>
                <mat-option value="left">Left</mat-option>
                <mat-option value="right">Right</mat-option> </mat-select> </mat-form-field> </div>
    <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.fillColor">
        <mat-label class="widget-mat-label">Choose Color</mat-label>
        <div class="clearfix"></div> <input [(colorPicker)]="widgetProps.fillColor" [style.background]="widgetProps.fillColor" class="color-picker" /> </div>
    <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.textColor">
        <mat-label class="widget-mat-label">Text Color</mat-label>
        <div class="clearfix"></div> <input [(colorPicker)]="widgetProps.textColor" [style.background]="widgetProps.textColor" class="color-picker" /> </div>
    <div class="col-xs-12 mt10 mb10">
        <mat-label class="widget-mat-label">BG Color</mat-label>
        <div class="clearfix"></div> <input [(colorPicker)]="widgetProps.backgroundColor" [style.background]="widgetProps.backgroundColor" class="color-picker" /> </div>
    <div class="col-xs-12">
        <mat-slide-toggle color="primary" [(ngModel)]="widgetProps.hasBorder"> Border </mat-slide-toggle> </div>
    <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.hasBorder">
        <mat-label class="widget-mat-label">Choose Color</mat-label>
        <div class="clearfix"></div> <input [(colorPicker)]="widgetProps.borderColor" [style.background]="widgetProps.borderColor" class="color-picker" /> </div>
    <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.hasBorder">
        <mat-form-field class="example-full-width"> <input matInput placeholder="Border width" [(ngModel)]="widgetProps.borderWidth" /> <span matSuffix>px &nbsp;</span> </mat-form-field> </div>
    <div class="col-xs-12 mt15">
        <mat-label class="widget-mat-label">Position</mat-label>
        <div class="row mt15">
            <div class="col-xs-6">
                <mat-form-field class="example-full-width"> <input type="number" matInput placeholder="Left" [(ngModel)]="widgetProps.coords.left" /> <span matSuffix>px &nbsp;</span> </mat-form-field> </div>
            <div class="col-xs-6">
                <mat-form-field class="example-full-width"> <input type="number" matInput placeholder="Top" [(ngModel)]="widgetProps.coords.top" /> <span matSuffix>px &nbsp;</span> </mat-form-field> </div> </div> </div>
    <div class="col-xs-12 mt15">
        <mat-label class="widget-mat-label">Size</mat-label>
        <div class="row mt15">
            <div class="col-xs-6">
                <mat-form-field class="example-full-width"> <input type="number" matInput placeholder="Width" [(ngModel)]="widgetProps.size.width" /> <span matSuffix>px &nbsp;</span> </mat-form-field> </div>
            <div class="col-xs-6">
                <mat-form-field class="example-full-width"> <input type="number" matInput placeholder="Height" [(ngModel)]="widgetProps.size.height" /> <span matSuffix>px &nbsp;</span> </mat-form-field> </div> </div> </div>
    <div class="col-xs-12 mb35">
        <button class="widget-button" mat-button color="primary" (click)="saveWidgetProps()"> Save Widget </button> </div></div>