<div class="modal-content">
  <div class="modal-header">
    <div class="pull-left">
      <h2>Choose a widget to continue</h2>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="modal-body modal-body-choose-item" mat-dialog-content>
    <div class="row">
      <div class="col-xs-4 text-center" [mat-dialog-close]="'image'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Image</p>
      </div>
      <div class="col-xs-4 text-center" [mat-dialog-close]="'pdf'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>PDF</p>
      </div>
      <div class="col-xs-4 text-center" [mat-dialog-close]="'video'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Video</p>
      </div>
      <div class="clearfix"></div>
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'text'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Text</p>
      </div>
      
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'button'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Button</p>
      </div>
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'clock'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Clock</p>
      </div>
      <div class="clearfix"></div>
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'html-viewer'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>HTML Viewer</p>
      </div>
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'marquee'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Marquee</p>
      </div>
      <div class="col-xs-4 text-center mt15" [mat-dialog-close]="'slideshow'">
        <button matButton>
          <img src="assets/ic-editor-image.png" alt="" />
        </button>
        <p>Slideshow</p>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>