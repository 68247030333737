import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { NgxSpinnerService } from 'ngx-spinner';

import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { LocationsService } from "../services/locations.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class LocationListComponent implements OnInit {
  bulkMenuItems: Array<string>;
  locations: Array<Object> = [];
  totalCount: number = 0;
  checkedList: Array<string> = [];
  searchQry: string = '';

  constructor(
    private _route: Router,
    public dialog: MatDialog,
    private _service: LocationsService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.bulkMenuItems = ["Delete"];
    this.getAllLocations(1, 10);
  }

  getAllLocations(page, limit, search = '') {
    this.spinner.show();
    this._service.getLocations(page, limit, search).then((res) => {
      this.locations = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onMenuClicked(index: number) {
    switch (index) {
      case 0:
        if(this.checkedList.length === 0) {
          this.openNoItemsSelectedDialog();
        } else {
          this.openDialog(this.checkedList);
        }
        break;

      default:
    }
  }

  onAdd(event: any) {
    this._route.navigate(["/manage/location/add"]);
  }

  onEdit(dimensionID): void {
    this._route.navigate(["/manage/location/edit/" + dimensionID]);
  }

  onPageChange(event: PageEvent) {
    this.getAllLocations(++event.pageIndex, event.pageSize, this.searchQry);
  }

  openDialog(dimensionID) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray(Array.isArray(dimensionID) ? dimensionID : [dimensionID]);
      }
    });
  }

  openNoItemsSelectedDialog() {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "No Items",
        content:
          "Choose items and delete!!!",
      },
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deleteLocation(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllLocations(1, 10);
      }
      this.spinner.hide();
    });
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.locations.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.locations.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  onSearch(searchQry: any) {
    this.searchQry = searchQry;
    this.getAllLocations(1, 10, searchQry);
  }
}
