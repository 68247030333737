import { Component, OnInit } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { DomSanitizer } from '@angular/platform-browser';
import { SessionStorage } from "../../../../../../../services/session-storage";
import { API_URL } from "../../../../../../../config";

let URL = API_URL + '/upload'

@Component({
  selector: "app-upload-asset-container",
  templateUrl: "./upload-container.component.html",
  styleUrls: ["./upload-container.component.scss"]
})
export class UploadAssetContainerComponent implements OnInit {
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  response: string;
  previewPath: any;
  hideUploadButton: Boolean = false;
  showMoreUploadButton: Boolean = false;

  constructor(private sanitizer: DomSanitizer, private sessionStorageService: SessionStorage) {

    const userData = JSON.parse(this.sessionStorageService.getUserData());
    let orgID = null;

    if (userData && userData.organisation != null) {
      orgID = userData.organisation.id;
    } else {
      orgID = JSON.parse(this.sessionStorageService.getUserData())['_id'];
    }

    this.uploader = new FileUploader({ 
      url: URL, 
      additionalParameter: { org_id: orgID }
    });

    this.hasBaseDropZoneOver = false;
    this.response = "";
    this.uploader.response.subscribe(res => (this.response = res));

    this.uploader.onProgressAll = () => {
      this.hideUploadButton = true;
    }

    this.uploader.onCompleteAll = () => {
      this.showMoreUploadButton = true;
    }

    this.uploader.onAfterAddingFile = (fileItem) => {
      this.previewPath = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
    }
  }

  ngOnInit() {
    // 
  }

  moreUpload() {
    this.uploader.clearQueue();
    this.showMoreUploadButton = false;
    this.hideUploadButton = false;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onFileSelected(file) {
    console.log(file);
  }
}
