import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class LocationsService {

    constructor(private commonHttpService: CommonHttpService) { }

    getLocations(page, limit, searchQry = '') {
        return this.commonHttpService.get('/locations?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry).then(res => res);
    }

    setLocation(postBody) {
        return this.commonHttpService.post('/locations', postBody).then(res => res);
    }

    getLocation(Id) {
        return this.commonHttpService.get('/location?id=' + encodeURI(Id)).then(res => res);
    }

    updateLocation(postBody, Id) {
        return this.commonHttpService.put('/locations?id=' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteLocation(postBody) {
        return this.commonHttpService.delete('/locations', postBody).then(res => res);
    }
}