<div class="gridContent">
  <mat-grid-list cols="12" rowHeight="2:4.5" gutterSize="12px">
    <mat-grid-tile colspan="3" class="matGridTile" *ngFor="let d of data">
      <a
        (click)="onView(d._id)"
        *ngIf="
          d.mimetype === 'image/jpeg' ||
          d.mimetype === 'image/jpg' ||
          d.mimetype === 'image/png'
        "
        [ngStyle]="{
          background: 'url(' + d.mediaUrl + ') no-repeat',
          'background-size': 'cover'
        }"
        class="linkContent"
      ></a>
      <a
        *ngIf="d.mimetype === 'video/mp4'"
        (click)="onView(d._id)"
        [ngStyle]="{
          background: 'url(' + d.thumbnail + ') no-repeat',
          'background-size': 'cover'
        }"
        class="linkContent"
      ></a>
      <mat-grid-tile-footer>
        <div class="flexStart" (click)="onView(d._id)">
          <h4>{{ d.originalname }}</h4>
          <small
            >{{ d.size | formatFileSize }} &nbsp;&nbsp;&nbsp;{{
              d.created_on | date: "mediumDate"
            }}</small
          >
        </div>
        <div class="flexEnd">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onView(d._id)">
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="onDelete(d._id)">
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </mat-grid-tile-footer>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<app-paginate
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate
>
