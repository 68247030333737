<div class="row">
  <div class="col-xs-12">
    <h2>Scene Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Scene Name</mat-label>
      <input
        matInput
        placeholder="Scene Name"
        name="widgetProps.title"
        [(ngModel)]="sceneInfo['props'].title"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-6 mb10">
        <mat-form-field appearance="legacy">
          <mat-label>Width</mat-label>
          <input
            matInput
            placeholder="Width"
            readonly
            name="sceneInfo['props'].width"
            [ngModel]="sceneInfo['props'].width"
          />
        </mat-form-field>
      </div>
      <div class="col-xs-6 mb10">
        <mat-form-field appearance="legacy">
          <mat-label>Height</mat-label>
          <input
            matInput
            placeholder="Height"
            name="sceneInfo['props'].height"
            readonly
            [ngModel]="sceneInfo['props'].height"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12 mt10 mb20" style="height: 50px">
    <mat-label class="widget-mat-label">Choose Background Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.backgroundColor"
      [style.background]="widgetProps.backgroundColor"
      class="color-picker"
    />
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12 mb10">
    <mat-form-field class="example-full-width">
      <textarea
        matInput
        placeholder="Description"
        [ngModel]="sceneInfo['props'].description"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>