import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "underscore";
import { PageEvent } from "@angular/material/paginator";

import { PlayerGroupsService } from "../services/player-groups.services";
import { LocationsService } from "../../../../manage/pages/location/services/locations.service";
import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";

@Component({
  selector: "app-scene-modal",
  templateUrl: "./player-group-modal.component.html",
  styleUrls: ["./player-group-modal.component.scss"],
})
export class PlayerGroupModalComponent implements OnInit {
  displayedColumns: string[] = [
    "_id",
    "name",
    "dimension",
    "playerId",
    "location",
    "storage",
    "status",
    "action",
  ];
  dataSource = new MatTableDataSource();
  totalCount: number;
  checkedList: Array<Object> = [];
  searchQry: string = "";
  locations: Array<Object>;
  dimensions: Array<Object>;

  location: string = 'all';
  dimension: string = 'all';

  ngOnInit() {

    Promise.all([
      this._dservice.getDimensions(0, 1000000),
      this._lservice.getLocations(0, 1000000),
    ]).then((res) => {
      this.dimensions = res[0]["data"] || [];
      this.locations = res[1]["data"] || [];
    });

    this.getAllPlayers({
      page: 1,
      limit: 10,
      searchQry: this.searchQry,
      location: this.location,
      dimension: this.dimension,
    });
  }

  constructor(
    private _service: PlayerGroupsService,
    private _lservice: LocationsService,
    private _dservice: DimensionService
  ) {}

  getAllPlayers(postBody: { page: number; limit: number; searchQry: any; location: string; dimension: string; }) {
    this._service.getPlayersBySearch(postBody).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
    });
  }

  isChecked(elem: Object) {
    return this.checkedList.includes(elem);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }

  doSelect(elem: Object) {
    if (this.isChecked(elem)) {
      this.checkedList = _.without(this.checkedList, elem);
    } else {
      this.checkedList.push(elem);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem);
    } else {
      this.checkedList = [];
    }
  }

  onSearch() {
    let postBody = {
      page: 1,
      limit: 10,
      searchQry: this.searchQry,
      location: "",
      dimension: "",
    };

    this.getAllPlayers(postBody);
  }

  onPageChange(event: PageEvent) {
    let postBody = {
      page: ++event.pageIndex,
      limit: event.pageSize,
      searchQry: this.searchQry,
      location: this.location,
      dimension: this.dimension,
    };

    this.getAllPlayers(postBody);
  }

  onChangeDimension(event: any) {
    this.dimension = event;
    let postBody = {
      page: 1,
      limit: 10,
      searchQry: this.searchQry,
      location: this.location,
      dimension: this.dimension,
    };

    this.getAllPlayers(postBody);
  }

  onChangeLocation(event: any) {
    this.location = event;
    let postBody = {
      page: 1,
      limit: 10,
      searchQry: this.searchQry,
      location: this.location,
      dimension: this.dimension,
    };

    this.getAllPlayers(postBody);
  }
}
