<div class="appContent">
  <app-header></app-header>
  <div class="contentWrapper">
    <div class="activityWrapper" *ngIf="notifications.length > 0">
      <div
        class="activityItemLayer"
        (click)="navigateTo(data.deep_link_url)"
        *ngFor="let data of notifications"
      >
        <div class="activityItemWrapper">
          <div class="icon">
            <mat-icon
              aria-hidden="false"
              [ngClass]="{
                success: data.status === 'S',
                error: data.status === 'F'
              }"
            >
              check_circle
            </mat-icon>
          </div>
          <div class="message">
            <p>{{ data.message }}</p>
            <small>{{ data['created_on'] | date: "mediumDate" }}</small>
          </div>
        </div>
      </div>
      <!--Pagination-->
      <div class="mt10">
        <app-paginate
          [length]="totalCount"
          pageSize="10"
          (onPageChange)="onPageChange($event)"
        ></app-paginate>
      </div>
    </div>
  </div>
</div>