
<div class="appContent">
    <app-header></app-header>
    <div class="contentWrapper">
        <app-module-header title="Slab Settings" isBackEnabled="false" [menuItems]="bulkMenuItems" (onMenuClicked)="onMenuClicked($event)" (onAdd)="onAdd($event)" showBulkMode="true" showAddButton="true" showSearch="true" (onSearch)=
            "onSearch($event)"></app-module-header>
        <mat-grid-list cols="12" class="tableHead" rowHeight="50px">
            <mat-grid-tile class="mat-grid-tile-checkbox">
                <mat-checkbox color="primary" (change)="doSelectAll()" [checked]="isAllChecked()"></mat-checkbox> </mat-grid-tile>
            <mat-grid-tile colspan="3">Name </mat-grid-tile>
            <mat-grid-tile colspan="2">Cloud Storage</mat-grid-tile>
            <mat-grid-tile colspan="2">License Count</mat-grid-tile>
            <mat-grid-tile colspan="2">Users Added</mat-grid-tile>
            <mat-grid-tile colspan="1">Status</mat-grid-tile>
            <mat-grid-tile colspan="1" class="tableAction">Action</mat-grid-tile> </mat-grid-list>
        <mat-grid-list cols="12" class="tableRow" rowHeight="60px" *ngFor="let data of slabs">
            <mat-grid-tile class="mat-grid-tile-checkbox">
                <mat-checkbox color="primary" [checked]="isChecked(data._id)" (change)="doSelect(data._id)"> </mat-checkbox> </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <h2>{{data.name}}</h2> </mat-grid-tile>
            <mat-grid-tile colspan="2">{{data.cloud_storage}} GB</mat-grid-tile>
            <mat-grid-tile colspan="2">{{data.license_count}}</mat-grid-tile>
            <mat-grid-tile colspan="2">{{'Total Users: ' + data.users_count}}</mat-grid-tile>
            <mat-grid-tile colspan="1">{{data.status ? 'Enabled' : 'Disabled'}}</mat-grid-tile>
            <mat-grid-tile colspan="1" class="tableAction"> <button mat-button class="mat-button-custom" (click)="onEdit(data._id)">                    <img src="assets/edit.png" alt="" width="16" />                </button> <button mat-button class="mat-button-custom" (click)=
                    "openDialog(data._id)">                    <img src="assets/delete.png" alt="" width="16" />                </button> </mat-grid-tile> </mat-grid-list>
        <app-paginate [length]='totalCount' pageSize="10" (onPageChange)=
            "onPageChange($event)"></app-paginate> </div></div>