<div class="appHeader">
  <button mat-button>
    <img src="assets/fullscreen.png" alt="" width="20" />
  </button>
  <div class="appSearchWrapper">
    <form>
      <mat-form-field class="example-full-width">
        <input type="text" matInput placeholder="Search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <div class="appRightItems">
    <button mat-icon-button>
      <mat-icon class="icon">notifications</mat-icon>
      <span class="badge">10</span>
    </button>
    <div class="appUser">
      <span> <img src="assets/icon-user-header.png" alt="" /> </span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>dialpad</mat-icon> <span>Account Settings</span>
        </button>
        <button type="button" (click)="onLogout()" mat-menu-item>
          <mat-icon>notifications_off</mat-icon> <span>Log out</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
