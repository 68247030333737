<div class="appContent">
  <app-header></app-header>
  <div class="contentWrapper">
    <div class="activityWrapper" *ngIf="activities.length > 0">
      <div class="activityItemLayer" *ngFor="let item of activities">
        <div class="activityDate">{{ item.date | date: "mediumDate" }}</div>
        <app-user-activity-item
          [item]="item.activities"
        ></app-user-activity-item>
      </div>
      <!--Pagination-->
      <div class="mt10">
        <app-paginate
          [length]="totalCount"
          pageSize="10"
          (onPageChange)="onPageChange($event)"
        ></app-paginate>
      </div>
    </div>
  </div>
</div>