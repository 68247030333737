import { Component, OnInit } from '@angular/core';
import {innerDrawerMenus} from "../drawer-menus"

@Component({
  selector: 'app-landing-manage',
  templateUrl: './landing-manage.component.html',
  styleUrls: ['./landing-manage.component.scss']
})

export class LandingManageComponent implements OnInit {

  menuItems: Array<Object> = innerDrawerMenus;
  
  constructor() { 
    // 
  }

  ngOnInit() {
    // 
  }

}
