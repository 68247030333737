import { Component, OnInit, Inject } from "@angular/core";
import {
    MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: "app-slideshow-prview-modal",
    templateUrl: './preview.modal.component.html',
    styleUrls: ['./preview.modal.component.scss']
})

export class SlideshowPreviewModal implements OnInit {

    customOptions: any = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 1000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: false
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public props: {
            props: {
                transition: string,
                transition_delay: string
            }
        },
        @Inject(MAT_DIALOG_DATA) public images: []
    ) { }

    ngOnInit() {
        this.customOptions.autoplayTimeout = parseInt(this.props.props.transition_delay);
        this.customOptions.animateOut = this.props.props.transition;
    }
}