import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountVerifyService } from "./services/account-verify.service";

@Component({
    selector: 'app-account-verify',
    templateUrl: './account.verify.component.html',
    styleUrls: ['./account.verify.component.scss']
})
export class AccountVerifyComponent implements OnInit {

    code: string = '';
    isVerified: Boolean;
    message: string = '';

    constructor(private _router: Router, private _route: ActivatedRoute, private _service: AccountVerifyService) { }

    ngOnInit() {
        this.code = this._route.snapshot.params.code;
        this._service.verifyAccount(this.code).subscribe(res => {
            if(res['status'] !== 200) {
                this.isVerified = false;
                this.message = res['message'];
            } else {
                this.isVerified = true;
                this.message = 'You have verified your account, please go ahead and login to your account for more!';
            }
        });
    }

    doLogin() {
        this._router.navigate(['/']);
    }
}