import { Component, OnChanges, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-button-widget",
  templateUrl: "./button.widget.component.html",
  styleUrls: ["./widget.scss"],
})

export class ButtonWidgetComponent implements OnChanges {

  @Output() onSaveProps = new EventEmitter();
  @Input() widget: Object;

  widgetProps = {
    name: "Button ",
    label: 'New Button',
    action: "",
    action_url: '',
    target: '_self',
    align: "",
    fillColor: "#333",
    textColor: "#e1e1e1",
    hasBorder: false,
    borderColor: "#333",
    borderWidth: "0",
    backgroundSrc: 'none',
    backgroundColor: "#333",
    coords: {
      left: 0,
      top: 0,
    },
    size: {
      width: 100,
      height: 45
    }
  };

  constructor() {
    //
  }

  ngOnChanges() {
    this.widgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Button " + this.widget["index"],
      label:
        (this.widget["props"] && this.widget["props"]["label"]) ||
        "New Button",
      action: (this.widget["props"] && this.widget["props"]["action"]) || "",
      action_url: (this.widget["props"] && this.widget["props"]["action_url"]) || "",
      target: (this.widget["props"] && this.widget["props"]["target"]) || "",
      align: (this.widget["props"] && this.widget["props"]["align"]) || "",
      fillColor: (this.widget["props"] && this.widget["props"]["fillColor"]) || "#333",
      backgroundColor: (this.widget["props"] && this.widget["props"]["backgroundColor"]) || "#333",
      textColor: (this.widget["props"] && this.widget["props"]["textColor"]) || "#e1e1e1",
      hasBorder:
        (this.widget["props"] && this.widget["props"]["hasBorder"]) || false,
      borderColor:
        (this.widget["props"] && this.widget["props"]["borderColor"]) || "#333",
      borderWidth:
        (this.widget["props"] && this.widget["props"]["borderWidth"]) || "0",
      backgroundSrc:
        (this.widget["props"] && this.widget["props"]["backgroundSrc"]) || "0",
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top: (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
      size: {
        width:
          (this.widget["props"] && this.widget["props"]["size"]["width"]) || 100,
        height: (this.widget["props"] && this.widget["props"]["size"]["height"]) || 45,
      }
    };

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true
    });
  }
}
