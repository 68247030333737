import { Component, OnInit, Input } from "@angular/core";
import { Lightbox } from "ngx-lightbox";

@Component({
  selector: "app-file-viewer",
  templateUrl: "./file-viewer.component.html",
  styleUrls: ["./file-viewer.component.scss"]
})
export class FileViewerComponent implements OnInit {
  @Input() mimeType: string;
  @Input() filePath: string;
  @Input() fileName: string;
  private _album: any = [];

  constructor(private _lightbox: Lightbox) { }

  ngOnInit() {
    // 
  }

  open(): void {
    this._lightbox.open([
      {
        src: this.filePath,
        caption: this.fileName,
        thumb: this.filePath
      }
    ], 0);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
