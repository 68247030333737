import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { NgxSpinnerService } from 'ngx-spinner';

import { PlayerGroupModalComponent } from "../modal/player-group-modal.component";
import { LocationsService } from "../../../../manage/pages/location/services/locations.service";
import { PlayerGroupsService } from "../../../../dashboard/pages/player-groups/services/player-groups.services";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-playergroups-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class PlayerGroupFormComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = ["name", "dimension", "location", "action"];
  id: any;
  saveBtnText: string;
  pageTitle: string = 'Add New - Player Group';

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
    location: new UntypedFormControl("", [Validators.required]),
    description: new UntypedFormControl("", [Validators.required]),
  });

  dataSource = new MatTableDataSource();
  locations: Array<Object>;
  selectedItems: Array<Object> = [];

  alertMessage: Object = {
    message: null,
    error: false
  };

  constructor(
    public dialog: MatDialog,
    private _lservice: LocationsService,
    private _service: PlayerGroupsService,
    private _route: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Player Groups",
        path: "manage/player-groups",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = this.id ? "Update" : "Save";
    this.pageTitle = this.id && 'Update - Player Group';

    this.spinner.show();
    this._lservice.getLocations(1, 100000).then((res) => {
      this.locations = res["data"];
      this.spinner.hide();
    });

    if (this.id) {
      this._service.getPlayerGroup(this.id).then(res => {
        this.myForm.get("name").setValue(res["data"]["name"]);
        this.myForm.get("location").setValue(res["data"]["location"]);
        this.myForm.get("description").setValue(res["data"]["description"]);

        this.dataSource.data = this.selectedItems = res["data"]["players"] || [];
        this.spinner.hide();
      });
    }
  }

  showSceneModal(): void {
    const dialogRef = this.dialog.open(PlayerGroupModalComponent, {
      width: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.selectedItems.length > 0) {
          let dataSourceObj = this.selectedItems;
          dataSourceObj = [...dataSourceObj, ...result];
          this.selectedItems = dataSourceObj;
        } else {
          this.selectedItems = result;
        }

        this.dataSource.data = this.selectedItems = _.uniq(
          this.selectedItems,
          function (x) {
            return x._id;
          }
        );
      }
    });
  }

  onRemoveSelectePlayerItem(elem: any) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content: "Do you want to delete this entry from listing ?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let _postBody = {
          'playerId': elem._id
        };
        this.spinner.show();
        this._service.removePlayerFromPlayerGroup(_postBody).then((res) => {
          if (res['status'] !== 200) {
            alert(res['message']);
          }

          this.selectedItems = this.selectedItems.filter((item) => {
            return item['_id'] !== elem._id;
          });
          this.dataSource.data = this.selectedItems;
          this.spinner.hide();
        });
      }
    });
  }

  onSave(): void {
    let postBody: Object = {};
    let players: Array<string> = [];

    if (this.selectedItems.length === 0) {
      const dialogRef = this.dialog.open(CustomModalComponent, {
        width: "400px",
        data: {
          title: "No Players Added!",
          content:
            "Hey! do you want to save this player group without any player ?",
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          postBody = {
            name: this.myForm.value.name,
            location: this.myForm.value.location,
            description: this.myForm.value.description,
            players,
          };

          this.setPlayerGroup(postBody);

        } else {
          this.showSceneModal();
        }
      });
    } else {
      players = this.selectedItems.map((elem) => {
        return elem["_id"];
      });

      postBody = {
        name: this.myForm.value.name,
        location: this.myForm.value.location,
        description: this.myForm.value.description,
        players,
      };

      this.setPlayerGroup(postBody);
    }
  }

  setPlayerGroup(postBody) {
    this.spinner.show();
    if (this.id) {
      this._service.updatePlayerGroup(postBody, this.id).then((res) => {
        this.setResponse(res);
        this.spinner.hide();
      });
    } else {
      this._service.setPlayerGroup(postBody).then((res) => {
        this.setResponse(res);
        this.spinner.hide();
      });
    }
  }

  setResponse(res) {
    switch (res["status"]) {
      case 200:
        this._route.navigate(["/manage/player-groups"]);
        this.alertMessage['error'] = false;
        this.alertMessage['message'] = null;
        break;
      case 204:
        this.alertMessage['error'] = true;
        this.alertMessage['message'] = res["message"];
        break;
      case 205:
        this.alertMessage['error'] = false;
        this.alertMessage['message'] = res["message"];
        setTimeout(() => {
          this._route.navigate(["/manage/player-groups"]);
        }, 2000);
        break;

      default:
        break;
    }
  }
}
