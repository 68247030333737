import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "underscore";
import { NgxSpinnerService } from 'ngx-spinner';

import { DimensionService } from "../../../../../manage/pages/dimensions/services/dimensions.service";
import { ZoneEditorService } from "../../services/zone-editor.services";

@Component({
  selector: "app-zone-creator-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"]
})
export class ZoneEditorForm implements OnInit {

  crumbItems: Array<Object>;
  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
    dimension: new UntypedFormControl("", [Validators.required]),
    // orientiation: new UntypedFormControl("Portrait", [Validators.required]),
    description: new UntypedFormControl("", [Validators.required])
  });
  dimensions: Array<Object> = [];
  selectedDimension: Object = {};

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false
      },
      {
        label: "Zone Editor",
        path: "manage/zone-editor",
        current: true
      }
    ];

    this._dservice.getDimensions(1, 10000).then((res) => {
      this.dimensions = res["data"];
    });

    this.myForm.get("dimension").valueChanges.subscribe(value => {
      // this.myForm.get("orientiation").setValue(value.orientation);
      this.selectedDimension = value;
    });
  }

  constructor(private _router: Router, private _dservice: DimensionService, private _mService: ZoneEditorService, private spinner: NgxSpinnerService) { }

  save() {

    let postBody = {
      name: this.myForm.value.name,
      dimension: this.selectedDimension['name'],
      orientation: this.selectedDimension['orientation'],
      width: this.selectedDimension['width'],
      height: this.selectedDimension['height'],
      content_json: null,
      thumbnail_path: null
    };

    this.spinner.show();
    this._mService.isZoneExists({ name: this.myForm.value.name }).then((res) => {
      this.spinner.hide();
      if(res['OK']) {
        this._router.navigate(['/manage/zone-editor/editor/'], { state: postBody });
      } else {
        alert(res['message']);
      }
    });
  }
}
