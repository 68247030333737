import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../../../../services/auth";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private _router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit() {}

  onLogout() {
    this._authService.logOut();
    this._router.navigate(["/"]);
  }
}
