import {
  Component,
  OnChanges,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { AssetModalWidgetComponent } from "../assets/assets.modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-video-widget",
  templateUrl: "./video.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class VideoWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Output() onAddAsset = new EventEmitter();
  @Input() widget: Object;

  vidWidgetProps = {
    name: "Video ",
    width: "",
    widthUnit: "",
    autoplay: "true",
    loop: "true",
    controls: "false",
    muted: "true",
    src: "",
    video: null,
    align: "",
    fit: "",
    backgroundColor: "#333",
    hasBorder: false,
    borderColor: "#333",
    borderWidth: "0",
    coords: {
      left: 0,
      top: 0,
    },
    duration: null
  };

  constructor(public dialog: MatDialog) { }

  ngOnChanges() {
    this.vidWidgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Video " + this.widget["index"],
      width: (this.widget["props"] && this.widget["props"]["width"]) || "100",
      widthUnit:
        (this.widget["props"] && this.widget["props"]["widthUnit"]) || "%",
      autoplay:
        (this.widget["props"] && this.widget["props"]["autoplay"]) || "true",
      loop: (this.widget["props"] && this.widget["props"]["loop"]) || "true",
      controls:
        (this.widget["props"] && this.widget["props"]["controls"]) || "false",
      muted: (this.widget["props"] && this.widget["props"]["muted"]) || "true",
      src: (this.widget["props"] && this.widget["props"]["src"]) || null,
      video: (this.widget["props"] && this.widget["props"]["video"]) || null,
      align: (this.widget["props"] && this.widget["props"]["align"]) || "",
      fit: (this.widget["props"] && this.widget["props"]["fit"]) || "",
      borderColor:
        (this.widget["props"] && this.widget["props"]["borderColor"]) || "#333",
      hasBorder:
        (this.widget["props"] && this.widget["props"]["hasBorder"]) || false,
      backgroundColor:
        (this.widget["props"] && this.widget["props"]["backgroundColor"]) ||
        "#333",
      borderWidth:
        (this.widget["props"] && this.widget["props"]["borderWidth"]) || "0",
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top:
          (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
      duration: (this.widget["props"] && this.widget["props"]["duration"]) || 0
    };

    this.onSaveProps.emit({
      props: this.vidWidgetProps,
      showAlert: false,
    });
  }

  openAssetsModal() {
    const dialogRef = this.dialog.open(AssetModalWidgetComponent, {
      width: "800px",
      data: {
        assetType: "videos",
      },
    });

    dialogRef.afterClosed().subscribe(async (assetObj) => {
      if (assetObj && assetObj.mediaUrl !== "") {
        this.vidWidgetProps.src = assetObj.thumbnail;
        this.vidWidgetProps.video = assetObj.mediaUrl;
        this.vidWidgetProps.duration = await this.getVideoDuration(assetObj.mediaUrl);
        this.onAddAsset.emit(assetObj.mediaUrl);
      }
    });
  }

  getVideoDuration(url: string) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = url;

      video.onloadedmetadata = function () {
        resolve(video.duration);
      };

      video.onerror = function () {
        reject('Failed to load video metadata.');
      };
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.vidWidgetProps,
      showAlert: true,
    });
  }
}
