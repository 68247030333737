<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Add New - Zone"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="row mt10">
  <div class="col-xs-4">
    <form [formGroup]="myForm" (ngSubmit)="save()">
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <input
              matInput
              placeholder="Name"
              name="name"
              formControlName="name"
              autocomplete="off"
            />
            <mat-error *ngIf="myForm.get('name').hasError('required')"
              >Field is required</mat-error
            >
            <mat-error *ngIf="myForm.get('name').hasError('minlength')"
              >Min length should be 5</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-select
              placeholder="Dimension"
              name="dimension"
              formControlName="dimension"
            >
              <mat-option [value]="item" *ngFor="let item of dimensions">{{
                item.name + " - " + item.width + "px by " + item.height + "px"
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="myForm.get('dimension').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <textarea
              matInput
              placeholder="Description"
              name="description"
              formControlName="description"
              maxlength="100"
            ></textarea>
            <mat-error *ngIf="myForm.get('description').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-xs-12">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            Create & Proceed
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
