import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";

import { AdminOrgListComponent } from "./list/list.component";
import { AdminOrganisationFormComponent } from "./form/form-organisation.component";
import { AdminOrganisationAddFormComponent } from "./add/form-organisation.component";
import { AdminOrgTabProfileComponent } from "./form/tabs/profile/organisation-profile.component";
import { AdminOrgAccountConfigComponent } from "./form/tabs/account/account.component";
import { AdminOrgActivateComponent } from './activate/activate.component';
import { AdminOrgTabAddProfileComponent } from './add/tabs/profile/organisation-profile.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AdminOrgListComponent,
    AdminOrganisationFormComponent,
    AdminOrgTabProfileComponent,
    AdminOrgAccountConfigComponent,
    AdminOrgActivateComponent,
    AdminOrganisationAddFormComponent,
    AdminOrgTabAddProfileComponent
  ]
})
export class AdminOrganisationModule {}
