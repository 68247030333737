import { Injectable } from "@angular/core";
import {
  ID_TOKEN,
  UID,
  USER_DATA,
  EMPTY,
  ROLE,
  PERMISSIONS,
} from "../../utils/constants/sessions";

@Injectable({
  providedIn: "root",
})
export class SessionStorage {
  constructor() {}

  setIdToken(token) {
    localStorage.setItem(ID_TOKEN, token || EMPTY);
  }

  setUid(uid) {
    localStorage.setItem(UID, uid || EMPTY);
  }

  setUserRole(role) {
    localStorage.setItem(ROLE, role || EMPTY);
  }

  setUserPermissions(permissions) {
    localStorage.setItem(PERMISSIONS, permissions || []);
  }

  setUserData(user) {
    localStorage.setItem(USER_DATA, user || null);
  }

  setUserTwoFactorValidated(isValidated) {
    localStorage.setItem("is_two_factor_validated", isValidated || null);
  }

  getIdToken() {
    return localStorage.getItem(ID_TOKEN) || EMPTY;
  }

  getUid() {
    return localStorage.getItem(UID) || EMPTY;
  }

  getUserRole() {
    return localStorage.getItem(ROLE) || EMPTY;
  }

  getUserData() {
    return localStorage.getItem(USER_DATA || null);
  }

  getUserPermissions() {
    return localStorage.getItem(PERMISSIONS || []);
  }

  isUserTwoFactorValidated() {
    return localStorage.getItem("is_two_factor_validated" || null);
  }
  
  clearSessionData() {
    return localStorage.clear();
  }
}
