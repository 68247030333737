import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: "app-custom-modal",
  templateUrl: "./custom-modal.component.html",
  styleUrls: ["./custom-modal.component.scss"]
})
export class CustomModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSuccess(): void {}

  ngOnInit() {}
}
