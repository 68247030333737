import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { NgxSpinnerService } from 'ngx-spinner';

import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";
import { ZoneEditorService } from "../../zone-editor/services/zone-editor.services";
import { ScenesService } from "../services/scenes.services";

@Component({
    selector: "app-scene-add-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ['./modal.component.scss']
})

export class SceneAddModalComponent implements OnInit {

    layout_id: number;
    layout: Object = {};
    isFormValid: boolean = true;
    postBody: Object = {};
    filteredLayouts: Array<Object> = [];

    myForm = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
        dimension: new UntypedFormControl("", [Validators.required])
    });
    dimensions: Array<Object> = [];
    pageLayouts: Array<Object> = [];

    constructor(
        private _dservice: DimensionService, 
        private _zoneService: ZoneEditorService, 
        private dialogRef: MatDialogRef<SceneAddModalComponent>, 
        private _router: Router,
        private spinner: NgxSpinnerService,
        private mService: ScenesService
    ) { }

    ngOnInit() {
        Promise.all([this._dservice.getDimensions(1, 10000), this._zoneService.getZones(1, 10000)]).then(res => {
            this.dimensions = res[0]["data"];
            this.pageLayouts = res[1]["data"];
            this.filteredLayouts = this.getFilteredLayouts('all');
        });
    }

    doSelectLayout(layout: Object) {
        this.layout = layout;
        this.layout_id = layout['_id'];
    }

    onDimensionChange(event: { value: any; }) {
        this.filteredLayouts = this.getFilteredLayouts(event.value);
    };

    getFilteredLayouts(searchString: string) {
        
        if(searchString !== 'all') {
            let filteredItems = _.filter(this.pageLayouts, (elem, index) => {
                return elem.dimension === searchString['name'];
            });
            return filteredItems;
        } else {
            return this.pageLayouts;
        }
    }

    onSave() {

        if(!_.isEmpty(this.layout)) {
            this.isFormValid = true;
            this.postBody = {
                name: this.myForm.value.name,
                orientation: this.myForm.value.dimension['orientation'],
                dimension: this.myForm.value.dimension['name'],
                width: this.myForm.value.dimension['width'],
                height: this.myForm.value.dimension['height'],
                layout: this.layout
            };
    
            setTimeout(() => {
                this.spinner.show();

                let postData = {
                    name: this.myForm.value.name
                };

                this.mService.isSceneExists(postData).then((res) => {
                    this.spinner.hide();
                    if(res['OK']) {
                        this.dialogRef.close(true);
                        this._router.navigate(['/manage/scene-editor/editor'], { state : this.postBody});
                    } else {
                        alert(res['message']);
                    }
                });

            }, 300);
        } else {
            this.isFormValid = false;
        }
        
    }
}