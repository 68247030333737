<mat-grid-list cols="2" rowHeight="100%">
  <mat-grid-tile class="loginBg">
    <div>
      <h1 class="white__color">Hello. Welcome!</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown.
      </p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="loginForm">
    <div *ngIf="isVerified">
      <img src="assets/icon_tick.svg" alt="" class="init-icon" />
      <h1 class="txtBlack">Congrats,</h1>
      <p class="txtBlack">
        You have verified your account, please go ahead and login to your
        account for more!
      </p>
      <div class="btnFormAction">
        <button mat-flat-button color="primary" (click)="doLogin()">
          Log In
        </button>
      </div>
    </div>
    <div *ngIf="!isVerified">
      <img src="assets/icon_error.svg" alt="" class="init-icon" />
      <h1 class="txtBlack">Oops!,</h1>
      <p class="txtBlack">{{ message }}</p>
      <div class="btnFormAction">
        <button mat-flat-button color="primary" (click)="doLogin()">
          Log In
        </button>
      </div>
    </div>
  </mat-grid-tile></mat-grid-list
>
