import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';

import { AdminOrganisationService } from "../../../services/organisation.service";
import { AdminSlabService } from "../../../../slabs/services/slabs.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-admin-org-addtab-profile",
    templateUrl: "./organisation-profile.component.html",
    styleUrls: ["./organisation-profile.component.scss"]
})

export class AdminOrgTabAddProfileComponent implements OnInit {

    @Input() orgId: string;

    alertMessage: Object = {
        message: null,
        error: false,
    };

    data: Object = {};
    isLoading: Boolean = false;
    slabs: Array<Object> = [];
    totalCount: Number = 0;
    selectedSlab: Object = null;

    myForm = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
        email: new UntypedFormControl("", [Validators.required, Validators.email]),
        license: new UntypedFormControl("", [Validators.required]),
        password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
        confirm_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(12), passwordMatchValidator]),
        contact_person: new UntypedFormControl("", [Validators.required]),
        primary_contact: new UntypedFormControl("", [Validators.required]),
        secondary_contact: new UntypedFormControl("", [Validators.required]),
        two_factor: new UntypedFormControl("")
    });

    constructor(
        private _service: AdminOrganisationService,
        private slabService: AdminSlabService,
        private _route: Router,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        this.slabService.getSlabs(1, 10000).then(res => {
            this.slabs = res["data"];
            this.totalCount = parseInt(res["count"]);
        })
    }

    onSlabSelected(event) {
        this.selectedSlab = event.value;
    }

    save(): void {
        
        this.spinner.show();
        let postBody = {
            name: this.myForm.value.name,
            email: this.myForm.value.email,
            password: this.myForm.value.password,
            contact_person: this.myForm.value.contact_person,
            primary_contact: this.myForm.value.primary_contact,
            secondary_contact: this.myForm.value.secondary_contact,
            two_factor: this.myForm.value.two_factor && this.myForm.value.two_factor !== '' ? this.myForm.value.two_factor : false,
            ftp_host: "",
            ftp_password: "",
            ftp_port: 21,
            ftp_username: "",
            licence_count: this.selectedSlab['license_count'],
            subscription_id: this.selectedSlab['_id'],
            subscription_plan: this.selectedSlab['name'],
        }

        this._service.setOrganisation(postBody).then(res => {
            this.spinner.hide();
            if (res["status"] !== 200) {
                this.alertMessage["message"] = res["message"];
                this.alertMessage["error"] = true;
            } else {
                this.alertMessage["message"] = res["message"];
                this.alertMessage["error"] = false;
                this._route.navigate(["/admin/organisations"]);
            }
        });
    }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const parent = formGroup.parent as UntypedFormGroup;
    if (!parent) return null;
    return parent.get('password').value === parent.get('confirm_password').value ?
        null : { 'mismatch': true };
}