<mat-grid-list cols="2" rowHeight="100%">
  <mat-grid-tile class="loginBg">
    <div>
      <h1 class="white__color">Hello. Welcome!</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown.
      </p>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="loginForm">
    <div>
      <h1 class="txtBlack">Verify your device</h1>
      <p class="txtBlack">
        We have send an OTP to your registered email. Please verify our device
        before login.
      </p>
      <app-alert
        *ngIf="alertMessage['message']"
        [message]="alertMessage['message']"
        [error]="alertMessage['error']"
      ></app-alert>
      <form
        class="formWrapper"
        [formGroup]="myForm"
        (ngSubmit)="doVerifyCode()"
      >
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="10 Digit Code"
            name="code"
            formControlName="code"
            autocomplete="off"
          />
          <mat-error *ngIf="myForm.get('code').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
        <div class="btnFormAction">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            Verify
          </button>
        </div>
      </form>
    </div>
  </mat-grid-tile>
</mat-grid-list>
