import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { SessionStorage } from "./session-storage";
import { API_URL } from "../config";

const METHOD = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

@Injectable({
  providedIn: "root",
})
export class CommonHttpService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorage
  ) {}

  get(endPoint) {
    return this.caller(endPoint, METHOD.get);
  }

  post(endPoint, body) {
    return this.caller(endPoint, METHOD.post, body);
  }

  put(endPoint, body = null) {
    return this.caller(endPoint, METHOD.put, body);
  }

  delete(endPoint, body = "") {
    return this.caller(endPoint, METHOD.delete, body);
  }

  async caller(endPoint, method, body = {}, headers = {}) {
    const headerOpts = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.sessionStorageService.getIdToken(),
        ...headers,
      },
    };

    const userData = JSON.parse(this.sessionStorageService.getUserData());
    let orgId = null;

    if(userData && userData.organisation != null) {
      orgId = userData.organisation.id;
    } else {
      orgId = JSON.parse(this.sessionStorageService.getUserData())['_id'];
    }

    if (method != "GET") {
      body['org_id'] = orgId;
      headerOpts["body"] = body;
    } else {
      endPoint = endPoint.includes("?") ? endPoint + "&org_id=" + encodeURIComponent(orgId) : endPoint + "?org_id=" + encodeURIComponent(orgId);
    }

    return this.httpClient
      .request(method, API_URL + endPoint, headerOpts)
      .toPromise();
  }
}
