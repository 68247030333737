import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-upload-asset",
  templateUrl: "./upload-asset.component.html",
  styleUrls: ["./upload-asset.component.scss"]
})
export class UploadAssetComponent implements OnInit {
  crumbItems: Array<Object>;

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false
      },
      {
        label: "Media Library",
        path: "dashboard/assets",
        current: true
      },
      {
        label: "Upload",
        path: "dashboard/assets/upload",
        current: true
      }
    ];
  }
}
