import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-admin-org-account-config",
    templateUrl: "./account.component.html",
    styleUrls: ["./account.component.scss"]
})

export class AdminOrgAccountConfigComponent implements OnInit {
    @Input() orgId: string;


    ngOnInit(): void {
        // 
    }

    constructor() {

    }

    save(): void {
        // 
    }
}