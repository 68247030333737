<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="!isLoading">
  <app-alert
    *ngIf="alertMessage['message']"
    [message]="alertMessage['message']"
    [error]="alertMessage['error']"
  ></app-alert>
  <form autocomplete="on" [formGroup]="myForm" (ngSubmit)="save()">
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            name="name"
            formControlName="name"
          />
          <mat-error *ngIf="myForm.get('name').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('name').hasError('minlength')"
            >Min length should be 5</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Email address"
            name="email"
            formControlName="email"
          />
          <mat-error *ngIf="myForm.get('email').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('email').hasError('minlength')"
            >Min length should be 5</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            name="password"
            formControlName="password"
          />
          <mat-error *ngIf="myForm.get('password').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('password').hasError('minlength')"
            >Min length should be 5</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Confirm Password"
            name="confirm_password"
            formControlName="confirm_password"
          />
          <mat-error *ngIf="myForm.get('confirm_password').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error
            *ngIf="myForm.get('confirm_password').hasError('minlength')"
            >Min length should be 5</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-12">
        <h3>Contact Details</h3>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Contact Name"
            name="contact_person"
            formControlName="contact_person"
          />
          <mat-error *ngIf="myForm.get('contact_person').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Primary Contact"
            name="primary_contact"
            formControlName="primary_contact"
          />
          <mat-error *ngIf="myForm.get('primary_contact').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Secondary Contact"
            name="secondary_contact"
            formControlName="secondary_contact"
          />
          <mat-error
            *ngIf="myForm.get('secondary_contact').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-12">
        <h3>License Details</h3>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <mat-select
            placeholder="Choose License"
            name="license"
            formControlName="license"
            (selectionChange)="onSlabSelected($event)"
          >
            <mat-option [value]="item" *ngFor="let item of slabs">{{
              item.name
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="myForm.get('license').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-checkbox
          color="primary"
          name="two_factor"
          formControlName="two_factor"
          >Enable Two Factor Auth?</mat-checkbox
        >
      </div>
    </div>
    <div class="row mb10 mt10" *ngIf="selectedSlab">
      <div class="col-xs-6 selected-slab">
        <p><strong>Name:</strong> {{ selectedSlab['name'] }}</p>
        <p><strong>License Count:</strong> {{ selectedSlab['license_count'] }}</p>
        <p>
          <strong>Status:</strong>
          {{ selectedSlab['status'] ? "Enabled" : "Disabled" }}
        </p>
        <p><strong>Storage:</strong> {{ selectedSlab['cloud_storage'] + "GB" }}</p>
      </div>
    </div>
    <div class="row mb10 mt25">
      <div class="col-xs-6">
        <button mat-flat-button color="primary" [disabled]="!myForm.valid">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>