import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

import { RolesService } from "../../roles/services/roles.service";
import { UsersService } from "../services/users.service";

@Component({
  selector: "app-user-roles-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class OrgUserFormComponent implements OnInit {
  crumbItems: Array<Object>;
  id: any;
  saveBtnText = "Save";
  rolesArray: Array<Object> = [];

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(4)]),
    email: new UntypedFormControl("", [Validators.required, Validators.minLength(4), Validators.email]),
    role: new UntypedFormControl("", [Validators.required]),
    password: new UntypedFormControl("", [Validators.minLength(8), Validators.maxLength(12)]),
    confirm_password: new UntypedFormControl("", [Validators.minLength(8), Validators.maxLength(12), passwordMatchValidator])
  });

  constructor(
    private _route: Router,
    private _service: UsersService,
    private _roleService: RolesService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false,
      },
      {
        label: "Manage Users",
        path: "/manage/users",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = this.id ? "Update" : "Save";

    if (this.id) {
      this.spinner.show();
      
      this._roleService.getRoles(1,1000).then((res) => {
        this.rolesArray = res['data'];
        this._service.getUser(this.id).then((res) => {
          this.myForm.get("name").setValue(res["data"]["name"]);
          this.myForm.get("email").setValue(res["data"]["email"]);
          this.myForm.get("role").setValue(res["data"]["role"]);
          this.changeDetectorRef.detectChanges();
          this.spinner.hide();
        });
      });
    } else {
      this._roleService.getRoles(1,1000).then((res) => {
        this.rolesArray = res['data'];
      });
    }
 
  }

  save() {
    let postBody = {
      name: this.myForm.value.name,
      role: this.myForm.value.role,
      email: this.myForm.value.email,
      password: this.myForm.value.password,
    };

    this.spinner.show();
    if (this.id) {
      this._service.updateUser(postBody, this.id).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/users"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    } else {
      this._service.setUser(postBody).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/users"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    }
  }
  
}

export const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const parent = formGroup.parent as UntypedFormGroup;
  if (!parent) return null;
  return parent.get('password').value === parent.get('confirm_password').value ?
      null : { 'mismatch': true };
}