import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-asset-tabs-content",
  templateUrl: "./tabs-content.component.html",
  styleUrls: ["./tabs-content.component.scss"],
})
export class TabsContentComponent implements OnInit {
  @Input() data: Array<Object>;
  @Input() totalCount: Array<Object>;
  
  @Output() onDeleteItem = new EventEmitter();
  @Output() onViewItem = new EventEmitter();
  @Output() onPageChangeEvent = new EventEmitter();

  ngOnInit() {}

  onPageChange(event: PageEvent) {
    this.onPageChangeEvent.emit(event);
  } 

  onDelete(id) {
    this.onDeleteItem.emit(id);
  }

  onView(id: any) {
    this.onViewItem.emit(id);
  }
}
