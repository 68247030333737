import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-scene-ediitor-add-item-modal",
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})

export class SceneAddItemModal implements OnInit {
    constructor() {}

    ngOnInit() {
        // 
    }

    chosenWidget(widget: string) {
        
    }
}