import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from "@angular/forms";

import { AccountsService } from "../../services/account.services";

@Component({
    selector: "app-user-account-tab-password",
    templateUrl: "./tab-user-account-password.component.html",
    styleUrls: ["./tab-user-account-password.component.scss"]
})

export class UserAccountTabPasswordComponent implements OnInit {
    isPasswordError: Boolean = false;

    alertMessage: Object = {
        message: null,
        error: false,
    };

    myForm = new UntypedFormGroup({
        current_password: new UntypedFormControl("", [Validators.required]),
        new_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
        confirm_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(12), passwordMatchValidator])
    });

    constructor(
        private _service: AccountsService,
        private formBuilder: FormBuilder
    ) {
        // 
    }

    ngOnInit() {
        // 
    }

    verifyPassword(password: string) {
        return this._service.verifyCurrentPassword(password);
    }

    async save() {
        this.verifyPassword(this.myForm.value.current_password).then(res => {

            this.isPasswordError = res['OK'] ? false : true;
            console.log(this.myForm.valid && this.isPasswordError);

            if (this.myForm.valid && !this.isPasswordError) {
                let postBody = {
                    password: this.myForm.value.new_password
                };

                this._service.setUserPassword(postBody).then(res => {
                    this.alertMessage["message"] = res["message"];
                    this.alertMessage["error"] = false;
                });
            }
        });
    }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const parent = formGroup.parent as UntypedFormGroup;
    if (!parent) return null;
    return parent.get('new_password').value === parent.get('confirm_password').value ?
        null : { 'mismatch': true };
}