
<app-editor-header [headerContent]="content" [showCancelBtn]='true' [showSaveBtn]='true' (onSave)="onSave()" (onCancel)="onCancel()"></app-editor-header>
<div class="clearfix"></div>
<app-zone-editor-toolbar (onAdd)="onAdd()" (onDelete)="onDelete()" (onZoomChange)="onZoomChange($event)"></app-zone-editor-toolbar>
<div class="clearfix"></div>
<div class="row pt10">
    <div class="col-xs-12">
        <app-alert *ngIf="alertMessage['message']" [message]="alertMessage['message']" [error]="alertMessage['error']"></app-alert> </div></div>
<div class="clearfix"></div>
<div class="row pt20">
    <div class="col-sm-9" id="editorMasterWrapper">
        <div class="editor-wrapper pt30 pb30 pl30 pr30" (click)='onObjectSelection()' #editorWrapper> <canvas id="screenLayoutEditorCanvasWrap" [width]='canvasWidth' [height]='canvasHeight'  style="border: #333 solid 1px;"></canvas> </div> </div>
    <div class="col-sm-3">
        <app-zone-property [width]='activeObjectWidth' [height]='activeObjectHeight' [left]='activeObjectOffsetLeft'  [top]='activeObjectOffsetTop' (onWidthChange)="onWidthChange($event)" (onHeightChange)="onHeightChange($event)" (onLeftChange)=
            "onLeftChange($event)" (onTopChange)="onTopChange($event)"></app-zone-property> </div></div>