<mat-grid-list cols="2" rowHeight="100%">
  <mat-grid-tile class="loginBg">
    <div>
      <h1 class="white__color">Hello. Welcome!</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown.
      </p>
      <button class="btnAction" (click)="doLogin()">
        Already registered? Please Log In
      </button>
    </div>
  </mat-grid-tile>
  <mat-grid-tile class="loginForm">
    <div>
      <h1 class="txtBlack">Create an account</h1>
      <p class="txtBlack">
        Would you like to join with us, to get your brand online?. Please fill
        up the form and our executive will get back to you.
      </p>
      <app-alert
        *ngIf="alertMessage['message']"
        [message]="alertMessage['message']"
        [error]="alertMessage['error']"
      ></app-alert>
      <form
        class="formWrapper"
        [formGroup]="myForm"
        (ngSubmit)="doCreateAccount()"
        #regForm="ngForm"
      >
        <div class="row mt10">
          <div class="col-xs-6">
            <mat-form-field>
              <input
                matInput
                placeholder="Organisation Name"
                formControlName="org_name"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('org_name').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('org_name').hasError('minlength')"
                >Min length should be 5</mat-error
              >
              <mat-error *ngIf="myForm.get('org_name').hasError('maxlength')"
                >Max length should be 10</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-xs-6">
            <mat-form-field>
              <input
                matInput
                placeholder="Contact Person"
                formControlName="contact_person"
                autocomplete="off"
              />
              <mat-error
                *ngIf="myForm.get('contact_person').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error
                *ngIf="myForm.get('contact_person').hasError('minlength')"
                >Min length should be 5</mat-error
              >
              <mat-error
                *ngIf="myForm.get('contact_person').hasError('maxlength')"
                >Max length should be 10</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10">
          <div class="col-xs-12">
            <mat-form-field>
              <input
                matInput
                placeholder="Email Address"
                formControlName="email"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('email').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('email').hasError('email')"
                >Should be a valid email</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10">
          <div class="col-xs-6">
            <mat-form-field>
              <input
                type="tel"
                matInput
                placeholder="Primary Contact"
                formControlName="primary_contact"
                autocomplete="off"
              />
              <mat-error
                *ngIf="myForm.get('primary_contact').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error
                *ngIf="myForm.get('primary_contact').hasError('pattern')"
                >Should be a valid phone number</mat-error
              >
              <mat-error
                *ngIf="myForm.get('primary_contact').hasError('maxlength')"
                >Max length should be 10</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-xs-6">
            <mat-form-field>
              <input
                type="tel"
                matInput
                placeholder="Secondary Contact"
                formControlName="secondary_contact"
                autocomplete="off"
              />
              <mat-error
                *ngIf="myForm.get('secondary_contact').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error
                *ngIf="myForm.get('secondary_contact').hasError('pattern')"
                >Should be a valid phone number</mat-error
              >
              <mat-error
                *ngIf="myForm.get('secondary_contact').hasError('maxlength')"
                >Max length should be 10</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <mat-checkbox
          color="primary"
          class="mat-checkbox-label-2"
          formControlName="agreement_cb"
        >
          By signing up with us, you are agreeing to our
          <a href="">Terms & Conditions</a> and <a href="#">Privacy Policy</a>
        </mat-checkbox>
        <div class="btnFormAction">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            Submit
          </button>
        </div>
      </form>
    </div>
  </mat-grid-tile></mat-grid-list
>
