import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class AdminSlabService {

    constructor(private commonHttpService: CommonHttpService) { }

    getSlabs(page, limit, searchQry = '') {
        return this.commonHttpService.get('/slabs?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry).then(res => res);
    }

    setSlab(postBody) {
        return this.commonHttpService.post('/slabs', postBody).then(res => res);
    }

    getSlab(Id) {
        return this.commonHttpService.get('/slabs/' + encodeURI(Id)).then(res => res);
    }

    updateSlab(postBody, Id) {
        return this.commonHttpService.put('/slabs/' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteSlab(postBody) {
        return this.commonHttpService.delete('/slabs', postBody).then(res => res);
    }
}