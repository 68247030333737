<h2 mat-dialog-title>{{ data['title'] }}</h2>
<div mat-dialog-content>
  <p>{{ data['content'] }}</p>
</div>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" color="secondary">
    Cancel
  </button>
  <button mat-button cdkFocusInitial [mat-dialog-close]="true" color="primary">
    Ok
  </button></mat-dialog-actions
>
