import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

import { RolesService } from "../services/roles.service";

@Component({
  selector: "app-user-roles-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class RoleFormComponent implements OnInit {
  crumbItems: Array<Object>;
  id: any;
  saveBtnText = "Save";
  permissionsArray: Array<Object> = [];
  selectedPermissions: Array<number> = [];

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(4)]),
    code: new UntypedFormControl("", [Validators.required, Validators.minLength(4)]),
  });

  constructor(
    private _route: Router,
    private _service: RolesService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Players",
        path: "/manage/players",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = this.id ? "Update" : "Save";

    if (this.id) {
      this.spinner.show();
      this._service.getRole(this.id).then((res) => {
        this.myForm.get("name").setValue(res["data"]["name"]);
        this.myForm.get("code").setValue(res["data"]["role_code"]);
        this.selectedPermissions = res["data"]["permissions"];
        this.changeDetectorRef.detectChanges();
        this.spinner.hide();
      });
    }

    this.permissionsArray = [
      {
        id: 1,
        label: "Manage Locations"
      },
      {
        id: 2,
        label: "Manage Players"
      },
      {
        id: 3,
        label: "Manage Player Groups"
      },
      {
        id: 4,
        label: "Manage Dimensions"
      },
      {
        id: 5,
        label: "Manage Assets"
      },
      {
        id: 12,
        label: "Manage Playlists"
      },
      {
        id: 13,
        label: "Manage Scheduler"
      },
      {
        id: 14,
        label: "Manage User Roles"
      },
      {
        id: 15,
        label: "Manage Users"
      },
      {
        id: 6,
        label: "Zone Editor"
      },
      {
        id: 11,
        label: "Scene Editor"
      },
      {
        id: 8,
        label: "Show Analytics Screen"
      },
      {
        id: 9,
        label: "Show Activity"
      },
      {
        id: 10,
        label: "Show Notifications"
      },
    ]
  }

  save() {
    let postBody = {
      name: this.myForm.value.name,
      role_code: this.myForm.value.code,
      permissions: this.selectedPermissions
    };

    this.spinner.show();
    if (this.id) {
      this._service.updateRole(postBody, this.id).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/user-role"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    } else {
      this._service.setRole(postBody).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/user-role"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    }
  }

  setPermission(event, id) {
    if(event.checked) {
      this.selectedPermissions.push(id);
    } else {
      if(this.selectedPermissions.includes(id)) {
        var index = this.selectedPermissions.indexOf(id);
        this.selectedPermissions.splice(index, 1);
      }
    }
  }

  isChecked(id) {
    return this.selectedPermissions.includes(id);
  }
}