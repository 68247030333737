import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "underscore";

@Component({
  selector: "app-events-tabs-content",
  templateUrl: "./tabs-content.component.html",
  styleUrls: ["./tabs-content.component.scss"],
})
export class EventTabsContentComponent implements OnInit, OnChanges {

  @Input() data: Array<Object>;
  @Input() totalCount: Array<Object>;

  // @Output() onDeleteItem = new EventEmitter();
  @Output() onPageChangeEvent = new EventEmitter();
  @Output() onHandlePublish = new EventEmitter();
  @Output() onHandleDelete = new EventEmitter();
  @Output() onHandleStop = new EventEmitter();
  @Output() onHandleRefresh = new EventEmitter();

  displayedColumns: string[] = [
    "event_name",
    "name",
    "start_on",
    "ends_on",
    "has_expiry",
    "is_recurring",
    "next_trigger_on",
    "allocated_playlists",
    "playlist_count",
    "action",
  ];
  dataSource = new MatTableDataSource();
  checkedList: Array<string> = [];

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.data;
  }

  onPageChange(event: PageEvent) {
    this.onPageChangeEvent.emit(event);
  }

  // onDelete(id) {
  //   this.onDeleteItem.emit(id);
  // }

  handlePublishEvent(id) {
    this.onHandlePublish.emit(id);
  }

  handleStopEvent(id) {
    this.onHandleStop.emit(id);
  }

  handleDelete(id) {
    this.onHandleDelete.emit(id);
  }

  handleRefresh(id) {
    this.onHandleRefresh.emit(id);
  }
}
