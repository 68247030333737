<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Media Library"
  [isBackEnabled]="false"
  (onAdd)="onAdd($event)"
  [showBulkMode]="false"
  showAddButton="true"
  btnText="Upload New"
  showSearch="true"
  (onSearch)="onSearch($event)"
></app-module-header>
<app-tabs
  [tabComponents]="tabComponents"
  (tabChange)="tabChange($event)"
></app-tabs>
<app-asset-tabs-content
  [totalCount]="totalCount"
  [data]="data"
  (onDeleteItem)="onDelete($event)"
  (onViewItem)="onView($event)"
  (onPageChangeEvent)="onPageChange($event)"
></app-asset-tabs-content
>
