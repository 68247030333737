import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class ActivityService {

    constructor(private commonHttpService: CommonHttpService) { }

    getActivities(page, limit) {
        return this.commonHttpService.get('/activity/user?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit)).then(res => res);
    }
}