<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Add New - Playlist"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="row">
  <div class="col-xs-4">
    <form [formGroup]="myForm" (ngSubmit)="onSave()">
      <div class="row mt15 mb15">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <input
              matInput
              placeholder="Name"
              name="name"
              formControlName="name"
              autocomplete="off"
            />
            <mat-error *ngIf="myForm.get('name').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
          <div class="mat-error" *ngIf="nameAlreadyAssigned">
            Playlist name is already assigned
          </div>
        </div>
      </div>
      <div class="row mt15 mb15">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-select
              placeholder="Supported Dimension"
              name="dimension"
              formControlName="dimension"
            >
              <mat-option [value]="item" *ngFor="let item of dimensions">{{
                item.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="myForm.get('dimension').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt15 mb15">
        <div class="col-xs-12">
          <mat-form-field class="example-full-width">
            <textarea
              matInput
              placeholder="Description"
              name="description"
              formControlName="description"
              maxlength="100"
            ></textarea>
            <mat-error *ngIf="myForm.get('description').hasError('required')"
              >Field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt15 mb15">
        <div class="col-xs-12">
          <button mat-flat-button color="primary" [disabled]="!myForm.valid">
            {{ saveBtnText }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-xs-8">
    <div class="assetWrapper">
      <div class="assetsListHeader">
        <h1>Add Scenes</h1>
        <button type="button" class="btnSecondary" (click)="showSceneModal()">
          New
        </button>
      </div>
      <div class="clearfix"></div>
      <div class="tableWrapper">
        <table width="100%" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">
              <h2>{{ element.name }}</h2>
            </td>
          </ng-container>
          <ng-container matColumnDef="dimension">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Max Supported Dim.
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.dimension +
                  " : " +
                  element.width +
                  " x " +
                  element.height
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="scene_sort">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Sort Order
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <app-playlist-scene-sort-order
                [data]="element"
                [sortedOrder]="sortedOrder"
                [index]="i"
              ></app-playlist-scene-sort-order>
            </td>
          </ng-container>
          <ng-container matColumnDef="durationReq">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Duration Required (Seconds)
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <app-playlist-scene-duration
                [data]="playlistDetail"
                [index]="i"
                [duration]="element.duration"
              ></app-playlist-scene-duration>
            </td>
          </ng-container>
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
            <td mat-cell *matCellDef="let element">
              {{ element.duration ? element.duration : "0" }}s /
              {{ element.duration | minuteSeconds }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-right"
            >
              Action
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              <button
                type="button"
                mat-button
                class="mat-button-custom"
                (click)="onRemoveSelectedScene(element)"
              >
                <img src="assets/delete.png" alt="" width="16" />
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
