<div class="groupContainer" *ngIf="value['players'][0]">
  <div class="row">
    <div class="col-sm-9">
      <span class="purple-dot pull-left"></span>
      <h3
        class="player-title"
        title="{{ value['players'][0].name }} {{ value['players'][0].location }}"
      >
        {{ value['players'][0].name }}, {{ value['players'][0].location }}
      </h3>
    </div>
    <div class="col-sm-3 text-right">
      <mat-icon (click)="onViewEvent(value['players'][0]['_id'])" matSuffix
        >visibility</mat-icon
      >
      <mat-icon (click)="onDeleteEvent(value['_id'])" matSuffix>delete</mat-icon>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="playlistItems">
        <div class="row" *ngFor="let playlist of value['playlists']">
          <div class="col-sm-9">
            <img src="assets/playlist-sm.png" alt="" class="pull-left" />
            <h3 class="pull-left playlist-item-title">{{ playlist.name }}</h3>
          </div>
          <div class="col-sm-3 text-right">
            <mat-icon
              (click)="
                onDeletePlaylist({
                  eventId: value['_id'],
                  playListId: playlist['_id']
                })
              "
              matSuffix
              >delete</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>