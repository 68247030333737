<app-alert
  *ngIf="alertMessage['message']"
  [message]="alertMessage['message']"
  [error]="alertMessage['error']"
></app-alert>
<form autocomplete="off" [formGroup]="myForm" (ngSubmit)="save()">
  <div class="row mt10 mb10">
    <div class="col-xs-6">
      <mat-form-field>
        <input
          type="password"
          matInput
          placeholder="Current Password"
          name="current_password"
          formControlName="current_password"
        />
        <mat-error *ngIf="myForm.get('current_password').hasError('required')"
          >Field is required</mat-error
        >
      </mat-form-field>
      <div class="field-error-text" *ngIf="isPasswordError">
        Current password is not matching
      </div>
    </div>
  </div>
  <div class="row mt10 mb10">
    <div class="col-xs-6">
      <mat-form-field>
        <input
          type="password"
          matInput
          placeholder="New Password"
          name="new_password"
          formControlName="new_password"
        />
        <mat-error *ngIf="myForm.get('new_password').hasError('required')"
          >Field is required</mat-error
        >
        <mat-error *ngIf="myForm.get('new_password').hasError('minlength')"
          >Min length should be 8</mat-error
        >
        <mat-error *ngIf="myForm.get('new_password').hasError('maxlength')"
          >Min length should be 12</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="row mt10 mb10">
    <div class="col-xs-6">
      <mat-form-field>
        <input
          type="password"
          matInput
          placeholder="Retype Password"
          name="confirm_password"
          formControlName="confirm_password"
        />
        <mat-error *ngIf="myForm.get('confirm_password').hasError('required')"
          >Field is required</mat-error
        >
        <mat-error *ngIf="myForm.get('confirm_password').hasError('minlength')"
          >Min length should be 8</mat-error
        >
        <mat-error *ngIf="myForm.get('confirm_password').hasError('maxlength')"
          >Min length should be 12</mat-error
        >
        <mat-error *ngIf="myForm.get('confirm_password').hasError('mismatch')"
          >Passwords do not match</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="row mb10 mt10">
    <div class="col-xs-6">
      <button mat-flat-button color="primary" [disabled]="!myForm.valid">
        Submit
      </button>
    </div>
  </div>
</form>