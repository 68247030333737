<app-module-header
  title="User Roles"
  [isBackEnabled]="false"
  [showBulkMode]="false"
  [showAddButton]="true"
  (onAdd)="onAdd()"
></app-module-header>
<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="_id">
      <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox
          color="primary"
          (change)="doSelectAll()"
          [checked]="isAllChecked()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(element._id)"
          (change)="doSelect(element._id)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.name }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="role_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role Code</th>
      <td mat-cell *matCellDef="let element">
        {{ element.role_code || "Nil" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="permissions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Permissions Allocated
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.permissions.length }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <button
          mat-button
          class="mat-button-custom"
          (click)="doEditRole(element._id)"
          title="Edit"
        >
          <img src="assets/edit.png" alt="" width="16" />
        </button>
        <button
          mat-button
          class="mat-button-custom"
          (click)="openDialog(element._id)"
          title="Delete"
        >
          <img src="assets/delete.png" alt="" width="16" />
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="clearfix"></div>
<app-paginate
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate>
