<div class="activityItemWrapper" *ngFor="let data of item">
  <div class="icon">
    <mat-icon
      aria-hidden="false"
      [ngClass]="{ success: data.status === 'S', error: data.status === 'F' }"
    >
      check_circle
    </mat-icon>
  </div>
  <div class="message">{{ data.message }}</div>
</div>