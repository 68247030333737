<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Update User"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="app-form-wrapper">
  <div class="row">
    <div class="col-xs-6">
      <form [formGroup]="myForm" (ngSubmit)="save()">
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Name"
                name="name"
                formControlName="name"
              />
              <mat-error *ngIf="myForm.get('name').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('name').hasError('minlength')"
                >Min length should be 5</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Email Address"
                name="email"
                formControlName="email"
              />
              <mat-error *ngIf="myForm.get('email').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('email').hasError('minlength')"
                >Min length should be 8</mat-error
              >
              <mat-error *ngIf="myForm.get('email').hasError('email')"
                >Valid email required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <mat-form-field>
              <mat-select
                placeholder="User Role"
                name="role"
                formControlName="role"
              >
                <mat-option
                  [value]="item._id"
                  *ngFor="let item of rolesArray"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
              <mat-error *ngIf="myForm.get('role').hasError('required')"
                >Field is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                type="password"
                matInput
                placeholder="Password"
                name="password"
                formControlName="password"
              />
              <mat-error *ngIf="myForm.get('password').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('password').hasError('minlength')"
                >Min length should be 5</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                type="password"
                matInput
                placeholder="Retype Password"
                name="confirm_password"
                formControlName="confirm_password"
              />
              <mat-error
                *ngIf="myForm.get('confirm_password').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error
                *ngIf="myForm.get('confirm_password').hasError('minlength')"
                >Min length should be 5</mat-error
              >
              <mat-error
                *ngIf="myForm.get('confirm_password').hasError('mismatch')"
                >Passwords do not match</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-12">
            <button mat-button color="primary" [disabled]="!myForm.valid">
              {{ saveBtnText }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>