<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Update User"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="app-form-wrapper">
  <div class="row">
    <div class="col-xs-6">
      <form [formGroup]="myForm" (ngSubmit)="save()">
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Name"
                name="name"
                formControlName="name"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('name').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('name').hasError('minlength')"
                >Min length should be 5</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-6">
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="Role Code"
                name="code"
                formControlName="code"
                autocomplete="off"
              />
              <mat-error *ngIf="myForm.get('code').hasError('required')"
                >Field is required</mat-error
              >
              <mat-error *ngIf="myForm.get('code').hasError('minlength')"
                >Min length should be 8</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-12">
            <strong
              ><h5 style="font-weight: bold">Permissions Required</h5></strong
            >
          </div>
          <div class="col-xs-4" *ngFor="let item of permissionsArray">
            <mat-checkbox
              [checked]="isChecked(item.id)"
              color="primary"
              class="pr10 cb-font"
              (change)="setPermission($event, item.id)"
            >
              {{ item.label }}
            </mat-checkbox>
          </div>
        </div>
        <div class="row mt10 mb10">
          <div class="col-xs-12">
            <button mat-button color="primary" [disabled]="!myForm.valid">
              {{ saveBtnText }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>