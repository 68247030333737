import { Component, Input, Output, EventEmitter } from "@angular/core";

export interface HeaderContent {
  title: string;
  dimension: string;
  orientation: string;
}

@Component({
  selector: "app-editor-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class ZoneEditorHeaderComponent {
  @Input() headerContent: HeaderContent;
  @Input() showSaveBtn: Boolean;
  @Input() showCancelBtn: Boolean;

  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  onSaveHandler() {
    this.onSave.emit();
  }

  onCancelHandler() {
    this.onCancel.emit();
  }
}
