<div
  class="clock-container"
  [ngStyle]="{ 'background-color': backgroundColor }"
>
  <div class="clock-col">
    <p class="clock-day clock-timer">{{ day }}</p>
    <p class="clock-label">Day</p>
  </div>
  <div class="clock-col">
    <p class="clock-hours clock-timer">{{ hours }}</p>
    <p class="clock-label">Hours</p>
  </div>
  <div class="clock-col">
    <p class="clock-minutes clock-timer">{{ minutes }}</p>
    <p class="clock-label">Minutes</p>
  </div>
  <div class="clock-col">
    <p class="clock-seconds clock-timer">{{ seconds }}</p>
    <p class="clock-label">Seconds</p>
  </div>
</div>