import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"]
})
export class AppTabsComponent implements OnInit {
  @Input() tabComponents: Array<Object>;
  @Output() tabChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  currentTab(index: Object): void {
    this.tabChange.emit(index);
  }

}
