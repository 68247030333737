import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

import { PlayersService } from "../services/players.service";
import { LocationsService } from "../../../../manage/pages/location/services/locations.service";
import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";

@Component({
  selector: "app-player-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class PlayerFormComponent implements OnInit {
  crumbItems: Array<Object>;
  id: any;
  saveBtnText = "Save";
  locations: Array<Object>;
  dimensions: Array<Object>;
  timezones: Array<string>;

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(4)]),
    code: new UntypedFormControl("", [Validators.required, Validators.minLength(4)]),
    location: new UntypedFormControl("", [Validators.required]),
    dimension: new UntypedFormControl("", [Validators.required]),
    storage: new UntypedFormControl("", [Validators.required]),
    timezone: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _route: Router,
    private _service: PlayersService,
    private _lservice: LocationsService,
    private _dservice: DimensionService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Players",
        path: "/manage/players",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = this.id ? "Update" : "Save";

    this.spinner.show();
    Promise.all([
      this._dservice.getDimensions(0, 1000000),
      this._lservice.getLocations(0, 1000000)
    ]).then((res) => {
      this.dimensions = res[0]["data"] || [];
      this.locations = res[1]["data"] || [];
      this.spinner.hide();
    });

    if (this.id) {
      this._service.getPlayer(this.id).then((res) => {
        this.myForm.get("name").setValue(res["data"]["name"]);
        this.myForm.get("code").setValue(res["data"]["code"]);
        this.myForm.get("location").setValue(res["data"]["location"]);
        this.myForm.get("dimension").setValue(res["data"]["dimension"]);
        this.myForm.get("storage").setValue(res["data"]["storage"]);
        this.myForm.get("timezone").setValue(res["data"]["timezone"]);
        this.spinner.hide();
      });
    }

    this._service.getTimezones().then((res) => {
      this.timezones = res['data'];
    });
  }

  save() {
    let postBody = {
      name: this.myForm.value.name,
      playerID: this.myForm.value.code,
      code: this.myForm.value.code,
      location: this.myForm.value.location,
      storage: this.myForm.value.storage,
      dimension: this.myForm.value.dimension,
      timezone: this.myForm.value.timezone
    };

    this.spinner.show();
    if (this.id) {
      this._service.updatePlayer(postBody, this.id).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/players"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    } else {
      this._service.setPlayer(postBody).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/players"]);
        } else {
          alert(res["message"]);
        }
        this.spinner.hide();
      });
    }

  }
}
