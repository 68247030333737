import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';

import { PlayerGroupsService } from "../services/player-groups.services";
import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";

@Component({
  selector: "app-player-group-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})

export class PlayerGroupListComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = [
    "_id",
    "name",
    "count",
    "location",
    "action"
  ];
  data: Array<Object>;
  totalCount: number;
  dataSource = new MatTableDataSource();
  checkedList: Array<string> = [];
  searchQry: string = '';
  bulkMenuItems: Array<string>;

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false
      },
      {
        label: "Player Groups",
        path: "manage/player-groups",
        current: true
      }
    ];

    this.bulkMenuItems = ["Delete"];

    this.getAllPlayerGroups(1, 10);
  }

  constructor(
    private _router: Router,
    private _service: PlayerGroupsService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  getAllPlayerGroups(page, limit, searchQry = '') {
    this.spinner.show();
    this._service.getPlayerGroups(page, limit, searchQry).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onMenuClicked(index: number) {
    switch (index) {
      case 0:
        this.deleteIdsArray(this.checkedList);
        break;

      default:
    }
  }

  onSearch(searchQry: any) {
    this.searchQry = searchQry;
    this.getAllPlayerGroups(1, 10, searchQry);
  }

  onPageChange(event: PageEvent) {
    this.getAllPlayerGroups(++event.pageIndex, event.pageSize, this.searchQry);
  }

  onEditGroup(playerId): void {
    this._router.navigate(["/manage/player-groups/edit/" + playerId]);
  }

  onAdd(): void {
    this._router.navigate(["/manage/player-groups/add/"]);
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }
  
  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  openDialog(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content:
          "Do you want to delete this entry from listing ?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deletePlayerGroup(postBody).then((res) => {
      if (res["status"] !== 200) {
        alert(res["message"]);
      }
      this.getAllPlayerGroups(1, 10, this.searchQry);
      this.spinner.hide();
    });
  }
}
