import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { HttpClientModule } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";
import { FileUploadModule } from "ng2-file-upload";
import { MatSelectModule } from "@angular/material/select";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { DrawerComponent } from "../../shared/components/drawer/drawer.component";
import { ModuleHeaderComponent } from "../../modules/user/shared/module-header/module-header.component";
import { PaginateComponent } from "../../shared/components/paginate/paginate.component";
import { CustomModalComponent } from "../../shared/components/custom-modal/custom-modal.component";
import { HeaderComponent } from "../../modules/user/shared/header/header.component";
import { AlertComponent } from "../../shared/components/alert/alert.component";
import { AppTabsComponent } from "../../shared/components/tabs/tabs.component";
import { BreadcrumbsComponent } from "../../modules/user/shared/breadcrumbs/breadcrumbs.component";
import { InnerdrawerComponent } from "../../modules/user/shared/innerdrawer/innerdrawer.component";
import { RouterModule } from "@angular/router";
import { MatTableModule } from "@angular/material/table";
import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';

import { ThumbnailDirective } from "../directives/thumbnail.directive";

import { FormatFileSizePipe } from '../pipes/FormatFileSizePipe';
import { MinuteSecondsPipe } from '../pipes/MinuteSecondsPipe';
import { SafeUrlPipe } from '../pipes/SafeUrlPipe';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDialogModule,
    RouterModule,
    MatTabsModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxDocViewerModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSelectSearchModule
  ],
  declarations: [
    DrawerComponent,
    ModuleHeaderComponent,
    PaginateComponent,
    CustomModalComponent,
    HeaderComponent,
    AlertComponent,
    BreadcrumbsComponent,
    InnerdrawerComponent,
    AppTabsComponent,
    ThumbnailDirective,
    FormatFileSizePipe,
    MinuteSecondsPipe,
    SafeUrlPipe
  ],
  exports: [
    MatToolbarModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDialogModule,
    DrawerComponent,
    DrawerComponent,
    ModuleHeaderComponent,
    PaginateComponent,
    CustomModalComponent,
    HeaderComponent,
    AlertComponent,
    BreadcrumbsComponent,
    InnerdrawerComponent,
    RouterModule,
    MatTabsModule,
    AppTabsComponent,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ThumbnailDirective,
    MatSelectModule,
    NgxDocViewerModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSelectSearchModule,
    FormatFileSizePipe,
    MinuteSecondsPipe,
    SafeUrlPipe
  ],

  entryComponents: [CustomModalComponent],
})
export class SharedModule {}
