import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-tab-groups",
    templateUrl: './tab-groups.component.html',
    styleUrls: ['./tab-groups.component.scss']
})
export class TabGroupsComponent implements OnInit {
  @Input() dataObj: Array<Object>;

  @Output() onDeleteEventEmitter = new EventEmitter();
  @Output() onDeletePlaylistEventEmitter = new EventEmitter();

  ngOnInit() {}

  constructor() {}

  onDeleteEvent(event) {
    this.onDeleteEventEmitter.emit(event);
  }

  onDeleteEventPl(event) {
    this.onDeletePlaylistEventEmitter.emit(event);
  }
}
