import { Component, OnInit, Input } from '@angular/core';
import { SessionStorage } from "../../../../services/session-storage";

@Component({
  selector: 'app-innerdrawer',
  templateUrl: './innerdrawer.component.html',
  styleUrls: ['./innerdrawer.component.scss']
})
export class InnerdrawerComponent implements OnInit {

  @Input('menuItems') menuItems:Array<Object>;
  @Input('drawerTitle') drawerTitle:string;
  permissions: Array<Number> = [];

  constructor(private sessionStorageService: SessionStorage) { 
    let permissions = sessionStorageService.getUserPermissions() != 'null' && sessionStorageService.getUserPermissions() != null ? sessionStorageService.getUserPermissions().split(',') : null;
    if(permissions != null && permissions.length > 0) {
      permissions.map((elem, index) => {
        this.permissions.push(parseInt(elem));
      });
    } else {
      this.permissions = null;
    }
  }

  ngOnInit() {
    // 
  }

}
