import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class PlayersService {

    constructor(private commonHttpService: CommonHttpService) { }

    getPlayers(page, limit, searchQry = '') {
        return this.commonHttpService.get('/players?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + encodeURIComponent(searchQry)).then(res => res);
    }

    setPlayer(postBody) {
        return this.commonHttpService.post('/players', postBody).then(res => res);
    }

    getPlayer(Id) {
        return this.commonHttpService.get('/players/' + encodeURI(Id)).then(res => res);
    }

    updatePlayer(postBody, Id) {
        return this.commonHttpService.put('/players/' + encodeURI(Id) , postBody).then(res => res);
    }

    deletePlayer(postBody) {
        return this.commonHttpService.delete('/players', postBody).then(res => res);
    }

    async getTimezones() {
        return await this.commonHttpService.get('/timezones');
    }

    async setShowCodeTrigger(code: string) {
        return await this.commonHttpService.post('/trigger/show_code/' + code, {});
    }
}