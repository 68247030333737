<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Add New - Dimension"
  [isBackEnabled]="true"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<form autocomplete="on" [formGroup]="myForm" (ngSubmit)="save()">
  <div class="row" *ngIf="responseErr">
    <div class="col-xs-12">
      <app-alert [message]="responseMsg" [error]="true"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-4">
      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="Name"
          value=""
          formControlName="name"
          autocomplete="off"
        />
        <mat-error *ngIf="myForm.get('name').hasError('required')"
          >Field is required</mat-error
        >
        <mat-error *ngIf="myForm.get('name').hasError('minlength')"
          >Min length should be 5</mat-error
        >
        <mat-error *ngIf="myForm.get('name').hasError('maxlength')"
          >Max length should be 10</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-4">
      <div class="appFormInput">
        <label for="" class="appLabel">Orientiation</label>
        <mat-radio-group formControlName="orientation">
          <mat-radio-button color="primary" value="landscape"
            >Landscape</mat-radio-button
          >
          <mat-radio-button color="primary" value="portrait"
            >Portrait</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="row mt10 mb10">
    <div class="col-xs-4">
      <mat-form-field class="example-full-width">
        <input
          type="number"
          matInput
          placeholder="Width"
          formControlName="width"
          autocomplete="off"
        />
        <span matSuffix>px &nbsp;</span>
      </mat-form-field>
    </div>
    <div class="col-xs-4">
      <mat-form-field class="example-full-width">
        <input
          type="number"
          matInput
          placeholder="Height"
          formControlName="height"
          autocomplete="off"
        />
        <span matSuffix>px &nbsp;</span>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button mat-button color="primary" [disabled]="!myForm.valid">
        {{ saveBtnText }}
      </button>
    </div>
  </div>
</form>
