<div class="property-wrapper">
  <div class="row">
    <div class="col-sm-12">
      <h2 class="m0">Property</h2>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row mt15">
    <div class="col-sm-6 form-group">
      <label for="">Left</label>
      <input
        type="text"
        (change)="onLeftChangeListener($event)"
        class="form-control"
        [value]="left"
        placeholder="in pixels"
      />
    </div>
    <div class="col-sm-6 form-group">
      <label for="">Top</label>
      <input
        type="text"
        class="form-control"
        (change)="onTopChangeListener($event)"
        [value]="top"
        placeholder="in pixels"
      />
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-sm-12 form-group">
      <label for="">Width</label>
      <input
        type="text"
        class="form-control"
        (change)="onWidthChangeListener($event)"
        [value]="width"
        placeholder="in pixels"
      />
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-sm-12 form-group">
      <label for="">Height</label>
      <input
        type="text"
        class="form-control"
        (change)="onHeightChangeListener($event)"
        [value]="height"
        placeholder="in pixels"
      />
    </div>
  </div>
</div>