import { Component, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from "underscore";

import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { AssetsService } from "../services/assets.service";

@Component({
  selector: "app-asset-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})

export class AssetListComponent implements OnInit {
  crumbItems: Array<Object>;
  tabComponents: Array<Object>;
  currentTab: Object;

  data: Array<Object> = [];
  totalCount: number = 0;
  searchQuery: string = "";

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _service: AssetsService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false,
      },
      {
        label: "Media Library",
        path: "dashboard/assets",
        current: true,
      },
    ];

    this.tabComponents = [
      {
        id: 1,
        label: "All",
        query: "all",
      },
      {
        id: 2,
        label: "Images",
        query: "images",
      },
      {
        id: 3,
        label: "Documents",
        query: "docs",
      },
      {
        id: 4,
        label: "Videos",
        query: "videos",
      },
    ];

    this.getAllAssets(1, 10, "all", "");
    this.currentTab = this.tabComponents[0];
  }

  tabChange(tab: MatTabChangeEvent) {
    this.currentTab = this.tabComponents[tab.index];
    this.searchQuery = "";
    this.getAllAssets(1, 10, this.currentTab["query"], "");
  }

  getAllAssets(page, limit, type, search) {
    this.spinner.show();
    this._service.getAssets(page, limit, type, search).then((res) => {
      this.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onAdd(event) {
    this.router.navigate(["/dashboard/assets/upload"]);
  } 

  onView(id: number) {
    this.router.navigate(["/dashboard/assets/view/" + id]);
  }

  onSearch(searchQry: any) {
    this.getAllAssets(1, 10, this.currentTab["query"], searchQry);
  }

  onDelete(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deleteAsset(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllAssets(1, 10, this.currentTab['query'], this.searchQuery);
      }
      this.spinner.hide();
    });
  }

  onPageChange(event: any) {
    this.getAllAssets(++event.pageIndex, event.pageSize, this.currentTab['query'], this.searchQuery);
  }
}
