import { Component, Input, OnInit } from "@angular/core";
@Component({
  selector: "app-playlist-scene-sort-order",
  templateUrl: "./sort-order.component.html",
  styleUrls: ["../../form/form.component.scss"],
})
export class SortOrderComponent implements OnInit {
  @Input("data") data: Object;
  @Input("index") index: number;
  @Input("sortedOrder") sortedOrder: Array<number>;
  visibleIndex: number;

  ngOnInit(): void {
    this.visibleIndex = this.sortedOrder.length ? this.sortedOrder[this.index] : this.index + 1;
  }
}
