<app-breadcrumbs [crumbItems]="crumbItems"></app-breadcrumbs>
<app-module-header
  title="Player Scenes"
  isBackEnabled="false"
  (onAdd)="openDialog()"
  showBulkMode="false"
  showAddButton="true"
  btnText="New"
  showSearch="true"
  (onSearch)="onSearch($event)"
></app-module-header>
<div class="clearfix"></div>
<div class="row mt20">
  <div class="col-xs-12">
    <app-tabs
      [tabComponents]="tabComponents"
      (tabChange)="tabChange($event)"
    ></app-tabs>
  </div>
</div>
<div class="clearfix"></div>
<!--Tab data-->
<div class="row">
  <div class="col-xs-12">
    <div class="gridContent">
      <mat-grid-list cols="12" rowHeight="2:4.5" gutterSize="12px">
        <mat-grid-tile
          colspan="3"
          class="matGridTile"
          *ngFor="let item of tabData"
        >
          <a
            class="linkContent"
            [ngStyle]="{
              background: 'url(' + item.thumbnail + ') no-repeat',
              'background-size': 'contain',
              'background-color': ' #ececec',
              'background-position': 'center'
            }"
          ></a>
          <mat-grid-tile-footer>
            <div class="flexStart">
              <h4>{{ item.name }} - {{ item.dimension }}</h4>
              <small
                >{{ item.duration | minuteSeconds }} &nbsp;&nbsp;&nbsp;{{
                  item.created_on | date: "d MMMM"
                }}</small
              >
            </div>
            <div class="flexEnd">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editScene(item._id)">
                  <span>Edit</span>
                </button>
                <button
                  mat-menu-item
                  (click)="openDeleteConfirmationDialog(item._id)"
                >
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
          </mat-grid-tile-footer>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
<!--Tab data-->
<div class="clearfix"></div>
<div class="row">
  <div class="col-xs-12">
    <app-paginate
      *ngIf="tabData.length > 0"
      [length]="totalCount"
      pageSize="10"
      (onPageChange)="onPageChange($event)"
    ></app-paginate>
  </div>
</div>
