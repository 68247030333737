import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-scheduler-player-groups",
  templateUrl: "./group-scheduler.component.html",
  styleUrls: ["./group-scheduler.component.scss"],
})
export class SchedulerGroupsComponent implements OnInit {
  @Input() value: Object = {};

  @Output() onDeleteEventEmitter = new EventEmitter();
  @Output() onDeletePlaylistEventEmitter = new EventEmitter();

  ngOnInit() {}

  constructor() {}

  onDeleteEvent(event) {
    this.onDeleteEventEmitter.emit(event);
  }

  onDeletePlaylist(event) {
    this.onDeletePlaylistEventEmitter.emit(event);
  }
}
