<app-module-header
  title="Player Scheduler"
  [isBackEnabled]="false"
  [showBulkMode]="false"
  [showAddButton]="false"
></app-module-header>
<div class="clearfix"></div>
<div class="contentWrapper">
  <div class="row">
    <div class="col-xs-4">
      <div class="playerListWrapper">
        <app-tabs
          [tabComponents]="tabComponents"
          (tabChange)="tabChange($event)"
        ></app-tabs>
        <div class="clearfix"></div>
        <mat-grid-list
          cols="12"
          gutterSize="12px"
          rowHeight="72px"
          class="searchbarComponent"
        >
          <mat-grid-tile colspan="9">
            <div class="moduleHeaderRightItems">
              <div class="searchBar">
                <form (submit)="onSearch()">
                  <mat-form-field class="example-full-width">
                    <input
                      type="text"
                      name="searchQry"
                      matInput
                      placeholder="Search"
                      [(ngModel)]="searchQry"
                    />
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </form>
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <!-- <button class="btnSecondary" (click)="openDialog()">              New            </button> -->
          </mat-grid-tile>
        </mat-grid-list>
        <!--Player details-->
        <app-tab-players
          [dataObj]="tabData"
          (onDeleteEventEmitter)="onDeleteEvent($event)"
          (onViewEventEmitter)="onViewPlayerEvents($event)"
          (onDeletePlaylistEventEmitter)="onDeletePlaylist($event)"
          *ngIf="currentTab['query'] === 'players'"
        ></app-tab-players>
        <app-tab-groups
          [dataObj]="tabData"
          (onDeleteEventEmitter)="onDeleteEvent($event)"
          (onDeletePlaylistEventEmitter)="onDeletePlaylist($event)"
          *ngIf="currentTab['query'] === 'playergroups'"
        ></app-tab-groups>
      </div>
    </div>
    <div class="col-xs-8 pb20">
      <full-calendar
        #calendar
        defaultView="dayGridMonth"
        [weekLabel]="'test'"
        [plugins]="calendarPlugins"
        [header]="{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }"
        eventLimit="true"
        (eventRender)="(eventRender)"
        [events]="events"
        editable="false"
        (datesRender)="handleDatesRender($event)"
      ></full-calendar>
    </div>
  </div>
</div>
