import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class ZoneEditorService {

    constructor(private commonHttpService: CommonHttpService) { }

    getZones(page, limit, searchQry = '') {
        return this.commonHttpService.get('/zones?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + encodeURIComponent(searchQry)).then(res => res);
    }

    setZone(postBody) {
        return this.commonHttpService.post('/zones', postBody).then(res => res);
    }

    isZoneExists(postBody) {
        return this.commonHttpService.post('/zones/exists', postBody).then(res => res);
    }

    getZone(Id) {
        return this.commonHttpService.get('/zones/' + encodeURI(Id)).then(res => res);
    }

    deleteZone(postBody) {
        return this.commonHttpService.delete('/zones', postBody).then(res => res);
    }
}