import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { UntypedFormControl, UntypedFormGroup, Validators, NgForm  } from "@angular/forms";
import { RegisterUserService } from "./services/create-user.service";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit {

  alertMessage = {
    message: null,
    error: null,
  };

  myForm = new UntypedFormGroup({
    org_name: new UntypedFormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
    contact_person: new UntypedFormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    primary_contact: new UntypedFormControl("", [Validators.required, Validators.pattern(new RegExp("[0-9]{10}")), Validators.maxLength(10)]),
    secondary_contact: new UntypedFormControl("", [Validators.required, Validators.pattern(new RegExp("[0-9]{10}")), Validators.maxLength(10)]),
    agreement_cb: new UntypedFormControl(false, Validators.requiredTrue)
  });

  @ViewChild('regForm', {static: false}) createUserForm: NgForm;

  constructor(private _router: Router, private _service: RegisterUserService) {}

  ngOnInit() {
    // 
  }

  doCreateAccount(): void {

    let postBody = {
      orgName: this.myForm.value.org_name,
      contact_person: this.myForm.value.contact_person,
      email: this.myForm.value.email,
      primary_contact: this.myForm.value.primary_contact,
      secondary_contact: this.myForm.value.secondary_contact,
    };

    this._service.createUser(postBody).subscribe(res => {
      if(res['OK']) {
        this.alertMessage.message = res['message'];
        this.alertMessage.error = false;
        this.myForm.reset();
        this.createUserForm.resetForm();
      } else {
        this.alertMessage.message = res['message'];
        this.alertMessage.error = true;
      }
    });

  }

  doLogin(): void {
    this._router.navigate(["/"]);
  }
}
