import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { NgxSpinnerService } from 'ngx-spinner';

import { CustomModalComponent } from "../../../../../../shared/components/custom-modal/custom-modal.component";
import { PlayListService } from "../services/playlist.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})

export class PlaylistListComponent implements OnInit {
  bulkMenuItems: Array<string>;
  displayedColumns: string[] = [
    "_id",
    "name",
    "dimension",
    "duration",
    "scene_count",
    "action"
  ];
  dataSource = new MatTableDataSource();
  totalCount: number = 0;
  searchQry: string = '';
  checkedList: Array<string> = [];

  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private _service: PlayListService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.bulkMenuItems = ["Delete"];
    this.getAllPlaylists(1, 10);
  }

  getAllPlaylists(page, limit, searchQry = '') {
    this.spinner.show();
    this._service.getPlaylists(page, limit, searchQry).then((res) => {
      this.dataSource.data = res["data"];
      this.totalCount = res["count"];
      this.spinner.hide();
    });
  }

  onMenuClicked(index: number) {
    switch (index) {
      case 0:
        this.deleteIdsArray(this.checkedList);
        break;

      default:
    }
  }

  onAdd() {
    this._router.navigate(["/manage/playlists/add"]);
  }

  onEditPlaylist(playerId): void {
    this._router.navigate(["/manage/playlists/edit/" + playerId]);
  }

  onPageChange(event: PageEvent) {
    this.getAllPlaylists(++event.pageIndex, event.pageSize, this.searchQry);
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.dataSource.data.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.dataSource.data.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  openDialog(id: Array<string>) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion!",
        content:
          "Do you want to delete this entry from listing?  You can keep the license for this particular player",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };

    this.spinner.show();
    this._service.deletePlaylist(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllPlaylists(1, 10, this.searchQry);
      }
      this.spinner.hide();
    });
  }

  onSearch(searchQry: any) {
    this.searchQry = searchQry;
    this.getAllPlaylists(1, 10, searchQry);
  }

  toHoursMinutesSeconds(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    let result = `${minutes
      .toString()
      .padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return result;
  };
}
