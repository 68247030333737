import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";

import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { AdminOrganisationService } from "../services/organisation.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})

export class AdminOrgListComponent implements OnInit {
  bulkMenuItems: Array<string>;
  organisations: Array<Object> = [];
  totalCount: number = 0;
  checkedList: Array<string> = [];
  searchQry: string = '';

  constructor(
    private _route: Router,
    public dialog: MatDialog,
    private _service: AdminOrganisationService
  ) { }

  ngOnInit() {
    this.bulkMenuItems = ["Delete"];
    this.getAllOrgs(1, 10);
  }

  getAllOrgs(page, limit, search = '') {
    this._service.getOrganisations(page, limit, search).then((res) => {
      this.organisations = res["data"];
      this.totalCount = res["count"];
    });
  }

  onMenuClicked(index: number) {
    switch (index) {
      case 0:
        // this.deleteIdsArray(this.checkedList);
        if (this.checkedList.length === 0) {
          this.openNoItemsSelectedDialog();
        } else {
          this.openDialog(this.checkedList);
        }

        break;

      default:
    }
  }

  onAdd() {
    this._route.navigate(["/admin/organisations/add"]);
  }

  onEdit(orgId: string): void {
    this._route.navigate(["/admin/organisations/edit/" + orgId]);
  }

  onActivateOrg(orgId: string): void {

    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Activation",
        content:
          "Do you want to activate this organisation?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._route.navigate(["/admin/organisations/activate/" + orgId]);
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.getAllOrgs(++event.pageIndex, event.pageSize, this.searchQry);
  }

  openDialog(dimensionID) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray(Array.isArray(dimensionID) ? dimensionID : [dimensionID]);
      }
    });
  }

  openNoItemsSelectedDialog() {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "No Items",
        content:
          "Choose items and delete!!!",
      },
    });
  }

  deleteIdsArray(arrayOfIds: any[]) {
    let postBody = {
      ids: arrayOfIds,
    };
    this._service.deleteOrganisation(postBody).then((res) => {
      if (res["status"] === 200) {
        this.getAllOrgs(1, 10);
      }
    });
  }

  isChecked(id) {
    return this.checkedList.includes(id);
  }

  isAllChecked() {
    return this.checkedList.length === this.organisations.length;
  }

  doSelect(id) {
    if (this.isChecked(id)) {
      this.checkedList = _.without(this.checkedList, id);
    } else {
      this.checkedList.push(id);
    }
  }

  doSelectAll() {
    if (this.checkedList.length === 0) {
      this.checkedList = this.organisations.map((elem, index) => elem["_id"]);
    } else {
      this.checkedList = [];
    }
  }

  onSearch(searchQry: any) {
    this.searchQry = searchQry;
    this.getAllOrgs(1, 10, searchQry);
  }
}
