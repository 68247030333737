<app-module-header
  title="Event Listing"
  [isBackEnabled]="false"
  (onAdd)="openDialog()"
  (onSecondaryButton)="onCalendarView($event)"
  [showBulkMode]="false"
  [showAddButton]="true"
  [showSecondaryButton]="true"
  btnText="Add New Event"

  (onSearch)="onSearch($event)"
></app-module-header>
<app-tabs
  [tabComponents]="tabComponents"
  (tabChange)="tabChange($event)"
></app-tabs>
<app-events-tabs-content
  [totalCount]="totalCount"
  [data]="data"
  (onPageChangeEvent)="onPageChange($event)"
  (onHandlePublish)="onPublish($event)"
  (onHandleDelete)="onDelete($event)"
  (onHandleStop)="onEventStop($event)"
  (onHandleRefresh)="onEventRefresh($event)"
></app-events-tabs-content>
