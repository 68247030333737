import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";

import { AdminNotificationService } from "../services/admin-notification.service";

@Component({
  selector: "app-user-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"]
})
export class AdminNotificationComponent implements OnInit, OnDestroy {

  notifications: Array<Object> = [];
  totalCount: number = 0;

  constructor(
    private _route: Router,
    private _service: AdminNotificationService,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, "activity-body");
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, "activity-body");
  }

  ngOnInit() {
    this.getNotifications(1, 10);
  }

  getNotifications(page, limit) {
    this._service.getNotifications(page, limit).then((res) => {
      this.notifications = res["data"];
      this.totalCount = res["count"];
    });
  }

  onPageChange(event: PageEvent) {
    this.getNotifications(++event.pageIndex, event.pageSize);
  }

  navigateTo(url: string) {
    console.log(url);
    this._route.navigate([url]);
  }
}