import { Component, OnInit } from '@angular/core';
import {innerDrawerMenus} from "../drawer-menus"

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.scss']
})
export class LandingDashboardComponent implements OnInit {

  menuItems: Array<Object> = innerDrawerMenus;
  
  constructor() { }

  ngOnInit() {
    // 
  }

}
