<div class="row">
  <div class="col-xs-12">
    <h2>Image Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Image Name</mat-label>
      <input matInput placeholder="Image Name" [(ngModel)]="widgetProps.name" />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Scroll Text</mat-label>
      <input
        matInput
        placeholder="Scroll Text"
        [(ngModel)]="widgetProps.scrollText"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select
        placeholder="Font Family"
        [(ngModel)]="widgetProps.fontFamily"
      >
        <mat-option value="Arial, sans-serif">Arial</mat-option>
        <mat-option value="Open Sans, sans-serif">Open Sans</mat-option>
        <mat-option value="Calibri">Calibri</mat-option>
        <mat-option value="Verdana, sans-serif">Verdana</mat-option>
        <mat-option value="Tahoma, sans-serif">Tahoma</mat-option>
        <mat-option value="'Trebuchet MS', sans-serif">Trebuchet MS</mat-option>
        <mat-option value="Calibri">Calibri</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Font Size</mat-label>
      <input
        type="number"
        matInput
        placeholder="Font Size"
        [(ngModel)]="widgetProps.fontSize"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-label>Text Style</mat-label>
    <div class="clearfix"></div>
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.bold"
      >Bold</mat-checkbox
    >
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.fontStyle"
      >Italic</mat-checkbox
    >
    <mat-checkbox
      color="primary"
      class="pr10 cb-font"
      [(ngModel)]="widgetProps.underline"
      >Underline</mat-checkbox
    >
  </div>
  <div class="col-xs-6 mt10 mb20" style="height: 50px">
    <mat-label class="widget-mat-label">Text Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.color"
      [style.background]="widgetProps.color"
      class="color-picker"
    />
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Direction" [(ngModel)]="widgetProps.direction">
        <mat-option value="left">Left</mat-option>
        <mat-option value="right">Right</mat-option>
        <mat-option value="up">Up</mat-option>
        <mat-option value="down">Down</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Behavior" [(ngModel)]="widgetProps.behavior">
        <mat-option value="sliding">Sliding</mat-option>
        <mat-option value="scrolling">Scrolling</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Scroll Delay</mat-label>
      <input
        matInput
        placeholder="in Milliseconds"
        [(ngModel)]="widgetProps.scrollDelay"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Scroll Amount</mat-label>
      <input
        matInput
        placeholder="in Milliseconds"
        [(ngModel)]="widgetProps.scrollAmount"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt10 mb20" style="height: 50px">
    <mat-label class="widget-mat-label">Choose Background Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.bgcolor"
      [style.background]="widgetProps.bgcolor"
      class="color-picker"
    />
  </div>
  <div class="clearfix"></div>
  <div class="col-xs-12">
    <mat-slide-toggle color="primary" [(ngModel)]="widgetProps.hasBorder">
      Border
    </mat-slide-toggle>
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.hasBorder">
    <mat-label class="widget-mat-label">Choose Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.borderColor"
      [style.background]="widgetProps.borderColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="widgetProps.hasBorder">
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Border width"
        [(ngModel)]="widgetProps.borderWidth"
      />
      <span matSuffix>px &nbsp;</span>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Position</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Left"
            [(ngModel)]="widgetProps.coords.left"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Top"
            [(ngModel)]="widgetProps.coords.top"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>