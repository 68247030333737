<div class="moduleHeader">
  <div class="row">
    <div class="col-xs-3">
      <button mat-button class="pull-left">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h2 class="mt0 mb0 pull-left">{{ dataState['name'] }}</h2>
      <p class="p-header">
        {{ dataState['dimension'] }} - {{ dataState['width'] }} x
        {{ dataState['height'] }}
      </p>
    </div>
    <div class="col-xs-5">
      <button mat-button (click)="onZoomin()" class="pull-left">
        <img src="./assets/ic-editor-zoomout.png" alt="" width="20" />
      </button>
      <div class="pull-left zoom-level mr15 ml5">{{ zoomLevelLabel }}</div>
      <button mat-button (click)="onZoomOut()" class="pull-left mr40">
        <img src="./assets/ic-editor-zoomin.png" alt="" width="20" />
      </button>
      <button class="btnSecondary mr40 pull-left" (click)="onResetZoom()">
        Reset Zoom
      </button>
      <!-- <button mat-button (click)="onNavBack()" class="pull-left mr30">        <img src="./assets/ic-editor-hand.png" alt="" width="20" />      </button> -->
      <button mat-button (click)="onClickSceneInfo('scene')" class="pull-left">
        <img src="./assets/ic-editor-sceneinfo.png" alt="" width="10" />
      </button>
    </div>
    <div class="col-xs-4 text-right">
      <button mat-flat-button color="primary" (click)="onSaveScene($event)">
        Save Scene
      </button>
    </div>
  </div>
</div>