import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";

import { AdminNotificationComponent } from './pages/notification.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AdminNotificationComponent
  ] 
})
export class AdminNotificationModule {}
