import { Component, OnInit } from "@angular/core";
import * as _ from "underscore";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-admin-org-add-account",
    templateUrl: "./form-organisation.component.html",
    styleUrls: ["./form-organisation.component.scss"]
})

export class AdminOrganisationAddFormComponent implements OnInit {

    tabComponents: Array<Object>;
    currentTab: Object;
    id: any;

    constructor(
        private _route: Router,
        private route: ActivatedRoute
    ) {
        // 
    }

    ngOnInit() {
        this.tabComponents = [
            {
                id: 1,
                label: "Profile Details",
                query: "profile",
            }
        ];

        this.currentTab = this.tabComponents[0];
    }

    tabChange(tab: MatTabChangeEvent) {
        this.currentTab = this.tabComponents[tab.index];
    }
}