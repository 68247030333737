<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="event_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Name</th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.event_name }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Players</th>
      <td mat-cell *matCellDef="let element">
        <h2 style="max-width: 40em;">{{ element.name.join(", ") }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="is_recurring">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Recurring</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.is_recurring">Yes - {{ element.current_trigger }} of {{ element.trigger_count }}</div>
        <div *ngIf="!element.is_recurring">Normal Schedule</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="next_trigger_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Next Trigger On</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.is_recurring">{{ element.next_trigger_on | date: "mediumDate" }} &nbsp;&nbsp;
          {{ element.next_trigger_on | date: "shortTime" }}</div>
        <div *ngIf="!element.is_recurring">-</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="start_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Starts On</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.ends_on; else noDate">
          {{ element.start_on | date: "mediumDate" }}
          &nbsp;&nbsp;
          {{ element.start_on | date: "shortTime" }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="ends_on">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ends On</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.ends_on; else noDate">
          {{ element.ends_on | date: "mediumDate" }}
          &nbsp;&nbsp;
          {{ element.ends_on | date: "shortTime" }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="has_expiry">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Has Expiry</th>
      <td mat-cell *matCellDef="let element">
        
        <div *ngIf="!element.is_recurring">{{ element.has_expiry ? "Yes" : "No" }}</div>
        <div *ngIf="element.is_recurring">Yes</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="allocated_playlists">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Playlists
      </th>
      <td mat-cell *matCellDef="let element">
        <strong>{{ element.playlists.join(', ') }}</strong>
      </td>
    </ng-container>
    <ng-container matColumnDef="playlist_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Playlists Allocated
      </th>
      <td mat-cell *matCellDef="let element">{{ element.playlist_count }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <span *ngIf="!element.is_published">
          <button
            class="btn btn-sm btn-info"
            (click)="handlePublishEvent(element.id)"
          >
            Publish
          </button>
        </span>
        <span *ngIf="element.is_published">
          <button
            class="btn btn-sm btn-danger"
            (click)="handleStopEvent(element.id)"
          >
            Stop
          </button>
        </span>
        <span *ngIf="element.refresh_event" class="pl5">
          <button
            class="btn btn-sm btn-success"
            (click)="handleRefresh(element.id)"
          >
            Refresh
          </button>
        </span>
        <span>
          <button
            mat-button
            class="mat-button-custom"
            (click)="handleDelete(element.id)"
            title="Delete"
          >
            <img src="assets/delete.png" alt="" width="16" />
          </button>
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <ng-template #noDate>
    <span>No date available</span>
  </ng-template>
</div>
<div class="clearfix"></div>
<app-paginate
  [length]="totalCount"
  pageSize="10"
  (onPageChange)="onPageChange($event)"
></app-paginate
>
