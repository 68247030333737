<div class="row">
  <div class="col-xs-12">
    <h2>Image Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Image Name</mat-label>
      <input
        matInput
        placeholder="Image Name"
        [(ngModel)]="imageWidgetProps.name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Source Link" [(ngModel)]="imageWidgetProps.type">
        <mat-option value="internal">Asset Library</mat-option>
        <mat-option value="external">External Link</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10" *ngIf="imageWidgetProps.type === 'external'">
    <mat-form-field appearance="legacy">
      <mat-label>External Link</mat-label>
      <input
        matInput
        placeholder="http://www.example.com/love.jpg"
        [(ngModel)]="imageWidgetProps.src"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10" *ngIf="imageWidgetProps.type === 'internal'">
    <mat-label class="widget-mat-label">Asset Library</mat-label>
    <p class="label">
      If you have already uploaded the file in asset library please pick the
      same.
    </p>
    <button class="widget-button" mat-button (click)="openAssetsModal()">
      Choose from Asset Library
    </button>
  </div>
  <div class="col-xs-12 mt10 mb10">
    <mat-label class="widget-mat-label">BG Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="imageWidgetProps.backgroundColor"
      [style.background]="imageWidgetProps.backgroundColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Alignment" [(ngModel)]="imageWidgetProps.align">
        <mat-option value="center">Center</mat-option>
        <mat-option value="left">Left</mat-option>
        <mat-option value="right">Right</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Fit" [(ngModel)]="imageWidgetProps.fit">
        <mat-option value="contain">Stretch</mat-option>
        <mat-option value="100%">Fill</mat-option>
        <mat-option value="cover">Center</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <mat-slide-toggle color="primary" [(ngModel)]="imageWidgetProps.hasBorder">
      Border
    </mat-slide-toggle>
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="imageWidgetProps.hasBorder">
    <mat-label class="widget-mat-label">Choose Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="imageWidgetProps.borderColor"
      [style.background]="imageWidgetProps.borderColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-4 mt10 mb10" *ngIf="imageWidgetProps.hasBorder">
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Border width"
        [(ngModel)]="imageWidgetProps.borderWidth"
      />
      <span matSuffix>px &nbsp;</span>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Position</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Left"
            [(ngModel)]="imageWidgetProps.coords.left"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Top"
            [(ngModel)]="imageWidgetProps.coords.top"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>