import { Component } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { SessionStorage } from "./services/session-storage";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Digital Signage Console";

  constructor(
    private bnIdle: BnNgIdleService,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorage,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.dialog.closeAll();
        this.sessionStorageService.clearSessionData();
        this._router.navigate(["/"]);
      }
    });
  }
}
