<div class="appInnerDrawer">
  <h2>{{ drawerTitle }}</h2>
  <div class="appInnerDrawerLinks" *ngIf="permissions == null">
    <a
      [routerLink]="menu.path"
      [routerLinkActive]="['active']"
      *ngFor="let menu of menuItems"
    >
      <img [src]="menu.icon" alt="" width="20" /> {{ menu.label }}
    </a>
  </div>
  <div class="appInnerDrawerLinks" *ngIf="permissions != null">
    <div *ngFor="let menu of menuItems" class="d-block">
      <a
        *ngIf="menu.id != null && permissions.includes(menu.id)"
        [routerLink]="menu.path"
        [routerLinkActive]="['active']"
      >
        <img [src]="menu.icon" alt="" width="20" /> {{ menu.label }}
      </a>
      <a
        *ngIf="menu.id == null"
        [routerLink]="menu.path"
        [routerLinkActive]="['active']"
      >
        <img [src]="menu.icon" alt="" width="20" /> {{ menu.label }}
      </a>
    </div>
  </div>
</div>