import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { API_URL } from "../../../config"

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    constructor(private http: HttpClient) { }

    getUser(email, password) {
        return this.http.post(API_URL + "/login", {
            email, password
        });
    }
}