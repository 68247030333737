import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "underscore";
import { PageEvent } from "@angular/material/paginator";

import { PlayListService } from "../services/playlist.service";
import { LocationsService } from "../../../../manage/pages/location/services/locations.service";
import { DimensionService } from "../../../../manage/pages/dimensions/services/dimensions.service";

@Component({
  selector: "app-scene-modal",
  templateUrl: "./scene-modal.component.html",
  styleUrls: ["./scene-modal.component.scss"],
})
export class SceneModalComponent implements OnInit {
  sceneItems: Array<Object> = [];
  selectedScenes: Array<Number> = [];

  totalCount: number;
  checkedList: Array<Object> = [];
  searchQry: string = "";
  sort: string = "dateDesc";
  dimensions: Array<Object>;
  dimension: string = "all";
  pageIndex: number = 1;
  pageSize: number = 10;
  isLoading: Boolean = false;

  ngOnInit() {

    this.isLoading = true;

    //Load all dimensions from DB
    this._dservice.getDimensions(0, 1000000).then((res) => {
      this.dimensions = res["data"] || [];
    });

    // Load scenes from DB
    this.getAllScenesBySearch();
  }

  constructor(
    private _service: PlayListService,
    private _dservice: DimensionService
  ) {}

  selectScene(scene): void {
    if (this.hasArrayValue(this.selectedScenes, scene)) {
      this.selectedScenes = this.removeArrayValue(this.selectedScenes, scene);
    } else {
      this.selectedScenes.push(scene);
    }
  }

  hasArrayValue(array, value) {
    return array.includes(value);
  }

  removeArrayValue(array, value) {
    return array.filter(function (ele) {
      return ele != value;
    });
  }

  onChangeDimension(event: any) {
    this.dimension = event;
    this.pageIndex = 1;
    this.pageSize = 10;

    this.getAllScenesBySearch();
  }

  onChangeSort(event: any) {
    this.sort = event;
    this.pageIndex = 1;
    this.pageSize = 10;

    this.getAllScenesBySearch();
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = ++event.pageIndex;
    this.pageSize = event.pageSize;

    this.getAllScenesBySearch();
  }

  onSearch() {
    this.getAllScenesBySearch();
  }

  getAllScenesBySearch() {
    let postBody = {
      page: this.pageIndex,
      limit: this.pageSize,
      searchQry: this.searchQry,
      sort: this.sort,
      dimension: this.dimension,
    };

    this.isLoading = true;

    this._service.getScenesBySearch(postBody).then((res) => {
      this.sceneItems = res["data"];
      this.totalCount = res["count"];
      this.isLoading = false;
    });
  }
}
