import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class SchedulerService {

    constructor(private commonHttpService: CommonHttpService) { }

    // Tab Components Request
    getTabDataByItem(itemType: string, page: string | number | boolean, limit: string | number | boolean, searchQry: string | number | boolean, fromPage = 'calendar') {
        return this.commonHttpService.get('/scheduler/events/' + encodeURI(itemType) + '?searchQry=' + encodeURIComponent(searchQry) + '&page=' + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit) + '&from_page=' + encodeURIComponent(fromPage)).then(res => res);
    }

    // Create schedule
    setSchedule(postBody: { player: any; playlist: any[]; has_expiry: boolean; repeat: boolean; has_touch: any; is_dummy_playlist_screen: any; weekdays: any[]; start_on: Date; ends_on: Date; allocation_from: string; playlist_sort: string[]; }) {
        return this.commonHttpService.post('/scheduler/events', postBody).then(res => res);
    }

    setPlayerGroupSchedule(postBody: { playerGroup: any; playlist: any[]; has_expiry: boolean; repeat: boolean; has_touch: any; is_dummy_playlist_screen: any; weekdays: any[]; start_on: Date; ends_on: Date; allocation_from: string; playlist_sort: string[]; }) {
        return this.commonHttpService.post('/scheduler/events/playergroup', postBody).then(res => res);
    }

    // Delete Schedule
    deleteEvent(postBody: any) {
        return this.commonHttpService.delete('/scheduler/events', postBody).then(res => res);
    }

    // Delete Playlist from Schedule
    deleteEventPlaylist(postBody: any) {
        return this.commonHttpService.delete('/scheduler/events/playlists', postBody).then(res => res);
    }

    // Get Schedules
    getEventSchedules(postBody: any) {
        return this.commonHttpService.post('/scheduler/events/get_all_events', postBody).then(res => res);
    }

    // Get all Players
    getPlayers(page: any | number, limit: string | number | boolean) {
        return this.commonHttpService.get('/players?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit)).then(res => res);
    }

    // Get all Players
    getPlayerGroups(page: any | number, limit: string | number | boolean) {
        return this.commonHttpService.get('/playergroups?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=').then(res => res);
    }

    // Get all Playlists
    getPlaylists(page: any | number, limit: string | number | boolean) {
        return this.commonHttpService.get('/playlists?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=').then(res => res);
    }

    setPublishEvent(postBody: Object, id: string) {
        return this.commonHttpService.put('/scheduler/events/status/' + encodeURI(id) , postBody).then(res => res);
    }

    setRefreshStatus(postBody: Object, id: string) {
        return this.commonHttpService.put('/scheduler/events/refresh/' + encodeURI(id) , postBody).then(res => res);
    }
}