import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { BnNgIdleService } from 'bn-ng-idle';

// Guards
import { AuthGuard } from "./guards/Auth.guard";

import { LoginComponent } from "./modules/login/login.component";
import { ForgotPasswordComponent } from "./modules/forgot-password/forgot-password.component";
import { CreateComponent } from "./modules/create/create.component";

import { SharedModule } from "./shared/module/shared.module";
import { ManageModule } from "./modules/user/manage/manage.module";
import { DashboardModule } from "./modules/user/dashboard/dashboard.module";
import { UserActivityModule } from "./modules/user/activity/activity.module";
import { UserAccountModule } from "./modules/user/account/account.module";
import { AdminActivityModule } from "./modules/admin/activity/admin-activity.module";

// Custom pipes
import { UserNotificationModule } from './modules/user/notifications/notification.module';
import { AdminNotificationModule } from './modules/admin/notifications/admin-notification.module';
import { AdminAccountModule } from './modules/admin/account/admin-account.module';
import { AdminOrganisationModule } from './modules/admin/organisations/admin-organisation.module';
import { AdminSlabsModule } from './modules/admin/slabs/admin-slabs.module';
import { AccountVerifyComponent } from "./modules/verify/account.verify.component";
import { TwoFactorAuthComponent } from "./modules/two-factor/two-factor.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    CreateComponent,
    AccountVerifyComponent,
    TwoFactorAuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ManageModule,
    DashboardModule,
    HttpClientModule,
    ReactiveFormsModule,
    UserActivityModule,
    UserAccountModule,
    UserNotificationModule,
    AdminActivityModule,
    AdminNotificationModule,
    AdminAccountModule,
    AdminOrganisationModule,
    AdminSlabsModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    NgxSpinnerService,
    BnNgIdleService
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
