<div class="row zone-header pt10 pb20 mt0">
  <div class="col-sm-10">
    <h2 class="mt0">{{ headerContent['name'] }}</h2>
    <small
      >{{ headerContent['dimension'] }} /
      {{
        headerContent['orientation'] +
          " - " +
          headerContent['width'] +
          " x " +
          headerContent['height'] +
          " pixels"
      }}
      Version</small
    >
  </div>
  <div class="col-sm-2">
    <div class="row">
      <div class="col-sm-6 pt5 pull-right" *ngIf="showSaveBtn">
        <button mat-flat-button color="primary" (click)="onSaveHandler()">
          Save
        </button>
      </div>
      <div class="col-sm-6 pt5 pull-right" *ngIf="showCancelBtn">
        <button mat-flat-button (click)="onCancelHandler()">Cancel</button>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="row">
  <div class="col-xs-12">
    <hr class="medium-gray mt0 mb0" />
  </div>
</div>