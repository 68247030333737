import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { map, catchError, } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { HttpClient } from '@angular/common/http';

import { AdminOrganisationService } from "../services/organisation.service";
import { AdminSlabService } from "../../slabs/services/slabs.service";

@Component({
    selector: "app-org-activate",
    templateUrl: "./activate.component.html",
    styleUrls: ["./activate.component.scss"],
})

export class AdminOrgActivateComponent implements OnInit {
    bulkMenuItems: Array<string>;
    isLoading: boolean;
    data: Object;
    id: any;
    slabs: Array<Object>;
    alertMessage: Object = {
        message: null,
        error: false,
    };
    selectedSlab: Object;

    myForm = new UntypedFormGroup({
        organisation_id: new UntypedFormControl("", [Validators.required, Validators.minLength(5)], this.orgIdValidator()),
        name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
        contact_person: new UntypedFormControl("", [Validators.required]),
        primary_contact: new UntypedFormControl("", [Validators.required]),
        secondary_contact: new UntypedFormControl("", [Validators.required]),
        subscription: new UntypedFormControl("", [Validators.required]),
        licence_count: new UntypedFormControl("", [Validators.required]),

        username: new UntypedFormControl("", [Validators.required]),
        password: new UntypedFormControl("", [Validators.required]),

        is_ftp: new UntypedFormControl(""),
        ftp_host: new UntypedFormControl(""),
        ftp_username: new UntypedFormControl(""),
        ftp_password: new UntypedFormControl(""),
        ftp_port: new UntypedFormControl("")
    });

    constructor(
        public dialog: MatDialog,
        private _service: AdminOrganisationService,
        private _route: Router,
        private route: ActivatedRoute,
        private _slabService: AdminSlabService,
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.bulkMenuItems = ["Delete"];
        this.id = this.route.snapshot.params.id;

        this.getUserAccountDetails();
        this.getAllSlabs();

        this.myForm.get("is_ftp").valueChanges.subscribe(value => {

            if(value) {
                this.myForm.get("ftp_host").setValidators([Validators.required]);
                this.myForm.get("ftp_username").setValidators([Validators.required]);
                this.myForm.get("ftp_password").setValidators([Validators.required]);
                this.myForm.get("ftp_port").setValidators([Validators.required]);
                this.myForm.get("ftp_port").setValue('21');
            } else {
                this.myForm.get("ftp_host").setValidators(null);
                this.myForm.get("ftp_username").setValidators(null);
                this.myForm.get("ftp_password").setValidators(null);
                this.myForm.get("ftp_port").setValidators(null);
                this.myForm.get("ftp_port").setValue('');
            }

            this.myForm.get("ftp_host").updateValueAndValidity();
            this.myForm.get("ftp_username").updateValueAndValidity();
            this.myForm.get("ftp_password").updateValueAndValidity();
            this.myForm.get("ftp_port").updateValueAndValidity();
        });
    }

    orgIdValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            return from(this._service.validateOrganisationID(control.value))
                .pipe(
                    map(res => {
                        if (res['status'] === 200) {
                            return { 'alreadyExists': true };
                        }
                    })
                );
        };

    }

    getUserAccountDetails(): void {
        this.isLoading = true;
        var usernameGenerated = '';

        this._service.getOrganisation(this.id).then(res => {
            this.myForm.get("name").setValue(res['data']['name']);
            this.myForm.get("contact_person").setValue(res['data']['contact_person']);
            this.myForm.get("primary_contact").setValue(res['data']['primary_contact']);
            this.myForm.get("secondary_contact").setValue(res['data']['secondary_contact']);

            usernameGenerated = res['data']['name'].replace(/\s/g, "");
            usernameGenerated = usernameGenerated.toLowerCase();

            this.myForm.get("username").setValue(usernameGenerated);
            this.myForm.get("password").setValue('password');

            this.data = res['data'];
            this.isLoading = false;
        });
    }

    getAllSlabs(): void {
        this._slabService.getSlabs(0, 100000).then(res => {
            this.slabs = res['data'];
        });
    }

    onSlabSelect(item: any) {
        this.selectedSlab = item;
        this.myForm.get("licence_count").setValue(item['license_count']);
    }

    save(): void {

        let postBody = {
            organisation_id: this.myForm.value.organisation_id,
            name: this.myForm.value.name,
            email: this.data['email'],
            username: this.myForm.value.username,
            password: this.myForm.value.password,
            subscription_plan: this.selectedSlab['name'],
            subscription_id: this.selectedSlab['_id'],
            contact_person: this.myForm.value.contact_person,
            primary_contact: this.myForm.value.primary_contact,
            secondary_contact: this.myForm.value.secondary_contact,
            verification: '',
            licence_count: this.selectedSlab['license_count'],

            is_ftp: this.myForm.value.is_ftp != "" ? this.myForm.value.is_ftp : false,
            ftp_host: this.myForm.value.ftp_host,
            ftp_username: this.myForm.value.ftp_username,
            ftp_password: this.myForm.value.ftp_password,
            ftp_port: this.myForm.value.ftp_port != "" ? this.myForm.value.ftp_port : 0,
            action: '_activate',
            status: 'A'
        };

        this._service.activateOrganisation(postBody, this.id).then(res => {
            if (res["status"] === 200) {
                alert('Organisation Activated!');
                this._route.navigate(["/admin/organisations"]);
            } else {
                alert(res["message"]);
            }
        });
    }
}
