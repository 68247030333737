import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";
import { LightboxModule } from 'ngx-lightbox';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';

import { SchedulerComponent } from "./pages/scheduler/scheduler.component";
import { SchedulerPlayerComponent } from "./pages/scheduler/players/players-scheduler.component";
import { SchedulerGroupsComponent } from "./pages/scheduler/groups/group-scheduler.component";
import { TabPlayersComponent } from "./pages/scheduler/tab-players/tab-players.component";
import { TabGroupsComponent } from "./pages/scheduler/tab-groups/tab-groups.component";
import { SchedulerModal } from "./pages/scheduler/modal/scheduler-modal.component";
import { SchedulerPlayerGroupModal } from "./pages/scheduler/modal-player-groups/scheduler-modal.component";
import { DimensionListComponent } from "./pages/dimensions/list/list.component";
import { DimensionFormComponent } from "./pages/dimensions/form/form.component";
import { LocationListComponent } from "./pages/location/list/list.component";
import { LocationFormComponent } from "./pages/location/form/form.component";
import { AssetListComponent } from "./pages/assets/list/list.component";
import { TabsContentComponent } from "./pages/assets/tab-content/tabs-content.component";
import { UploadAssetComponent } from "./pages/assets/upload/upload-asset.component";
import { UploadAssetContainerComponent } from "./pages/assets/upload/upload-container/upload-container.component";
import { AssetViewComponent } from "./pages/assets/view/asset-view.component";
import { FileViewerComponent } from "./pages/assets/file-viewer/file-viewer.component";
import { LandingManageComponent } from "./landing-manage/landing-manage.component";
import { EventListComponent } from "./pages/scheduler/index/list.component";
import { EventTabsContentComponent } from "./pages/scheduler/index/tab-content/tabs-content.component";
import { RoleFormComponent } from "./pages/roles/form/form.component";
import { RolesListComponent } from "./pages/roles/list/list.component";
import { OrgUsersListComponent } from "./pages/users/list/list.component";
import { OrgUserFormComponent } from "./pages/users/form/form.component";

@NgModule({
  imports: [
    SharedModule, 
    FullCalendarModule, 
    MatAutocompleteModule,
    DateTimePickerModule,
    LightboxModule,
    TimePickerModule,
    DatePickerModule
  ],
  declarations: [
    DimensionListComponent,
    DimensionFormComponent,
    LocationListComponent,
    LocationFormComponent,
    LandingManageComponent,
    AssetListComponent,
    TabsContentComponent,
    UploadAssetComponent,
    UploadAssetContainerComponent,
    AssetViewComponent,
    FileViewerComponent,
    SchedulerPlayerGroupModal,
    SchedulerComponent,
    SchedulerPlayerComponent,
    TabPlayersComponent,
    SchedulerGroupsComponent,
    TabGroupsComponent,
    SchedulerModal,
    EventListComponent,
    EventTabsContentComponent,
    RoleFormComponent,
    RolesListComponent,
    OrgUsersListComponent,
    OrgUserFormComponent
  ],
  entryComponents: [
    SchedulerModal,
    SchedulerPlayerGroupModal
  ]
})
export class ManageModule {}
