import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-paginate",
  templateUrl: "./paginate.component.html",
  styleUrls: ["./paginate.component.scss"]
})
export class PaginateComponent implements OnInit {
  @Input() length: Number;
  @Input() pageSize: Number;

  pageSizeOptions: Array<Number> = [5, 10, 25, 100];

  @Output() onPageChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    // 
  }

  onChange(event: PageEvent) { 
    this.onPageChange.emit(event);
  }
}
