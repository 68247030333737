import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-user-activity-item",
  templateUrl: "./activity-item.component.html",
  styleUrls: ['../activity.component.scss']
})

export class AdminActivityItemComponent implements OnInit {
    @Input() item: Array<Object> = [];

    ngOnInit(): void {
        // 
        console.log('itemArray' ,this.item);
    }

    constructor() {
        //
    }
}