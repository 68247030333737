<div class="moduleHeader">
  <button mat-button *ngIf="isBackEnabled" (click)="onNavBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2>{{ title }}</h2>
  <div *ngIf="showBulkMode">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      Bulk Action <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        (click)="onMenuItemClicked(i)"
        mat-menu-item
        *ngFor="let menu of menuItems; let i = index"
      >
        <span>{{ menu }}</span>
      </button>
    </mat-menu>
  </div>
  <div class="moduleHeaderRightItems" *ngIf="showAddButton">
    <div class="searchBar">
      <form (ngSubmit)="onSearchSubmit()">
        <mat-form-field class="example-full-width">
          <input
            type="text"
            matInput
            placeholder="Search"
            [(ngModel)]="searchText"
            name="searchText"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </form>
    </div>
    <button class="btnSecondary" (click)="onAddMenuClicked()">
      {{ btnText }}
    </button>
    <button
      *ngIf="showSecondaryButton"
      class="btnClear"
      (click)="onSecondaryButtonClick()"
    >
      Calendar View
    </button>
  </div>
</div>
