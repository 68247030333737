import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";

import { AdminSlabService } from "../services/slabs.service";

@Component({
    selector: "app-admin-slabs-form",
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.scss"],
})
export class AdminSlabFormComponent implements OnInit {
    crumbItems: Array<Object>;
    id: any;
    saveBtnText: string = "Submit";
    pageTitle: string = "Add New - Slab";
    permissionError: boolean = false;

    permissionsAllowed: Array<Object> = [
        { id: 1, name: 'Basic Playlist', isSelected: false },
        { id: 2, name: 'Scheduling', isSelected: false },
        { id: 3, name: 'Zone Editor', isSelected: false },
        { id: 4, name: 'Scene Designer', isSelected: false }
    ];

    myForm = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
        permissions: new UntypedFormControl(""),
        cloud_traffic: new UntypedFormControl("", [Validators.required]),
        cloud_storage: new UntypedFormControl("", [Validators.required]),
        cloud_monthly_download: new UntypedFormControl("", [Validators.required]),
        license_count: new UntypedFormControl("", [Validators.required]),
        is_autosync_enabled: new UntypedFormControl(""),
        is_device_status_enabled: new UntypedFormControl(""),
        is_analytical_tool_enabled: new UntypedFormControl(""),
        is_auto_update_enabled: new UntypedFormControl(""),
        users_count: new UntypedFormControl("", [Validators.required]),
        status: new UntypedFormControl(""),
    });

    constructor(
        public dialog: MatDialog,
        private _service: AdminSlabService,
        private _route: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.crumbItems = [
            {
                label: "Dashboard",
                path: null,
                current: false,
            },
            {
                label: "Slabs",
                path: "/admin/slabs",
                current: true,
            },
        ];

        this.id = this.route.snapshot.params.id;
        this.saveBtnText = this.id ? "Update" : "Save";
        this.pageTitle = this.id && "Update - Slab Settings";

        if (this.id) {
            this._service.getSlab(this.id).then((res) => {
                this.myForm.get("name").setValue(res["data"]["name"]);
                this.myForm.get("cloud_traffic").setValue(res["data"]["cloud_traffic"]);
                this.myForm.get("cloud_storage").setValue(res["data"]["cloud_storage"]);
                this.myForm.get("cloud_monthly_download").setValue(res["data"]["cloud_monthly_download"]);
                this.myForm.get("license_count").setValue(res["data"]["license_count"]);
                this.myForm.get("is_autosync_enabled").setValue(res["data"]["is_autosync_enabled"]);
                this.myForm.get("is_device_status_enabled").setValue(res["data"]["is_device_status_enabled"]);
                this.myForm.get("is_analytical_tool_enabled").setValue(res["data"]["is_analytical_tool_enabled"]);
                this.myForm.get("is_auto_update_enabled").setValue(res["data"]["is_auto_update_enabled"]);
                this.myForm.get("users_count").setValue(res["data"]["users_count"]);
                this.myForm.get("status").setValue(res["data"]["status"]);

                this.permissionsAllowed.map((elem, index) => {
                    if(_.contains(res["data"]["permissions"], elem['id'])) {
                        this.permissionsAllowed[index]['isSelected'] = true;
                    }
                });

            });
        }
    }

    isChecked(id: number) {
        return this.permissionsAllowed[id]['isSelected'];
    }

    togglePermissions(id: number) {
        this.permissionsAllowed[id]['isSelected'] = !this.permissionsAllowed[id]['isSelected'];
    }

    save(): void {
        let postBody: Object = {};
        postBody = {
            name: this.myForm.value.name,
            permissions: [],
            cloud_traffic: this.myForm.value.cloud_traffic,
            cloud_storage: this.myForm.value.cloud_storage,
            cloud_monthly_download: this.myForm.value.cloud_monthly_download,
            license_count: this.myForm.value.license_count,
            is_autosync_enabled: this.myForm.value.is_autosync_enabled != '' ? this.myForm.value.is_autosync_enabled : false,
            is_device_status_enabled: this.myForm.value.is_device_status_enabled != '' ? this.myForm.value.is_device_status_enabled : false,
            is_analytical_tool_enabled: this.myForm.value.is_analytical_tool_enabled != '' ? this.myForm.value.is_analytical_tool_enabled : false,
            is_auto_update_enabled: this.myForm.value.is_auto_update_enabled != '' ? this.myForm.value.is_auto_update_enabled : false,
            users_count: this.myForm.value.users_count,
            status: this.myForm.value.status,
        };

        this.permissionsAllowed.map((elem, index) => {
            if (elem['isSelected']) {
                postBody['permissions'].push(elem['id']);
            }
        });

        if(!_.some(this.permissionsAllowed, { isSelected: true })) {
            this.permissionError = true;
        } else {
            this.permissionError = false;
            this.setSlab(postBody);
        }
    }

    setSlab(postBody) {
        if (this.id) {
            this._service.updateSlab(postBody, this.id).then((res) => {
                if (res["status"] === 200) {
                    this._route.navigate(["/admin/slabs"]);
                } else {
                    alert(res["message"]);
                }
            });
        } else {
            this._service.setSlab(postBody).then((res) => {
                if (res["status"] === 200) {
                    this._route.navigate(["/admin/slabs"]);
                } else {
                    alert(res["message"]);
                }
            });
        }
    }
}
