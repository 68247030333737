import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SceneAddItemModal } from "./modal/modal.component";
import { MatDialog } from "@angular/material/dialog";
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';

import { WidgetAlertComponent } from "./widget-alert/widget-alert.component";
import { SlideshowPreviewModal } from "./widget/preview/preview.modal.component";
import { ScenesService } from "../services/scenes.services";
import html2canvas from "html2canvas";

@Component({
  selector: "app-scene-editor",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})

export class SceneEditorComponent implements OnInit {
  crumbItems: Array<Object>;
  assetsArray: Array<any> = [];
  canvas: Object;
  sceneWidget: Object = {
    widget: '',
    index: null,
    props: {}
  };
  widgetArray: Array<Object> = [];
  activeWidget: Object = {
    widget: '',
    index: null
  };
  id: any;
  sceneData: Object = null;
  isLoading: Boolean = true;
  dataState: Object = null;
  sceneInfo: Object = null;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private _service: ScenesService,
    private spinner: NgxSpinnerService
  ) {

    this.id = this.route.snapshot.params.id;

    if (!history.state.name && !this.id) {
      this._router.navigate(['/manage/scene-editor']);
    } else {
      this.dataState = history.state;
    }
  }

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Home",
        path: null,
        current: false,
      },
      {
        label: "Scenes",
        path: "/manage/scene-editor",
        current: true,
      },
    ];

    if (history.state.name) {
      this.canvas = JSON.parse(history.state.layout.content_json);
    }

    if (this.id) {
      this.spinner.show();
      this._service.getScene(this.id).then((res) => {
        this.sceneData = res['data'];
        this.canvas = JSON.parse(this.sceneData['canvas']);

        this.dataState = {
          dimension: res['data']['dimension'],
          name: res['data']['name'],
          width: res['data']['width'],
          height: res['data']['height']
        };

        this.sceneWidget = {
          widget: "scene",
          index: 2000,
          props: {
            width: this.canvas['canvasWidth'] * 4,
            height: this.canvas['canvasHeight'] * 4,
            title: res['data']['name'],
            description: "Your descripton goes here!!"
          }
        };
        this.widgetArray = this.sceneData['widgets'];
        this.assetsArray = this.sceneData['asset_array'];
        this.isLoading = false;

        this.ref.detectChanges();
        this.spinner.hide();
      });
    } else {

      this.sceneWidget = {
        widget: "scene",
        index: 2000,
        props: {
          width: this.canvas['canvasWidth'] * 4,
          height: this.canvas['canvasHeight'] * 4,
          title: "New Scene",
          description: "Your descripton goes here!!"
        }
      };

      this.isLoading = false;
    }

    this.sceneInfo = {
      backgroundColor: '#333'
    }
  }

  getWidget(index: any) {
    if (this.widgetArray.length > 0) {
      return (this.widgetArray && this.widgetArray[index] && typeof this.widgetArray[index] != 'undefined') ? this.widgetArray[index]['widget'] : '';
    }

    return "";
  }

  handleClick(index: any) {
    let elem = document.getElementById('object-' + index);
    let containedWidget = elem.getAttribute('data-widget');

    if (containedWidget !== '') {
      // Show property window based on meta widget
      let actualArrayIndex = _.indexOf(_.pluck(this.widgetArray, 'index'), index);
      this.activeWidget = this.widgetArray[actualArrayIndex];
    } else {
      // Show overlay modal
      const dialogRef = this.dialog.open(SceneAddItemModal, {
        width: "400px"
      });

      dialogRef.afterClosed().subscribe(widget => {
        if (widget) {
          elem.setAttribute('data-widget', widget);

          if (widget === 'clock') {

            let props = {
              name: "Clock " + index,
              hrFormat: 'H',
              timeZone: 'Asia/Calcutta',
              formatText: '',
              size: {
                width: 200,
                height: 200,
              },
              coords: {
                left: 0,
                top: 0,
              }
            }

            if (this.widgetArray.length > 0) {
              this.widgetArray = _.compact(this.widgetArray);
            }
            this.widgetArray.push({
              widget, index, props
            });

          } else {
            if (this.id) {
              this.widgetArray[index] = {
                widget, index
              };
            } else {
              if (this.widgetArray[index] == null) {
                this.widgetArray[index] = {
                  widget, index
                };
              } else {
                this.widgetArray.push({
                  widget, index
                });
              }

            }
          }
          this.activeWidget = this.widgetArray[index];
          this.ref.detectChanges();
        }
      });
    }

  }

  getWidgetImage(widget: string) {
    let widgetImage = null;

    switch (widget) {
      case 'image':
        widgetImage = '/assets/ic-editor-image.png';
        break;
      case 'slideshow':
        widgetImage = '/assets/ic-editor-image.png';
        break;
    }

    return widgetImage;
  }

  getUnescapedHtml(html: string) {
    return html;
  }

  removeWidget(event, widget: string, index: any) {
    event.stopPropagation();
    if (this.hasWidgetInWidgetArray({ widget, index })) {
      this.widgetArray[index] = null;
      let elem = document.getElementById('object-' + index);
      elem.setAttribute('data-widget', '');
    }
    return false;
  }

  hasWidgetInWidgetArray(widgetObj) {
    return _.findWhere(this.widgetArray, widgetObj);
  }

  sceneInfoEvent(event) {
    if (event === 'scene') {
      this.activeWidget = this.sceneWidget;
    } else {
      this.activeWidget = {};
    }
  }

  onSaveProps(eventData: {props: Object, showAlert: boolean}) {
    this.activeWidget['props'] = eventData.props;
    this.ref.detectChanges();
    this.ref.markForCheck();

    if (eventData.showAlert) {
      this.dialog.open(WidgetAlertComponent, {
        width: "400px",
        data: {
          title: 'Widget Updated',
          content: 'The properties for the selected widget has been updated'
        }
      });
    }
  }

  onSaveSceneProps(event: any) {
    this.sceneInfo = event;
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.dialog.open(WidgetAlertComponent, {
      width: "400px",
      data: {
        title: 'Scene Updated',
        content: 'The properties for the selected widget has been updated'
      }
    });
  }

  onAddAsset(eventData: any) {
    if (Array.isArray(eventData)) {
      this.assetsArray = _.uniq(eventData);
    } else {
      let hasAssetId = _.contains(this.assetsArray, eventData);
      if (!hasAssetId) {
        this.assetsArray.push(eventData);
      }
    }
  }

  onRemoveAsset(index: any) {
    this.assetsArray.splice(index, 1);
  }

  onSaveScene() {

    var element = document.getElementById('canvasWrapperScene');
    var self = this;
    html2canvas(element).then(function (canvas) {

      let videoContainers = document.getElementsByClassName('videoContainers');
      let videoDurations = [];
      if (videoContainers.length > 0) {
        if (self.widgetArray.length) {
          let videoAsset = self.widgetArray.filter((elem) => elem['widget'] === 'video');
          if (videoAsset.length) {
            videoAsset.forEach((elem) => {
              videoDurations.push(parseInt(elem['props']['duration']));
            });
          }
        }
      }

      let slideshowWidget = null;
      if (self.widgetArray.length) {
        slideshowWidget = self.widgetArray.find((elem) => elem['widget'] === 'slideshow');
      }

      if (slideshowWidget !== null && typeof slideshowWidget !== 'undefined') {
        videoDurations.push(slideshowWidget['props']['images'].length * slideshowWidget['props']['transition_delay']);
      }

      let sceneDuration = videoDurations.length > 0 ? _.max(videoDurations) : 10;
      let postBody = {};
      let assetObjArray = [];
      let _widgetArray = [];

      if (self.widgetArray.length) {
        self.widgetArray.map((elem, index) => {
          if (elem['props']['text'] && elem['props']['text'] != '') {
            elem['props']['text'] = elem['props']['text'];
          }

          if (elem['props']['scrollText'] && elem['props']['scrollText'] != '') {
            elem['props']['scrollText'] = elem['props']['scrollText'];
          }

          _widgetArray.push(elem);
          assetObjArray.push(elem)
        });
      }

      if (self.id) {
        postBody = {
          widgets: _widgetArray,
          name: self.sceneWidget['props']['title'],
          width: self.sceneData['width'],
          height: self.sceneData['height'],
          dimension: self.sceneData['dimension'],
          dimensionId: self.sceneData['dimension_id'],
          description: '',
          canvasWidth: self.canvas['canvasWidth'] * 4,
          canvasHeight: self.canvas['canvasHeight'] * 4,
          canvas: JSON.stringify(self.canvas),
          assetIdArray: _.uniq(self.assetsArray),
          isPortrait: self.sceneData['is_portrait'],
          thumbnail: canvas.toDataURL(),
          duration: sceneDuration,
          sceneBg: self.sceneInfo['backgroundColor']
        };

        self.spinner.show();
        self._service.updateScene(postBody, self.id).then((res) => {
          if (res["status"] === 200) {
            let dialogRef = self.dialog.open(WidgetAlertComponent, {
              width: "400px",
              data: {
                title: 'Scene Saved',
                content: 'The current scene has been saved successfully'
              }
            });

            dialogRef.afterClosed().subscribe(res => {
              self._router.navigate(["/manage/scene-editor"]);
            });
          } else {
            alert(res["message"]);
          }
          self.spinner.hide();
        });

      } else {
        postBody = {
          widgets: self.widgetArray,
          name: history.state.name,
          width: history.state.width,
          height: history.state.height,
          dimension: history.state.dimension,
          dimensionId: history.state.layout._id,
          description: '',
          canvasWidth: self.canvas['canvasWidth'] * 4,
          canvasHeight: self.canvas['canvasHeight'] * 4,
          canvas: JSON.stringify(self.canvas),
          assetIdArray: self.assetsArray,
          isPortrait: history.state.orientation === "Portrait",
          thumbnail: canvas.toDataURL(),
          duration: sceneDuration,
          sceneBg: self.sceneInfo['backgroundColor']
        };

        self.spinner.show();
        // API to save the scene in backend.
        self._service.setScene(postBody).then((res) => {
          if (res["status"] === 200) {

            let dialogRef = self.dialog.open(WidgetAlertComponent, {
              width: "400px",
              data: {
                title: 'Scene Saved',
                content: 'The current scene has been saved successfully'
              }
            });

            dialogRef.afterClosed().subscribe(res => {
              self._router.navigate(["/manage/scene-editor"]);
            });

          } else {
            alert(res["message"]);
          }
          self.spinner.hide();
        });
      }
    });
  }

  previewSlideShow(props) {
    this.dialog.open(SlideshowPreviewModal, {
      width: "900px",
      data: {
        props: props,
        images: props.images
      }
    });
  }

}
