<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="!isLoading">
  <app-alert
    *ngIf="alertMessage['message']"
    [message]="alertMessage['message']"
    [error]="alertMessage['error']"
  ></app-alert>
  <form autocomplete="on" [formGroup]="myForm" (ngSubmit)="save()">
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            name="name"
            formControlName="name"
          />
          <mat-error *ngIf="myForm.get('name').hasError('required')"
            >Field is required</mat-error
          >
          <mat-error *ngIf="myForm.get('name').hasError('minlength')"
            >Min length should be 5</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-label>Email Address</mat-label>
        <p>{{ data['email'] }}</p>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-12">
        <h3>Contact Details</h3>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            name="contact_person"
            formControlName="contact_person"
          />
          <mat-error *ngIf="myForm.get('contact_person').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Primary Contact"
            name="primary_contact"
            formControlName="primary_contact"
          />
          <mat-error *ngIf="myForm.get('primary_contact').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Secondary Contact"
            name="secondary_contact"
            formControlName="secondary_contact"
          />
          <mat-error
            *ngIf="myForm.get('secondary_contact').hasError('required')"
            >Field is required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row mb10 mt10">
      <div class="col-xs-6">
        <button mat-flat-button color="primary" [disabled]="!myForm.valid">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>