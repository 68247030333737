import { Component, OnInit, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'app-zone-editor-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class ZoneEditorToolbarComponent implements OnInit {

    @Output() onAdd = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onZoomChange = new EventEmitter();

    sliderVal:number = 1;

    ngOnInit() {
        // 
    }

    onAddItem() {
        this.onAdd.emit();
    }

    onDeleteItem() {
        this.onDelete.emit();
    }

    onZoomInClick() {
        if(this.sliderVal > 0) { 
            this.sliderVal = this.sliderVal - 1;
            this.onZoomChange.emit(this.sliderVal);
        }
    }

    onZoomOutClick() {
        if(this.sliderVal < 5) {
            this.sliderVal = this.sliderVal + 1; 
            this.onZoomChange.emit(this.sliderVal);
        }
    }

    onSliderChange(event: any) {
        this.sliderVal = event.value;
        this.onZoomChange.emit(event.value);
    }
}