import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
  providedIn: "root",
})
export class AssetsService {
  constructor(private commonHttpService: CommonHttpService) { }

  getAssets(page, limit, type, search) {
    return this.commonHttpService
      .get(
        "/assets?page=" +
        encodeURI(page) +
        "&limit=" +
        encodeURIComponent(limit) +
        "&type=" +
        encodeURIComponent(type) +
        "&query=" +
        encodeURIComponent(search)
      )
      .then((res) => res);
  }

  getAllAssets(page, limit, dimension, sort, type) {
    return this.commonHttpService
      .get(
        "/assets/partial?page=" +
        encodeURI(page) +
        "&limit=" +
        encodeURIComponent(limit) +
        "&dimension=" +
        encodeURIComponent(dimension) +
        "&type=" +
        encodeURIComponent(type) +
        "&sort=" +
        encodeURIComponent(sort)
      )
      .then((res) => res);
  }

  getAsset(Id) {
    return this.commonHttpService
      .get("/assets/" + encodeURI(Id))
      .then((res) => res);
  }

  updateAsset(postBody, Id) {
    return this.commonHttpService
      .put("/assets/" + encodeURI(Id), postBody)
      .then((res) => res);
  }

  deleteAsset(postBody) {
    return this.commonHttpService
      .delete("/assets", postBody)
      .then((res) => res);
  }
}
