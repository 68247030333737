import { Component, OnInit, Renderer2 } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import * as _ from "underscore";
import { MatTabChangeEvent } from "@angular/material/tabs";

import { AccountsService } from "../services/account.services";

@Component({
    selector: "app-user-account",
    templateUrl: "./user-account.component.html",
    styleUrls: ["./user-account.component.scss"]
})

export class UserAccountComponent implements OnInit {

    tabComponents: Array<Object>;
    currentTab: Object;

    constructor(
        private _route: Router,
        private _service: AccountsService,
        private renderer: Renderer2
    ) {
        // 
    }

    ngOnInit() {
        this.tabComponents = [
            {
                id: 1,
                label: "Account Details",
                query: "profile",
            },
            {
                id: 2,
                label: "Change Password",
                query: "password",
            }
        ];

        this.currentTab = this.tabComponents[0];
    }

    tabChange(tab: MatTabChangeEvent) {
        this.currentTab = this.tabComponents[tab.index];
    }
}