import {
  Component,
  OnChanges,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { AssetModalWidgetComponent } from "../assets/assets.modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-slideshow-widget",
  templateUrl: "./slideshow.widget.component.html",
  styleUrls: ["./widget.scss"],
})
export class SlideshowWidgetComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Input() widget: Object;
  @Output() onAddAsset = new EventEmitter();
  @Output() onRemoveAsset = new EventEmitter();
  slideImages: Array<Object> = [];

  widgetProps = {
    name: "Slideshow ",
    transition: "animate__fadeOut",
    transition_delay: 10,
    bgColor: "#dcdcdc",
    images: [],
  };

  constructor(
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    //
  }

  ngOnChanges() {
    this.widgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Slideshow " + this.widget["index"],
      bgColor:
        (this.widget["props"] && this.widget["props"]["bgColor"]) || "#dcdcdc",
      transition:
        (this.widget["props"] && this.widget["props"]["transition"]) ||
        "animate__fadeOut",
      transition_delay:
        (this.widget["props"] && this.widget["props"]["transition_delay"]) ||
        10,
      images: (this.widget["props"] && this.widget["props"]["images"]) || [],
    };
    this.slideImages =
      this.widgetProps.images.length > 0 ? this.widgetProps.images : [];

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false,
    });
  }

  saveWidgetProps() {
    this.widgetProps.images = this.slideImages;
    let images = [];
    this.slideImages.forEach((elem) => {
      images.push(elem["mediaUrl"]);
    });
    this.onAddAsset.emit(images);
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true,
    });
  }

  openAssetsModal() {
    const dialogRef = this.dialog.open(AssetModalWidgetComponent, {
      width: "900px",
      data: {
        assetType: "images",
      },
    });

    dialogRef.afterClosed().subscribe((assetObj) => {
      if (assetObj && assetObj.mediaUrl !== "") {
        this.slideImages.push({
          filename: assetObj.filename,
          mediaUrl: assetObj.mediaUrl,
          thumbnail: assetObj.thumbnail,
          originalname: assetObj.originalname,
          fileId: assetObj.fileId,
        });

        this.widgetProps.images = this.slideImages;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  deleteSlideItem(index: number) {
    let images = [];
    images = this.widgetProps.images;
    images.splice(index, 1);
    this.onRemoveAsset.emit(index);

    this.widgetProps.images = images;
    this.changeDetectorRef.detectChanges();
  }
}
