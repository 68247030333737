<div class="row pt10 pb10">
  <div class="col-sm-12">
    <div class="pull-left mr15">
      <button mat-icon-button (click)="onAddItem()">
        <mat-icon>add_box</mat-icon>
      </button>
    </div>
    <div class="pull-left mr30">
      <button mat-icon-button (click)="onDeleteItem()">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
    <div class="pull-left mr15">
      <button mat-icon-button (click)="onZoomInClick()">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
    <div class="pull-left mr15">
      <mat-slider
        color="primary"
        min="1"
        max="5"
        step="0.5"
        [value]="sliderVal"
        (change)="onSliderChange($event)"
        (input)="onSliderChange($event)"
      ></mat-slider>
    </div>
    <div class="pull-left mr15">
      <button mat-icon-button (click)="onZoomOutClick()">
        <mat-icon>zoom_in</mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <hr class="medium-gray mt0 mb0" />
  </div>
</div>