import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class DimensionService {

    constructor(private commonHttpService: CommonHttpService) { }

    getDimensions(page, limit, searchQry = '', sortyByOrientation = '') {
        return this.commonHttpService.get('/dimensions?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + searchQry + '&sort=' + encodeURIComponent(sortyByOrientation)).then(res => res);
    }

    setDimension(postBody) {
        return this.commonHttpService.post('/dimensions', postBody).then(res => res);
    }

    getDimension(dimensionID) {
        return this.commonHttpService.get('/dimension?id=' + encodeURI(dimensionID)).then(res => res);
    }

    updateDimension(postBody, dimensionID) {
        return this.commonHttpService.put('/dimensions?id=' + encodeURI(dimensionID) , postBody).then(res => res);
    }

    deleteDimension(postBody) {
        return this.commonHttpService.delete('/dimensions', postBody).then(res => res);
    }
}