import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AssetsService } from "../../../../../manage/pages/assets/services/assets.service";
import { DimensionService } from "../../../../../manage/pages/dimensions/services/dimensions.service";

@Component({
  selector: "app-asset-modal",
  templateUrl: "./assets.modal.component.html",
  styleUrls: ["./assets.modal.component.scss"],
})
export class AssetModalWidgetComponent implements OnInit {
  assetType: string;
  dataArray: Array<Object> = [];
  totalCount: number = 0;
  searchQuery: string = "";
  data: any;
  dimensions: Array<Object> = [];
  sortby: string = "date";
  filter: string = "all";
  dimension: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _assetService: AssetsService,
    private _dimensionService: DimensionService,
  ) {
    this.data = _data;
  }

  ngOnInit() {
    this.getAllAssets(1, 10);
    this._dimensionService.getDimensions(1, 10000).then((res) => {
      this.dimensions = res["data"];
    });
  }

  getAllAssets(page, limit) {
    this._assetService
      .getAllAssets(page, limit, this.filter, this.sortby, this.data.assetType)
      .then((res) => {
        this.dataArray = res["data"];
        this.totalCount = res["count"];
      });
  }

  onSearch() {
    this.getAllAssets(1, 10);
  }

  onPageChange(event: any) {
    this.getAllAssets(++event.pageIndex, event.pageSize);
  }

  onApplyFilter(event) {
    this.filter = event;
    this.getAllAssets(1, 10);
  }

  onApplySort(event) {
    this.sortby = event;
    this.getAllAssets(1, 10);
  }
}
