<div class="modal-content">
  <div class="modal-header">
    <div class="pull-left">
      <h2>Add New Player</h2>
      <small>Search and add new players</small>
    </div>
    <div class="pull-right">
      <div class="searchBar">
        <form (ngSubmit)="onSearch()">
          <mat-form-field class="example-full-width">
            <input
              type="text"
              matInput
              placeholder="Search"
              [(ngModel)]="searchQry"
              name="searchQry"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="sceneModalFilter">
    <div class="pull-left filter">
      <mat-label placeholder="Dimension" name="dimension">Dimension</mat-label>
      <mat-select
        [ngModel]="dimension"
        (ngModelChange)="onChangeDimension($event)"
      >
        <mat-option value="all">All</mat-option>
        <mat-option [value]="item.name" *ngFor="let item of dimensions">{{
          item.name + " - " + item.width + "px by " + item.height + "px"
        }}</mat-option>
      </mat-select>
    </div>
    <div class="pull-left filter">
      <mat-label>Location</mat-label>
      <mat-select
        placeholder="Location"
        name="location"
        [ngModel]="location"
        (ngModelChange)="onChangeLocation($event)"
      >
        <mat-option value="all">All</mat-option>
        <mat-option [value]="item.name" *ngFor="let item of locations">{{
          item.name
        }}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="modal-body" mat-dialog-content>
    <table width="100%" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="_id">
        <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-checkbox
            color="primary"
            (change)="doSelectAll()"
            [checked]="isAllChecked()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            [checked]="isChecked(element)"
            (change)="doSelect(element)"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          <h2>{{ element.name || "New Player" }}</h2>
        </td>
      </ng-container>
      <ng-container matColumnDef="dimension">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Max Supported Dim.
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.dimension || "Nil" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="playerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Player ID</th>
        <td mat-cell *matCellDef="let element">{{ element.playerId }}</td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let element">
          {{ element.location || "Nil" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="storage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Storage</th>
        <td mat-cell *matCellDef="let element">
          {{
            element.storage
              ? element.storage + "- (used " + element.used_size + ")"
              : "No storage info"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.is_online ? "Online" : "Offline" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          {{ element.status === "A" ? "Approved Player" : "Pending" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="clearfix"></div>
    <app-paginate
      [length]="totalCount"
      pageSize="10"
      (onPageChange)="onPageChange($event)"
    ></app-paginate>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="secondary" [mat-dialog-close]="[]">
      Cancel
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="checkedList">
      Add Player(s)
    </button>
  </div>
</div>