import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-tab-players",
  templateUrl: "./tab-players.component.html",
  styleUrls: ["./tab-players.component.scss"],
})
export class TabPlayersComponent implements OnInit {
  @Input() dataObj: Array<Object>;

  @Output() onDeleteEventEmitter = new EventEmitter();
  @Output() onViewEventEmitter = new EventEmitter();
  @Output() onDeletePlaylistEventEmitter = new EventEmitter();

  ngOnInit() {}

  constructor() {}

  onDeleteEvent(event) {
    this.onDeleteEventEmitter.emit(event);
  }

  onViewEvent(event) {
    this.onViewEventEmitter.emit(event);
  }

  onDeleteEventPl(event) {
    this.onDeletePlaylistEventEmitter.emit(event);
  }
}
