import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "../../../config";

@Injectable({
  providedIn: "root",
})

export class ForgotPasswordService {
  constructor(private http: HttpClient) {}

  resetUserPassword(email) {
    return this.http.post(API_URL + "/reset", {
      email,
    });
  }
}
