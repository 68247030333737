import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./modules/login/login.component";
import { ForgotPasswordComponent } from "./modules/forgot-password/forgot-password.component";
import { CreateComponent } from "./modules/create/create.component";
import { LandingComponent } from "./modules/user/landing/landing.component";
import { DimensionListComponent } from "./modules/user/manage/pages/dimensions/list/list.component";
import { LandingManageComponent } from "./modules/user/manage/landing-manage/landing-manage.component";
import { DimensionFormComponent } from "./modules/user/manage/pages/dimensions/form/form.component";
import { LocationListComponent } from "./modules/user/manage/pages/location/list/list.component";
import { LocationFormComponent } from "./modules/user/manage/pages/location/form/form.component";
import { AssetListComponent } from "./modules/user/manage/pages/assets/list/list.component";
import { UploadAssetComponent } from "./modules/user/manage/pages/assets/upload/upload-asset.component";
import { AssetViewComponent } from "./modules/user/manage/pages/assets/view/asset-view.component";

import { LandingDashboardComponent } from "./modules/user/dashboard/landing-dashboard/landing-dashboard.component";
import { PlaylistListComponent } from "./modules/user/dashboard/pages/playlists/list/list.component";
import { PlaylistFormComponent } from "./modules/user/dashboard/pages/playlists/form/form.component";
import { PlayersListComponent } from "./modules/user/dashboard/pages/players/list/list.component";
import { PlayerFormComponent } from "./modules/user/dashboard/pages/players/form/form.component";
import { PlayerFormApprovalComponent } from "./modules/user/dashboard/pages/players/approve/form-approve.component";
import { PlayerGroupListComponent } from "./modules/user/dashboard/pages/player-groups/list/list.component";
import { PlayerGroupFormComponent } from "./modules/user/dashboard/pages/player-groups/form/form.component";
import { SchedulerComponent } from "./modules/user/manage/pages/scheduler/scheduler.component";
import { ZoneEditorListComponent } from "./modules/user/dashboard/pages/zone-editor/list/list.component";
import { ZoneEditorForm } from "./modules/user/dashboard/pages/zone-editor/create/form/form.component";
import { ZoneEditorComponent } from "./modules/user/dashboard/pages/zone-editor/create/editor/zone-editor.component";
import { SceneListComponent } from "./modules/user/dashboard/pages/scene-editor/list/list.component";
import { SceneEditorComponent } from "./modules/user/dashboard/pages/scene-editor/editor/editor.component";

// Guards
import { AuthGuard } from "./guards/Auth.guard";
import { ActivityComponent } from './modules/user/activity/pages/activity.component';
import { DashboardAnalyticsComponent } from './modules/user/dashboard/pages/analytics/dashboard-analytics.component';
import { UserAccountComponent } from './modules/user/account/pages/user-account.component';
import { NotificationComponent } from './modules/user/notifications/pages/notification.component';
import { AdminActivityComponent } from './modules/admin/activity/pages/activity.component';
import { AdminNotificationComponent } from './modules/admin/notifications/pages/notification.component';
import { AdminAccountComponent } from './modules/admin/account/pages/admin-account.component';
import { AdminOrgListComponent } from './modules/admin/organisations/list/list.component';
import { AdminOrganisationFormComponent } from './modules/admin/organisations/form/form-organisation.component';
import { AdminSlabListComponent } from './modules/admin/slabs/list/list.component';
import { AdminSlabFormComponent } from './modules/admin/slabs/form/form.component';
import { AdminOrgActivateComponent } from './modules/admin/organisations/activate/activate.component';
import { EventListComponent } from "./modules/user/manage/pages/scheduler/index/list.component";
import { RoleFormComponent } from "./modules/user/manage/pages/roles/form/form.component";
import { RolesListComponent } from "./modules/user/manage/pages/roles/list/list.component";
import { OrgUsersListComponent } from "./modules/user/manage/pages/users/list/list.component";
import { OrgUserFormComponent } from "./modules/user/manage/pages/users/form/form.component";
import { AdminOrganisationAddFormComponent } from "./modules/admin/organisations/add/form-organisation.component";
import { AccountVerifyComponent } from "./modules/verify/account.verify.component";
import { TwoFactorAuthComponent } from "./modules/two-factor/two-factor.component";

const routes: Routes = [
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: "reset", component: ForgotPasswordComponent, pathMatch: "full" },
  { path: "account/verify/:code", component: AccountVerifyComponent, pathMatch: "full" },
  { path: "account/two-factor", component: TwoFactorAuthComponent, pathMatch: "full" },
  { path: "create", component: CreateComponent, pathMatch: "full" },
  {
    path: "admin",
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "organisations",
        component: AdminOrgListComponent
      },
      {
        path: "organisations/add",
        component: AdminOrganisationAddFormComponent
      },
      {
        path: "organisations/edit/:id",
        component: AdminOrganisationFormComponent
      },
      {
        path: "organisations/activate/:id",
        component: AdminOrgActivateComponent
      },
      {
        path: "slabs",
        component: AdminSlabListComponent
      },
      {
        path: "slabs/add",
        component: AdminSlabFormComponent
      },
      {
        path: "slabs/edit/:id",
        component: AdminSlabFormComponent
      }
    ]
  },
  {
    path: "manage",
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: LandingManageComponent,
        children: [
          {
            path: "dimensions",
            component: DimensionListComponent
          },
          {
            path: "dimensions/add",
            component: DimensionFormComponent
          },
          {
            path: "dimensions/edit/:id",
            component: DimensionFormComponent
          },
          {
            path: "location",
            component: LocationListComponent
          },
          {
            path: "location/add",
            component: LocationFormComponent
          },
          {
            path: "location/edit/:id",
            component: LocationFormComponent
          },
          {
            path: "scheduler",
            component: EventListComponent
          },
          {
            path: "scheduler/calendar-view",
            component: SchedulerComponent
          },
          {
            path: "players",
            component: PlayersListComponent
          },
          {
            path: "players/add",
            component: PlayerFormComponent
          },
          {
            path: "players/edit/:id",
            component: PlayerFormComponent
          },
          {
            path: "players/approve/:id",
            component: PlayerFormApprovalComponent
          },
          {
            path: "playlists",
            component: PlaylistListComponent
          },
          {
            path: "playlists/add",
            component: PlaylistFormComponent
          },
          {
            path: "playlists/edit/:id",
            component: PlaylistFormComponent
          },
          {
            path: "player-groups",
            component: PlayerGroupListComponent
          },
          {
            path: "player-groups/add",
            component: PlayerGroupFormComponent
          },
          {
            path: "player-groups/edit/:id",
            component: PlayerGroupFormComponent
          },
          {
            path: "zone-editor",
            component: ZoneEditorListComponent
          },
          {
            path: "zone-editor/create",
            component: ZoneEditorForm
          },
          {
            path: "zone-editor/editor",
            component: ZoneEditorComponent
          },
          {
            path: "scene-editor",
            component: SceneListComponent
          },
          {
            path: "scene-editor/editor",
            component: SceneEditorComponent
          },
          {
            path: "scene-editor/edit/:id",
            component: SceneEditorComponent
          },
          {
            path: "user-role",
            component: RolesListComponent
          },
          {
            path: "user-role/add",
            component: RoleFormComponent
          },
          {
            path: "user-role/edit/:id",
            component: RoleFormComponent
          },
          {
            path: "users",
            component: OrgUsersListComponent
          },
          {
            path: "users/add",
            component: OrgUserFormComponent
          },
          {
            path: "users/edit/:id",
            component: OrgUserFormComponent
          },
        ]
      }
    ]
  }, {
    path: "dashboard",
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: LandingDashboardComponent,
        children: [
          {
            path: "analytics",
            component: DashboardAnalyticsComponent
          },
          {
            path: "assets",
            component: AssetListComponent
          },
          {
            path: "assets/upload",
            component: UploadAssetComponent
          },
          {
            path: "assets/view/:id",
            component: AssetViewComponent
          }
        ]
      }
    ]
  },
  {
    path: 'activity',
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        component: ActivityComponent
      },
      {
        path: 'admin',
        component: AdminActivityComponent
      }
    ]
  },
  {
    path: 'account',
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        component: UserAccountComponent
      },
      {
        path: 'admin',
        component: AdminAccountComponent
      }
    ]
  },
  {
    path: 'notification',
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        component: NotificationComponent
      },
      {
        path: 'admin',
        component: AdminNotificationComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
