import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

import { LocationsService } from "../services/locations.service";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class LocationFormComponent implements OnInit {
  crumbItems: Array<Object>;
  location: Object = {
    name: "",
    code: "",
  };
  id: any;
  saveBtnText = "Save";

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
    code: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _route: Router,
    private _service: LocationsService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false,
      },
      {
        label: "Location",
        path: "manage/location",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = (this.id) ? "Update" : "Save";

    if (this.id) {
      this.spinner.show();
      this._service.getLocation(this.id).then(res => {
        this.myForm.get("name").setValue(res['data']['name']);
        this.myForm.get("code").setValue(res['data']['code']);
        this.spinner.hide();
      });
    }
  }

  save() {
    let postBody = {
      name: this.myForm.value.name,
      code: this.myForm.value.code
    };

    this.spinner.show();
    try {
      if (this.id) {
        this._service.updateLocation(postBody, this.id).then((res) => {
          if (res["status"] === 200) {
            this._route.navigate(["/manage/location"]);
          } else {
            alert(res["message"]);
          }
          this.spinner.hide();
        });
      } else {
        this._service.setLocation(postBody).then((res) => {
          if (res["status"] === 200) {
            this._route.navigate(["/manage/location"]);
          } else {
            alert(res["message"]);
          }
          this.spinner.hide();
        });
      }
    } catch (error) {
      this.spinner.hide();
    }
  }
}
