import { Component, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";

import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { SchedulerService } from "../services/scheduler.service";

import { SchedulerModal } from "../modal/scheduler-modal.component";
import { SchedulerPlayerGroupModal } from "../modal-player-groups/scheduler-modal.component";

@Component({
  selector: "app-event-index",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class EventListComponent implements OnInit {
  crumbItems: Array<Object>;
  tabComponents: Array<Object>;
  currentTab: Object;

  data: Array<Object> = [];
  totalCount: number = 0;
  searchQuery: string = "";

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _service: SchedulerService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.tabComponents = [
      {
        id: 1,
        label: "Players",
        query: "players",
      },
      {
        id: 2,
        label: "Player Groups",
        query: "playergroups",
      },
    ];

    this.currentTab = this.tabComponents[0];
    this.getTabComponents(1, 10);
  }

  tabChange(tab: MatTabChangeEvent) {
    this.currentTab = this.tabComponents[tab.index];
    this.searchQuery = "";
    this.getTabComponents(1, 10, this.searchQuery);
  }

  getTabComponents(page, limit, search = "") {
    this.spinner.show();
    this._service
      .getTabDataByItem(this.currentTab["query"], page, limit, search, "list")
      .then((res) => {
        this.data = res["data"];
        this.totalCount = res["count"];
        this.spinner.hide();
      });
  }

  onCalendarView(event) {
    this.router.navigate(["/manage/scheduler/calendar-view"]);
  }

  onSearch(searchQry: any) {
    this.getTabComponents(1, 10, searchQry);
  }

  onDelete(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Confirm Deletion",
        content:
          "Do you want to delete this entry from listing?  There is no UNDO!!!",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteIdsArray([id]);
      }
    });
  }

  deleteIdsArray(arrayOfIds) {
    let postBody = {
      ids: arrayOfIds,
    };
    this.spinner.show();
    this._service.deleteEvent(postBody).then((res) => {
      if (res["status"] !== 200) {
        alert(res["message"]);
      }
      this.getTabComponents(1, 10, this.searchQuery);
      this.spinner.hide();
    });
  }

  onPageChange(event: any) {
    this.getTabComponents(++event.pageIndex, event.pageSize, this.searchQuery);
  }

  onPublish(id) {
    let postBody = {
      status: true,
    };
    this.spinner.show();
    this._service.setPublishEvent(postBody, id).then((res) => {
      if (res["status"] === 200) {
        this.getTabComponents(1, 10, this.searchQuery);
      }
      this.spinner.hide();
    });
  }

  onEventRefresh(id) {
    let postBody = {
      status: true,
    };
    this.spinner.show();
    this._service.setRefreshStatus(postBody, id).then((res) => {
      if (res["status"] === 200) {
        this.getTabComponents(1, 10, this.searchQuery);
      }
      this.spinner.hide();
    });
  }

  onEventStop(id) {
    const dialogRef = this.dialog.open(CustomModalComponent, {
      width: "400px",
      data: {
        title: "Stop Event?",
        content: "Do you want to stop this Playlist from scheduler?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let postBody = {
          status: false,
        };
        this.spinner.show();
        this._service.setPublishEvent(postBody, id).then((res) => {
          if (res["status"] === 200) {
            this.getTabComponents(1, 10, this.searchQuery);
          }
          this.spinner.hide();
        });
      }
    });
  }

  openDialog() {
    let dialogRef;

    if (this.currentTab["query"] === "players") {
      dialogRef = this.dialog.open(SchedulerModal, {
        width: "800px",
        data: {
          currentTab: this.currentTab,
        },
      });
    } else {
      dialogRef = this.dialog.open(SchedulerPlayerGroupModal, {
        width: "800px",
        data: {
          currentTab: this.currentTab,
        },
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTabComponents(1, 10, this.searchQuery);
      }
    });
  }
}
