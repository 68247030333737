import { NgModule } from "@angular/core";
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChartsModule } from 'ng2-charts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LandingComponent } from "../landing/landing.component";
import { LandingDashboardComponent } from "./landing-dashboard/landing-dashboard.component";
import { PlaylistListComponent } from "./pages/playlists/list/list.component";
import { PlaylistFormComponent } from "./pages/playlists/form/form.component";
import { SceneModalComponent } from "./pages/playlists/modal/scene-modal.component";
import { PlayersListComponent } from "./pages/players/list/list.component";
import { PlayerFormComponent } from "./pages/players/form/form.component";
import { PlayerFormApprovalComponent } from "./pages/players/approve/form-approve.component";
import { PlayerGroupListComponent } from "./pages/player-groups/list/list.component";
import { PlayerGroupFormComponent } from "./pages/player-groups/form/form.component";
import { PlayerGroupModalComponent } from "./pages/player-groups/modal/player-group-modal.component";

import { ZoneEditorListComponent } from "./pages/zone-editor/list/list.component";
import { ZoneEditorForm } from "./pages/zone-editor/create/form/form.component";
import { ZoneEditorComponent } from "./pages/zone-editor/create/editor/zone-editor.component";
import { ZoneEditorHeaderComponent } from "./pages/zone-editor/create/header/header.component";
import { ZoneEditorToolbarComponent } from "./pages/zone-editor/create/toolbar/toolbar.component";
import { ZoneEditorPropertyComponent } from "./pages/zone-editor/create/zone-property/zone-property.component";

import { SceneListComponent } from "./pages/scene-editor/list/list.component";
import { SceneAddModalComponent } from "./pages/scene-editor/modal/modal.component";
import { SceneEditorComponent } from "./pages/scene-editor/editor/editor.component";
import { SceneEditorHeaderComponent } from "./pages/scene-editor/editor/header/header.component";
import { SceneAddItemModal } from "./pages/scene-editor/editor/modal/modal.component";
import { ImageWidgetComponent } from "./pages/scene-editor/editor/widget/image.widget.component";
import { AssetModalWidgetComponent } from "./pages/scene-editor/editor/assets/assets.modal.component";
import { SceneWidgetComponent } from "./pages/scene-editor/editor/widget/scene.widget.component";
import { VideoWidgetComponent } from "./pages/scene-editor/editor/widget/video.widget.component";
import { TextWidgetComponent } from "./pages/scene-editor/editor/widget/text.widget.component";
import { ButtonWidgetComponent } from "./pages/scene-editor/editor/widget/button.widget.component";
import { ClockWidgetPropsComponent } from "./pages/scene-editor/editor/widget/clock.widget.component-props";
import { ClockWidgetComponent } from "./pages/scene-editor/editor/widget/clock-widget.component";
import { SlideshowWidgetComponent } from "./pages/scene-editor/editor/widget/slideshow.widget.component";
import { DashboardAnalyticsComponent } from "./pages/analytics/dashboard-analytics.component";
import { SortOrderComponent } from "./pages/playlists/components/sort-order/sort-order.component";
import { PlaylistSceneSetDurationComponent } from "./pages/playlists/components/duration/duration.component";

// Module
import { SharedModule } from "src/app/shared/module/shared.module";
import { WidgetAlertComponent } from "./pages/scene-editor/editor/widget-alert/widget-alert.component";
import { HTMLViewerWidgetComponent } from "./pages/scene-editor/editor/widget/html-viewer.widget.component";
import { MarqueeWidgetComponent } from "./pages/scene-editor/editor/widget/marquee.component";
import { SlideshowPreviewModal } from "./pages/scene-editor/editor/widget/preview/preview.modal.component";
import { PdfWidgetComponent } from "./pages/scene-editor/editor/widget/pdf.widget.component";

@NgModule({
  declarations: [
    LandingComponent,
    LandingDashboardComponent,
    PlaylistListComponent,
    PlaylistFormComponent,
    SceneModalComponent,
    PlayersListComponent,
    PlayerFormComponent,
    PlayerFormApprovalComponent,
    PlayerGroupListComponent,
    PlayerGroupFormComponent,
    PlayerGroupModalComponent,
    ZoneEditorListComponent,
    ZoneEditorForm,
    ZoneEditorComponent,
    ZoneEditorHeaderComponent,
    ZoneEditorToolbarComponent,
    ZoneEditorPropertyComponent,
    SceneListComponent,
    SceneAddModalComponent,
    SceneEditorComponent,
    SceneEditorHeaderComponent,
    SceneAddItemModal,
    ImageWidgetComponent,
    AssetModalWidgetComponent,
    SceneWidgetComponent,
    VideoWidgetComponent,
    TextWidgetComponent,
    ButtonWidgetComponent,
    ClockWidgetPropsComponent,
    ClockWidgetComponent,
    HTMLViewerWidgetComponent,
    MarqueeWidgetComponent,
    DashboardAnalyticsComponent,
    SortOrderComponent,
    PlaylistSceneSetDurationComponent,
    WidgetAlertComponent,
    SlideshowWidgetComponent,
    SlideshowPreviewModal,
    PdfWidgetComponent
  ],
  imports: [
    SharedModule, 
    FullCalendarModule, 
    MatAutocompleteModule,
    DateTimePickerModule,
    ColorPickerModule,
    ChartsModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  entryComponents: [
    SceneModalComponent, 
    PlayerGroupModalComponent,
    SceneAddModalComponent,
    SceneAddItemModal,
    AssetModalWidgetComponent,
    WidgetAlertComponent,
    SlideshowPreviewModal
  ]
})
export class DashboardModule {}
