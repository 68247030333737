import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";
import { LightboxModule } from 'ngx-lightbox';

import { AdminAccountComponent } from "./pages/admin-account.component";
import { UserAccountTabProfileComponent } from './pages/profile/tab-profile.component';
import { UserAccountTabPasswordComponent } from './pages/password-tab/tab-user-account-password.component';

@NgModule({
  imports: [SharedModule, LightboxModule],
  declarations: [
    AdminAccountComponent,
    UserAccountTabProfileComponent,
    UserAccountTabPasswordComponent
  ] 
})

export class AdminAccountModule {}