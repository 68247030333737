import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { DimensionService } from "../services/dimensions.service";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class DimensionFormComponent implements OnInit {
  crumbItems: Array<Object>;
  dimension: Object = {
    name: "",
    width: "",
    height: "",
    orientation: "",
  };
  id: any;
  saveBtnText = "Save";

  responseErr: Boolean = false;
  responseMsg: String = '';

  myForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
    width: new UntypedFormControl("", [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/), Validators.minLength(3), Validators.maxLength(4)]),
    height: new UntypedFormControl("", [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]+$/),
      Validators.minLength(3),
      Validators.maxLength(4)
    ]),
    orientation: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _route: Router,
    private dimensionService: DimensionService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Manage",
        path: null,
        current: false,
      },
      {
        label: "Dimension",
        path: "manage/dimensions",
        current: true,
      },
    ];

    this.id = this.route.snapshot.params.id;
    this.saveBtnText = (this.id) ? "Update" : "Save";

    if (this.id) {
      this.dimensionService.getDimension(this.id).then(res => {
        this.myForm.get("name").setValue(res['data']['name']);
        this.myForm.get("width").setValue(res['data']['width']);
        this.myForm.get("height").setValue(res['data']['height']);
        this.myForm.get("orientation").setValue(res['data']['orientation']);
      });
    }
  }

  save() {
    let postBody = {
      name: this.myForm.value.name,
      orientation: this.myForm.value.orientation,
      width: this.myForm.value.width,
      height: this.myForm.value.height,
      max_support_res: this.myForm.value.width + " " + this.myForm.value.height,
    };

    if (this.id) {
      this.dimensionService.updateDimension(postBody, this.id).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/dimensions"]);
        } else {
          this.responseErr = true;
          this.responseMsg = res["message"];
        }
      });
    } else {
      this.dimensionService.setDimension(postBody).then((res) => {
        if (res["status"] === 200) {
          this._route.navigate(["/manage/dimensions"]);
        } else { 
          this.responseErr = true;
          this.responseMsg = res["message"];
        }
      });
    }
  }
}
