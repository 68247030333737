import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class PlayerGroupsService {

    constructor(private commonHttpService: CommonHttpService) { }

    getPlayerGroups(page, limit, searchQry) {
        return this.commonHttpService.get('/playergroups?page=' + encodeURI(page) + '&limit=' + encodeURIComponent(limit) + '&searchQry=' + encodeURIComponent(searchQry)).then(res => res);
    }

    setPlayerGroup(postBody) {
        return this.commonHttpService.post('/playergroups', postBody).then(res => res);
    }

    getPlayerGroup(Id) {
        return this.commonHttpService.get('/playergroups/' + encodeURI(Id)).then(res => res);
    }

    updatePlayerGroup(postBody, Id) {
        return this.commonHttpService.put('/playergroups/' + encodeURI(Id) , postBody).then(res => res);
    }

    deletePlayerGroup(postBody) {
        return this.commonHttpService.delete('/playergroups', postBody).then(res => res);
    }

    getPlayersBySearch(postBody) {
        return this.commonHttpService.post('/players/search', postBody).then(res => res);
    }

    removePlayerFromPlayerGroup(postBody) {
        return this.commonHttpService.delete('/players/fromGroup', postBody).then(res => res);
    }
}