import { Injectable } from "@angular/core";
import { CommonHttpService } from "../../../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class ScenesService {

    constructor(private commonHttpService: CommonHttpService) { }

    getScenes(itemType: string, page: number, limit: number, searchQry: string = '') {
        return this.commonHttpService.get('/scenes?query=' + encodeURI(itemType) + '&searchQry=' + encodeURIComponent(searchQry) + '&page=' + encodeURIComponent(page) + '&limit=' + encodeURIComponent(limit)).then(res => res);
    }

    setScene(postBody) {
        return this.commonHttpService.post('/scenes', postBody).then(res => res);
    }

    isSceneExists(postBody) {
        return this.commonHttpService.post('/scenes/is_exists', postBody).then(res => res);
    }

    getScene(Id) {
        return this.commonHttpService.get('/scenes/' + encodeURI(Id)).then(res => res);
    }

    updateScene(postBody, Id) {
        return this.commonHttpService.put('/scenes/' + encodeURI(Id) , postBody).then(res => res);
    }

    deleteScene(postBody) {
        return this.commonHttpService.delete('/scenes', postBody).then(res => res);
    }
}