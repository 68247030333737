import { Injectable } from "@angular/core";

import { SessionStorage } from "../../../../services/session-storage";
import { CommonHttpService } from "../../../../services/common-http";

@Injectable({
    providedIn: 'root'
})

export class AdminAccountsService {

    constructor(private commonHttpService: CommonHttpService, private sessionStorageService: SessionStorage) { }
    
    async getUserAccountDetails() {
        let userID = JSON.parse(this.sessionStorageService.getUserData())['_id'];
        const res = await this.commonHttpService.get('/organisations/user/' + encodeURIComponent(userID));
        return res;
    }

    async updateUserAccount(postBody) {
        let userID = JSON.parse(this.sessionStorageService.getUserData())['_id'];
        const res = await this.commonHttpService.put('/organisations/user/' + encodeURI(userID), postBody);
        return res;
    }

    async verifyCurrentPassword(password) {
        let userID = JSON.parse(this.sessionStorageService.getUserData())['_id'];
        let postBody = {
            password
        };
        const res = await this.commonHttpService.post('/organisations/user/verifyPassword/' + encodeURI(userID), postBody);
        return res;
    }

    async setUserPassword(postBody) {
        let userID = JSON.parse(this.sessionStorageService.getUserData())['_id'];
        const res = await this.commonHttpService.put('/organisations/user/setPassword/' + encodeURI(userID), postBody);
        return res;
    }
}