
<div class="uploadContainer" *ngIf="!uploader.queue.length">
    <div ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="uploader" class="appAssetUploader">
        <h3>Drop files anywhere to upload</h3> <span>OR</span> <input type="file" value="Upload" ng2FileSelect [uploader]="uploader" multiple class="uploaderInput" /> <span class="helper-block">Maximum upload file size: 128 MB. Supported jpg, png, docs, videos</span    >  </div></div><div class="appUploadQueue" *ngIf="uploader?.queue?.length > 0">  <div class="uploadItem" *ngFor="let item of uploader.queue">    <span *ngIf="item.isSuccess" class="uploadStatus success">      <mat-icon>check</mat-icon>    </span>
        <span *ngIf="item.isCancel" class="uploadStatus cancel"><mat-icon>cancel</mat-icon></span    >    <span *ngIf="item.isError" class="uploadStatus err">      <mat-icon>warning</mat-icon>    </span> <button (click)=
            "item.remove()" class="matButton">      <mat-icon>delete_outline</mat-icon>    </button>
        <button class="matButton matButtonCancel" (click)="item.cancel()" *ngIf="item.isUploading">
            <mat-icon>cancel</mat-icon> </button> <img src="" *ngIf="        item?.file?.type === 'image/png' ||        item?.file?.type === 'image/jpg' ||        item?.file?.type === 'image/jpeg'      " thumbnail [image]="item?._file" /> <video  *ngIf=" item?.file?.type === 'video/mp4' " width="100%"  #videoPlayer  controls  controlsList="nodownload">        <source [src]="previewPath " />           Browser not supported    </video>
            <div class="itemInfo">
                <h3 [ngClass]="{ err: item.isError }">{{ item?.file?.name }}</h3> <span>{{ item?.file?.size / 1024 / 1024 | number: "0.2" }} MB</span>
                <div *ngIf="uploader.options.isHTML5">
                    <div class="progress" style="margin-bottom: 0;">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{ width: item.progress + '%' }"></div> </div> </div> </div>
<!-- Add sample thumb for videos and documents--> </div>
<div class="textCenter"> <button (click)="uploader.uploadAll()" mat-button color="primary" *ngIf="!hideUploadButton">      Upload All    </button> <button (click)="moreUpload()" mat-button color="primary" *ngIf="showMoreUploadButton">      More Upload    </button>
    <button (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading" mat-button> Cancel All </button>
        <button (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length" mat-button> Clear All </button> </div></div>