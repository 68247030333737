import { Component, OnChanges, Input, ChangeDetectorRef, SimpleChanges } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";

@Component({
    selector: "app-clock-widget",
    templateUrl: "./clock-widget.component.html",
    styleUrls: ['./widget.scss']
})

export class ClockWidgetComponent implements OnChanges {

    @Input() hrFormat: string;
    @Input() timeZone: string;
    @Input() backgroundColor: string;
    day: string;
    hours: string;
    minutes: string;
    seconds: string;

    constructor(private ref: ChangeDetectorRef) {}

    ngOnChanges() {

        setInterval(() => {

            var locale = moment().tz(this.timeZone);
            
            this.day = locale.format("dd");
            this.hours = locale.format(this.hrFormat);
            this.minutes = locale.format("mm");
            this.seconds = locale.format("ss");

            this.ref.detectChanges();
            this.ref.markForCheck();
        }, 1000);
    }
}