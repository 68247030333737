<div class="appContent">
  <app-header></app-header>
  <div class="contentWrapper m0">
    <app-tabs
      [tabComponents]="tabComponents"
      (tabChange)="tabChange($event)"
    ></app-tabs>
    <div class="userAccountTabContentWrapper pt20 pl20 pr20 pb20">
      <div class="row">
        <div class="col-xs-6">
          <app-admin-org-addtab-profile
            *ngIf="currentTab['query'] === 'profile'"
          ></app-admin-org-addtab-profile>
        </div>
      </div>
    </div>
  </div>
</div>