import { Component, Inject, OnInit } from "@angular/core";
import {
    MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
    selector: "app-widget-alert",
    templateUrl: './widget-alert.component.html',
    styleUrls: ['./widget-alert.component.scss']
})

export class WidgetAlertComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: Object) { }

    ngOnInit() {
        // 
    }

    chosenWidget() {

    }
}