import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";

import * as moment from "moment";
import momentTZ from 'moment-timezone';

@Component({
  selector: "app-clock-widget-props",
  templateUrl: "./clock.widget.component-props.html",
  styleUrls: ["./widget.scss"],
})
export class ClockWidgetPropsComponent implements OnChanges {
  @Output() onSaveProps = new EventEmitter();
  @Input() widget: Object;
  timeNow: string;
  timeZonesList = momentTZ.tz.names();

  widgetProps = {
    name: "Clock ",
    hrFormat: "H",
    timeZone: "",
    formatText: "",
    backgroundColor: "#333",
    coords: {
      left: 0,
      top: 0,
    },
    size: {
      width: 200,
      height: 200,
    },
  };

  ngOnChanges(): void {

    this.widgetProps = {
      name:
        (this.widget["props"] && this.widget["props"]["name"]) ||
        "Clock Widget " + this.widget["index"],
      hrFormat:
        (this.widget["props"] && this.widget["props"]["hrFormat"]) || "H",
      timeZone:
        (this.widget["props"] && this.widget["props"]["timeZone"]) || "Asia/Calcutta",
      formatText:
        (this.widget["props"] && this.widget["props"]["formatText"]) || "",
      backgroundColor:
        (this.widget["props"] && this.widget["props"]["backgroundColor"]) || '#333',
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top:
          (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
      size: {
        width:
          (this.widget["props"] && this.widget["props"]["size"]["width"]) || 0,
        height:
          (this.widget["props"] && this.widget["props"]["size"]["height"]) || 0,
      },
    };

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true
    });
  }
}
