import { Component, OnChanges, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-marquee-widget",
  templateUrl: "./marquee.component.html",
  styleUrls: ["./widget.scss"],
})

export class MarqueeWidgetComponent implements OnChanges {

  @Output() onSaveProps = new EventEmitter();
  @Input() widget: Object;

  widgetProps = {
    name: "Marquee ",
    fontFamily: 'Arial, sans-serif',
    fontSize: 12,
    direction: "left",
    behavior: "scroll",
    scrollDelay: 10,
    scrollAmount: 10,
    bgcolor: "#333",
    hasBorder: false,
    borderColor: "#333",
    borderWidth: "0",
    scrollText: '',
    color: "#333",
    bold: false,
    fontStyle: 'normal',
    underline: false,
    coords: {
      left: 0,
      top: 0,
    },
    size: {
      width: 100,
      height: 45
    }
  };

  ngOnChanges() {
    this.widgetProps = {
      name: (this.widget["props"] && this.widget["props"]["name"]) || "Marquee " + this.widget["index"],
      direction: (this.widget["props"] && this.widget["props"]["direction"]) || "",
      scrollText: (this.widget["props"] && this.widget["props"]["scrollText"]) || "",
      fontFamily: (this.widget["props"] && this.widget["props"]["fontFamily"]) || 'Arial, sans-serif',
      fontSize: (this.widget["props"] && this.widget["props"]["fontSize"]) || 12,
      behavior: (this.widget["props"] && this.widget["props"]["behavior"]) || "#333",
      scrollDelay: (this.widget["props"] && this.widget["props"]["scrollDelay"]) || 10,
      scrollAmount: (this.widget["props"] && this.widget["props"]["scrollAmount"]) || 10,
      bgcolor: (this.widget["props"] && this.widget["props"]["bgcolor"]) || '#333',
      color: (this.widget["props"] && this.widget["props"]["color"]) || "#333",
      bold:
        (this.widget["props"] && this.widget["props"]["bold"]) || false,
      fontStyle:
        (this.widget["props"] && this.widget["props"]["fontStyle"]) || false,
      underline:
        (this.widget["props"] && this.widget["props"]["underline"]) || false,
      hasBorder:
        (this.widget["props"] && this.widget["props"]["hasBorder"]) || false,
      borderColor:
        (this.widget["props"] && this.widget["props"]["borderColor"]) || "#333",
      borderWidth:
        (this.widget["props"] && this.widget["props"]["borderWidth"]) || "0",
      coords: {
        left:
          (this.widget["props"] && this.widget["props"]["coords"]["left"]) || 0,
        top: (this.widget["props"] && this.widget["props"]["coords"]["top"]) || 0,
      },
      size: {
        width:
          (this.widget["props"] && this.widget["props"]["size"]["width"]) || 100,
        height: (this.widget["props"] && this.widget["props"]["size"]["height"]) || 45,
      }
    };

    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: false,
    });
  }

  saveWidgetProps() {
    this.onSaveProps.emit({
      props: this.widgetProps,
      showAlert: true,
    });
  }
}
