import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-module-header",
  templateUrl: "./module-header.component.html",
  styleUrls: ["./module-header.component.scss"]
})
export class ModuleHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isBackEnabled: Boolean;
  @Input() showBulkMode: Boolean;
  @Input() showAddButton: Boolean;
  @Input() showSecondaryButton: Boolean = false;
  @Input() menuItems: Array<string>;
  @Input() btnText: string = "New";

  @Output() onMenuClicked = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onSecondaryButton = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  searchText: string = '';

  constructor(private _location: Location) {}

  ngOnInit() {
    // console.log("isBackEnabled", this.menuItems);
  }

  onMenuItemClicked(index) {
    this.onMenuClicked.emit(index);
  }

  onAddMenuClicked() {
    this.onAdd.emit(null);
  }

  onSecondaryButtonClick() {
    this.onSecondaryButton.emit(null);
  }

  onNavBack(): void {
    this._location.back();
  }

  onSearchSubmit(): void { 
    this.onSearch.emit(this.searchText);
  }
}
