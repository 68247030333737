import { Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "app-zone-property",
  templateUrl: "./zone-property.html",
  styleUrls: ["./zone-property.scss"]
})

export class ZoneEditorPropertyComponent implements OnInit {

    @Input() width: number;
    @Input() height: number;
    @Input() left: number;
    @Input() top: number;

    @Output() onWidthChange = new EventEmitter();
    @Output() onHeightChange = new EventEmitter();
    @Output() onLeftChange = new EventEmitter();
    @Output() onTopChange = new EventEmitter();

    ngOnInit() {  
        // 
    }

    constructor() { }

    onWidthChangeListener(event: any) {
        this.onWidthChange.emit(event);
    }
    onHeightChangeListener(event: any) {
        this.onHeightChange.emit(event);
    }
    onLeftChangeListener(event: any) {
        this.onLeftChange.emit(event);
    }
    onTopChangeListener(event: any) {
        this.onTopChange.emit(event);
    }
}
