<div class="appDrawer">
  <a href="#" class="appLogo">
    <img src="assets/logo.png" alt="" width="32px" />
  </a>
  <div class="appMenus" *ngIf="permissions == null">
    <div *ngFor="let menu of menuItems" class="d-block">
      <a
        [routerLink]="menu.path"
        [ngClass]="{ active: currentUrl === menu.route }"
      >
        <img [src]="menu.icon" width="20px" /> {{ menu.label }}
      </a>
    </div>
  </div>
  <div class="appMenus" *ngIf="userRole == 'admin'">
    <div *ngFor="let menu of menuItems" class="d-block">
      <a
        [routerLink]="menu.path"
        [ngClass]="{ active: currentUrl === menu.route }"
      >
        <img [src]="menu.icon" width="20px" /> {{ menu.label }}
      </a>
    </div>
  </div>
  <div class="appMenus" *ngIf="permissions != null">
    <div *ngFor="let menu of menuItems" class="d-block">
      <a
        *ngIf="menu.id != null && permissions.includes(menu.id)"
        [routerLink]="menu.path"
        [ngClass]="{ active: currentUrl === menu.route }"
      >
        <img [src]="menu.icon" width="20px" /> {{ menu.label }}
      </a>
      <a
        *ngIf="menu.id == null"
        [routerLink]="menu.path"
        [ngClass]="{ active: currentUrl === menu.route }"
      >
        <img [src]="menu.icon" width="20px" /> {{ menu.label }}
      </a>
    </div>
  </div>
</div>
