import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

import { AdminAccountsService } from "../../services/admin-account.services";

@Component({
    selector: "app-user-account-tab-profile",
    templateUrl: "./tab-profile.component.html",
    styleUrls: ["./tab-profile.component.scss"]
})

export class UserAccountTabProfileComponent implements OnInit {

    alertMessage: Object = {
        message: null,
        error: false,
    };

    data: Object = {};
    isLoading: Boolean = false;
    myForm = new UntypedFormGroup({
        name: new UntypedFormControl("", [Validators.required, Validators.minLength(5)]),
        contact_person: new UntypedFormControl("", [Validators.required]),
        primary_contact: new UntypedFormControl("", [Validators.required]),
        secondary_contact: new UntypedFormControl("", [Validators.required])
    });

    constructor(
        private _service: AdminAccountsService,
    ) {
        // 
    }

    ngOnInit() {
        this.getUserAccountDetails();
    }

    getUserAccountDetails(): void {
        this.isLoading = true;
        this._service.getUserAccountDetails().then(res => {
            this.myForm.get("name").setValue(res['data']['name']);
            this.myForm.get("contact_person").setValue(res['data']['contact_person']);
            this.myForm.get("primary_contact").setValue(res['data']['primary_contact']);
            this.myForm.get("secondary_contact").setValue(res['data']['secondary_contact']);

            this.data = res['data'];
            this.isLoading = false;
        });
    }

    save(): void {
        let postBody = {
            name: this.myForm.value.name,
            contact_person: this.myForm.value.contact_person,
            primary_contact: this.myForm.value.primary_contact,
            secondary_contact: this.myForm.value.secondary_contact
        }

        this._service.updateUserAccount(postBody).then(res => {
            this.alertMessage["message"] = res["message"];
            this.alertMessage["error"] = false;
        });
    }
}