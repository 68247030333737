import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
    selector: "app-scene-editor-header",
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class SceneEditorHeaderComponent implements OnInit {

    sceneProperties: Object;
    @Output() sceneInfoEvent = new EventEmitter();
    @Output() saveScene = new EventEmitter();
    zoomLevel: number = 1;
    currentZoomLevel: number = 4;
    zoomArr = [0.5, 0.75, 0.85, 0.9, 1, 1.2, 1.5];
    zoomLevelLabel: string = '100%';
    @Input() dataState: Object;

    constructor() {
        this.sceneProperties = {
            name: "Ramzan Ads 1920 1080 - 2020",
            layoutID: 12,
            description: "",
            dimensionID: 10
        }
    }

    ngOnInit() {
        // 
    }

    onClickSceneInfo(event) {
        this.sceneInfoEvent.emit(event);
    }

    onZoomin() {
        let elem = document.getElementById('canvasWrapperScene');

        if (this.currentZoomLevel > 0) {
            this.currentZoomLevel = --this.currentZoomLevel;
            let zoomLevel = Number(this.zoomArr[this.currentZoomLevel]);
            elem.style.transform = `scale(${Number(zoomLevel)})`;

            this.zoomLevelLabel = (zoomLevel * 100) + '%';
        }

    }

    onZoomOut() {
        let elem = document.getElementById('canvasWrapperScene');

        if (this.currentZoomLevel < this.zoomArr.length - 1) {
            this.currentZoomLevel = ++this.currentZoomLevel;
            let zoomLevel = Number(this.zoomArr[this.currentZoomLevel]);
            elem.style.transform = `scale(${Number(zoomLevel)})`;

            this.zoomLevelLabel = (zoomLevel * 100) + '%';
        }
    }

    onResetZoom() {
        let elem = document.getElementById('canvasWrapperScene');
        elem.style.transform = `scale(${Number(this.zoomArr[this.zoomLevel])})`;
        this.currentZoomLevel = 4;

        this.zoomLevelLabel = (this.zoomLevel * 100) + '%';
    }

    onSaveScene(event) {
        this.saveScene.emit(event);
    }

}