<form [formGroup]="myForm" (ngSubmit)="onSave()">
  <div class="pl25 pr25 pt10 pb10">
    <h2 mat-dialog-title>Scheduler Playlist</h2>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <app-alert
            *ngIf="alertMessage['message']"
            [message]="alertMessage['message']"
            [error]="alertMessage['error']"
          ></app-alert>
          <div class="row">
            <div class="col-xs-6">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  placeholder="Name"
                  name="name"
                  formControlName="name"
                  autocomplete="off"
                />
                <mat-error *ngIf="myForm.get('name').hasError('required')"
                  >Field is required</mat-error
                >
                <mat-error *ngIf="myForm.get('name').hasError('minlength')"
                  >Min length should be 5</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row mt10 mb10">
            <div class="col-sm-6">
              <mat-form-field class="example-full-width">
                <mat-label>Player</mat-label>
                <!-- <input type="text" matInput placeholder="Player" name="player" formControlName="player"                  [matAutocomplete]="playerAuto" /> -->
                <mat-select
                  placeholder="Player"
                  formControlName="player"
                  (selectionChange)="onPlayerSelected($event)"
                >
                  <mat-option *ngFor="let option of options" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
                <!-- <mat-autocomplete #playerAuto="matAutocomplete" [displayWith]="displayFn"                  (optionSelected)="onPlayerSelected($event)">                  <mat-option *ngFor="let option of filteredPlayerData | async" [value]="option">{{ option.name }}                  </mat-option>                </mat-autocomplete> -->
                <mat-error *ngIf="myForm.get('player').hasError('required')"
                  >Field is required</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div class="row mt10 mb10">
            <div class="col-sm-12">
              <mat-form-field>
                <mat-label>Playlist</mat-label>
                <mat-select
                  name="playlist"
                  formControlName="playlist"
                  placeholder="Playlists"
                  [multiple]="true"
                >
                  <mat-select-search
                    name="playlistMultiCtrl"
                    formControlName="playlistMultiCtrl"
                  ></mat-select-search>
                  <mat-option
                    *ngFor="let option of filteredPlOptions | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="false">Field is required</mat-error>
            </div>
          </div>
          <div class="row mt10 mb10" *ngIf="selectedPlaylists.length">
            <div class="col-sm-12">
              <label for=""
                >Selected Playlist:
                <span class="duration-total"
                  >Total Duration: {{ totalDuration }}s /
                  {{ totalDuration | minuteSeconds }}</span
                ></label
              >
              <table class="selected-playlist">
                <tr>
                  <th>Name</th>
                  <th>Sort</th>
                  <th>Duration</th>
                </tr>
                <tr *ngFor="let playlist of selectedPlaylists; let i = index">
                  <td>{{ playlist.name }}</td>
                  <td>
                    <input
                      type="number"
                      [value]="i + 1"
                      class="sort-order-required"
                      placeholder="Sort"
                    />
                  </td>
                  <td>{{ playlist.duration | minuteSeconds }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt10 mb10">
        <div class="col-sm-10">
          <mat-radio-group
            color="primary"
            name="hasExpiry"
            formControlName="hasExpiry"
          >
            <mat-radio-button class="radio" value="no-expiry">
              No Expiry
            </mat-radio-button>
            <mat-radio-button class="radio" value="period">
              Set Period
            </mat-radio-button>
            <mat-radio-button class="radio" value="recurring">
              Recurring Playlist
            </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="myForm.get('hasExpiry').hasError('required')"
            >Field is required</mat-error
          >
        </div>
      </div>
      <!-- Set period-->
      <div
        class="row mt10 mb10"
        *ngIf="myForm.get('hasExpiry').value === 'period'"
      >
        <div class="col-sm-10">
          <div class="row">
            <div class="col-sm-6">
              <mat-label>From</mat-label>
              <ejs-datetimepicker
                id="datetimepicker"
                placeholder="Start Time"
                [value]="startsOn"
                (change)="onStartDateChange($event)"
              ></ejs-datetimepicker>
            </div>
            <div class="col-sm-6">
              <mat-label>To</mat-label>
              <ejs-datetimepicker
                id="datetimepicker"
                placeholder="End Time"
                [value]="endsOn"
                (change)="onEndDateChange($event)"
              ></ejs-datetimepicker>
            </div>
          </div>
        </div>
      </div>

      <!-- Recurring Playlist-->
      <div
        class="row mt10 mb10"
        *ngIf="myForm.get('hasExpiry').value === 'recurring'"
      >
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-3">
              <mat-label>Start time</mat-label>
              <ejs-timepicker
                id="recurStartTime"
                [value]="recurringStartTime"
                (change)="onRecurringStartTimeChange($event)"
              ></ejs-timepicker>
            </div>
            <div class="col-sm-3">
              <mat-label>End time</mat-label>
              <ejs-timepicker
                id="recurEndTime"
                [value]="recurringEndTime"
                (change)="onRecurringEndTimeChange($event)"
              ></ejs-timepicker>
            </div>
          </div>
        </div>
      </div>
      <!-- Recurring Playlist-->
      <div class="mt25" *ngIf="myForm.get('hasExpiry').value === 'recurring'">
        <div>
          <div class="row mb10">
            <div class="col-sm-12">
              <mat-label
                >Choose Weekdays for the playlist to be repeated</mat-label
              >
            </div>
            <div class="col-sm-12">
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(0)"
                (change)="doSelectWeekday(0)"
                >Sun</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(1)"
                (change)="doSelectWeekday(1)"
                >Mon</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(2)"
                (change)="doSelectWeekday(2)"
                >Tue</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(3)"
                (change)="doSelectWeekday(3)"
                >Wed</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(4)"
                (change)="doSelectWeekday(4)"
                >Thu</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(5)"
                (change)="doSelectWeekday(5)"
                >Fri</mat-checkbox
              >
              <mat-checkbox
                color="primary"
                [checked]="isWeekdayPresent(6)"
                (change)="doSelectWeekday(6)"
                >Sat</mat-checkbox
              >
            </div>
          </div>
          <div class="row mb20">
            <div class="col-sm-3">
              <mat-label>Starts On</mat-label>
              <ejs-datepicker
                id="recurring-starts-on"
                [value]="recurringStartDate"
                (change)="onRecurringStartDateChange($event)"
              ></ejs-datepicker>
            </div>
            <div class="col-sm-3">
              <mat-label>Repeat Till</mat-label>
              <ejs-datepicker
                id="recurring-ends-on"
                [value]="recurringEndDate"
                (change)="onRecurringEndDateChange($event)"
              ></ejs-datepicker>
            </div>
          </div>
          <mat-error *ngIf="hasWeekdayError">
            Choose weekday, recurring date and submit the scheduler
          </mat-error>
        </div>
      </div>
      <div class="mt25 mb20">
        <div class="row mb10">
          <div class="col-sm-12">
            <mat-slide-toggle
              color="primary"
              checked="false"
              name="hasTouch"
              formControlName="hasTouch"
            >
              Touch Widget
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row mb10">
          <div class="col-sm-12">
            <mat-slide-toggle
              color="primary"
              checked="false"
              name="isDummyPlaylist"
              formControlName="isDummyPlaylist"
            >
              Play this when screen is empty or no playlist available
            </mat-slide-toggle>
            <mat-error *ngIf="errDummyPlaylist"
              >You cannot make a multiple playlist into dummy
              playlist</mat-error
            >
          </div>
        </div>
        <div class="row mb10">
          <div class="col-sm-12">
            <mat-slide-toggle
              color="primary"
              checked="true"
              name="isPublished"
              formControlName="isPublished"
            >
              Do you want to publish this playlist on Submit?
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row mb10">
          <div class="col-sm-12">
            <mat-slide-toggle
              color="primary"
              checked="true"
              name="repeat"
              formControlName="repeat"
            >
              Loop Scenes inside this playlist
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button type="button" mat-button [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        type="submit"
        mat-button
        [disabled]="!myForm.valid || isSubmitButtonDisabled"
        color="primary"
      >
        Schedule
      </button>
    </mat-dialog-actions>
  </div>
</form>
