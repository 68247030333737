<!-- <div class="card lg">
  <h3>Storage</h3>
  <h2>24GB</h2>
  <small>Used</small>
  <div class="storage-info">
    <span>Used 2Gb / 5Gb</span>
    <div class="storage-graph">
      <div class="storage-used-graph"></div>
    </div>
  </div>
</div>
<div class="card">
  <h3>Players</h3>
  <h2>64</h2>
  <small>Added</small> <img src="assets/ic-player.png" alt="" />
</div>
<div class="card">
  <h3>Online</h3>
  <h2>24</h2>
  <small>As of Now</small> <img src="assets/ic-player.png" alt="" />
</div>
<div class="card">
  <h3>Offline</h3>
  <h2>24</h2>
  <small>As of Now</small> <img src="assets/ic-player.png" alt="" />
</div> -->
<div class="clearfix"></div>
<app-module-header
  title="Players"
  [isBackEnabled]="false"
  [menuItems]="bulkMenuItems"
  [showBulkMode]="true"
  [showAddButton]="true"
  (onMenuClicked)="onMenuClicked($event)"
  (onSearch)="onSearch($event)"
  (onAdd)="onAdd()"
></app-module-header>
<div class="clearfix"></div>
<div class="tableWrapper">
  <table width="100%" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="_id">
      <th width="40" mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox
          color="primary"
          (change)="doSelectAll()"
          [checked]="isAllChecked()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(element._id)"
          (change)="doSelect(element._id)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        <h2>{{ element.name || "New Player" }}</h2>
      </td>
    </ng-container>
    <ng-container matColumnDef="dimension">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Max Supported Dim.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.dimension || "Nil" }}
      </td>
    </ng-container>
    
    <ng-container matColumnDef="timezone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Timezone</th>
      <td mat-cell *matCellDef="let element">
        {{ element.timezone || "Nil" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="playerId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Player ID</th>
      <td mat-cell *matCellDef="let element">{{ element.playerId }}</td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
      <td mat-cell *matCellDef="let element">
        {{ element.location || "Nil" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="storage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Storage</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.storage
            ? element.storage + "- (used " + element.used_size + ")"
            : "No storage info"
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        <span
          class="label"
          [ngClass]="{
            'label-success': element.is_online,
            'label-danger': !element.is_online
          }"
          >{{ showStatus(element.is_online) }}</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">
        Action
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <div *ngIf="element.status == 'A'">
          <button
            mat-button
            class="mat-button-custom"
            (click)="doEditPlayer(element._id)"
            title="Edit"
          >
            <img src="assets/edit.png" alt="" width="16" />
          </button>
          <button
            mat-button
            class="mat-button-custom"
            (click)="openDialog(element._id)"
            title="Delete"
          >
            <img src="assets/delete.png" alt="" width="16" />
          </button>
          <!-- Show only if the player is registered -->
          <button
            class="btn btn-sm btn-success"
            (click)="handleShowCode(element.playerId, $event)"
          >
            Show Code 
          </button>
        </div>
        <div *ngIf="element.status == 'P'">
          <button mat-button (click)="onApprovePlayer(element._id)">
            Approve
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="clearfix"></div>
<app-paginate
  [length]="totalCount"
  [pageSize]="pageSize"
  (onPageChange)="onPageChange($event)"
></app-paginate>
