import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/module/shared.module";
import { AdminSlabFormComponent } from './form/form.component';
import { AdminSlabListComponent } from './list/list.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    AdminSlabListComponent,
    AdminSlabFormComponent
  ] 
})
export class AdminSlabsModule {}
