import { Component, OnInit, ViewChild } from "@angular/core";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, BaseChartDirective, Label } from "ng2-charts";
import * as pluginAnnotations from "chartjs-plugin-annotation";
import { MatTableDataSource } from "@angular/material/table";

import { PlayersService } from "../players/services/players.service";
import { PlayListService } from "../playlists/services/playlist.service";

@Component({
  selector: "app-dashboard-analytics",
  templateUrl: "./dashboard-analytics.component.html",
  styleUrls: ["./dashboard-analytics.component.scss"],
})
export class DashboardAnalyticsComponent implements OnInit {
  crumbItems: Array<Object>;
  displayedColumns: string[] = ["playerId", "location", "created_on", "status"];

  displayedColumnsPlaylist: string[] = [
    "name",
    "dimension",
    "duration",
    "scene_count",
  ];
  dataSourcePlaylists = new MatTableDataSource();
  playersLength: Number = 0;
  offlinePlayers: Array<any> = [];

  public lineChartData: ChartDataSets[] = [
    { data: [55, 40, 65, 59, 80, 81, 56], label: "Player 1" },
    { data: [27, 90, 28, 48, 40, 19, 86], label: "Player 2" },
    {
      data: [270, 400, 180, 480, 770, 90, 1000],
      label: "Player 3",
      yAxisID: "y-axis-1",
    },
  ];
  public lineChartLabels: Label[] = [
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
        },
        {
          id: "y-axis-1",
          position: "right",
          gridLines: {
            color: "rgba(255,0,0,0.3)",
          },
          ticks: {
            fontColor: "red",
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
    {
      // dark grey
      backgroundColor: "rgba(77,83,96,0.2)",
      borderColor: "rgba(77,83,96,1)",
      pointBackgroundColor: "rgba(77,83,96,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(77,83,96,1)",
    },
    {
      // red
      backgroundColor: "rgba(255,0,0,0.3)",
      borderColor: "red",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
    },
  ];
  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [pluginAnnotations];
  dataSource = new MatTableDataSource();

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  ngOnInit() {
    this.crumbItems = [
      {
        label: "Dashboard",
        path: null,
        current: false,
      },
      {
        label: "Zone Editor",
        path: "dashboard/zone-editor",
        current: true,
      },
    ];

    // setInterval(() => {
    //   this.randomize();
    // }, 5000);

    this.getAllPlayers(1, 100000);
    this.getAllPlaylists(1, 100000, "");
  }

  constructor(
    private _service: PlayersService,
    private _plService: PlayListService
  ) {}

  getAllPlayers(page, limit) {
    this._service.getPlayers(page, limit).then((res) => {
      this.dataSource.data = res["data"];
      this.playersLength = res["data"].length;
      this.offlinePlayers = res["data"].filter((player) => {
        return !player.is_online;
      });
    });
  }

  getAllPlaylists(page, limit, searchQry = "") {
    this._plService.getPlaylists(page, limit, searchQry).then((res) => {
      this.dataSourcePlaylists.data = res["data"];
    });
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number) {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }
}
