<div class="row">
  <div class="col-xs-12">
    <h2>HTML Viewer Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Image Name"
        [(ngModel)]="htmlViewerWidgetProps.name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>URL</mat-label>
      <input
        matInput
        placeholder="http://www.example.com/love.jpg"
        [(ngModel)]="htmlViewerWidgetProps.url"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-6 mb10">
    <mat-form-field appearance="legacy">
      <input
        matInput
        placeholder="Refresh Rate in Seconds"
        [(ngModel)]="htmlViewerWidgetProps.refreshRate"
        value="30s"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>