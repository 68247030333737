import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";

import { CustomModalComponent } from "src/app/shared/components/custom-modal/custom-modal.component";
import { AdminSlabService } from "../services/slabs.service";

@Component({
    selector: "app-admin-slabs-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
})

export class AdminSlabListComponent implements OnInit {
    bulkMenuItems: Array<string>;
    slabs: Array<Object> = [];
    totalCount: number = 0;
    checkedList: Array<string> = [];
    searchQry: string = '';

    constructor(
        private _route: Router,
        public dialog: MatDialog,
        private _service: AdminSlabService
    ) { }

    ngOnInit() {
        this.bulkMenuItems = ["Delete"];
        this.getAllSlabs(1, 10);
    }

    getAllSlabs(page, limit, search = '') {
        this._service.getSlabs(page, limit, search).then((res) => {
            this.slabs = res["data"];
            this.totalCount = res["count"];
        });
    }

    onMenuClicked(index: number) {
        switch (index) {
            case 0:
                // this.deleteIdsArray(this.checkedList);
                if (this.checkedList.length === 0) {
                    this.openNoItemsSelectedDialog();
                } else {
                    this.openDialog(this.checkedList);
                }

                break;

            default:
        }
    }

    onAdd(event: any) {
        this._route.navigate(["/admin/slabs/add"]);
    }

    onEdit(id): void {
        this._route.navigate(["/admin/slabs/edit/" + id]);
    }

    onPageChange(event: PageEvent) {
        this.getAllSlabs(++event.pageIndex, event.pageSize, this.searchQry);
    }

    openDialog(dimensionID) {
        const dialogRef = this.dialog.open(CustomModalComponent, {
            width: "400px",
            data: {
                title: "Confirm Deletion",
                content:
                    "Do you want to delete this entry from listing?  There is no UNDO!!!",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteIdsArray(Array.isArray(dimensionID) ? dimensionID : [dimensionID]);
            }
        });
    }

    openNoItemsSelectedDialog() {
        const dialogRef = this.dialog.open(CustomModalComponent, {
            width: "400px",
            data: {
                title: "No Items",
                content:
                    "Choose items and delete!!!",
            },
        });
    }

    deleteIdsArray(arrayOfIds) {
        let postBody = {
            ids: arrayOfIds,
        };
        this._service.deleteSlab(postBody).then((res) => {
            if (res["status"] === 200) {
                this.getAllSlabs(1, 10);
            }
        });
    }

    isChecked(id) {
        return this.checkedList.includes(id);
    }

    isAllChecked() {
        return this.checkedList.length === this.slabs.length;
    }

    doSelect(id) {
        if (this.isChecked(id)) {
            this.checkedList = _.without(this.checkedList, id);
        } else {
            this.checkedList.push(id);
        }
    }

    doSelectAll() {
        if (this.checkedList.length === 0) {
            this.checkedList = this.slabs.map((elem, index) => elem["_id"]);
        } else {
            this.checkedList = [];
        }
    }

    onSearch(searchQry: any) {
        this.searchQry = searchQry;
        this.getAllSlabs(1, 10, searchQry);
    }
}
