<div class="row">
  <div class="col-xs-12">
    <h2>Clock Widget Properties</h2>
    <hr class="widget-header mt0 mb0" />
  </div>
</div>
<div class="row mt30">
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-label>Widget Name</mat-label>
      <input matInput placeholder="Name" [(ngModel)]="widgetProps.name" />
    </mat-form-field>
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Hr Format" [(ngModel)]="widgetProps.hrFormat">
        <mat-option value="H">24 Hr Format</mat-option>
        <mat-option value="h">12 Hr Format</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt10 mb10" style="height: 50px">
    <mat-label class="widget-mat-label">BG Color</mat-label>
    <div class="clearfix"></div>
    <input
      [(colorPicker)]="widgetProps.backgroundColor"
      [style.background]="widgetProps.backgroundColor"
      class="color-picker"
    />
  </div>
  <div class="col-xs-12 mb10">
    <mat-form-field appearance="legacy">
      <mat-select placeholder="Timezone" [(ngModel)]="widgetProps.timeZone">
        <mat-option [value]="zone" *ngFor="let zone of timeZonesList"
          >{{zone}}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Position</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Left"
            [(ngModel)]="widgetProps.coords.left"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Top"
            [(ngModel)]="widgetProps.coords.top"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mt15">
    <mat-label class="widget-mat-label">Size</mat-label>
    <div class="row mt15">
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Width"
            [(ngModel)]="widgetProps.size.width"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field class="example-full-width">
          <input
            type="number"
            matInput
            placeholder="Height"
            [(ngModel)]="widgetProps.size.height"
          />
          <span matSuffix>px &nbsp;</span>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-xs-12 mb35">
    <button
      class="widget-button"
      mat-button
      color="primary"
      (click)="saveWidgetProps()"
    >
      Save Widget
    </button>
  </div>
</div>