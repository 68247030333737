import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import "fabric";
import { NgxSpinnerService } from "ngx-spinner";

import { ZoneEditorService } from "../../services/zone-editor.services";

declare let fabric;

// Fabric prototype
fabric.Object.prototype.set({
  transparentCorners: false,
  borderColor: "#333",
  cornerColor: "#333",
});

@Component({
  selector: "app-zone-editor-window",
  templateUrl: "./zone-editor.component.html",
  styleUrls: ["./zone-editor.component.scss"],
})
export class ZoneEditorComponent implements OnInit {
  content: {};
  showSaveBtn: Boolean;
  showCancelBtn: Boolean;

  // Actual Dimensions
  public actualWidth;
  public actualHeight;

  // Zoom level
  public zoomPerc = 0.25;

  // Canvas helpers
  public canvas;
  public canvasHeight;
  public canvasWidth;

  // Get active element dimensions
  activeObjectWidth: number = 0;
  activeObjectHeight: number = 0;
  activeObjectOffsetTop: number = 0;
  activeObjectOffsetLeft: number = 0;

  alertMessage: Object = {
    message: "",
    error: false,
  };
  canvasId: number = 1;

  ngOnInit() {
    if (!history.state.name) {
      this._router.navigate(["/manage/zone-editor/"]);
    }

    this.actualWidth = history.state.width;
    this.actualHeight = history.state.height;

    // Canvas dimensions
    this.canvasHeight = this.actualHeight * this.zoomPerc;
    this.canvasWidth = this.actualWidth * this.zoomPerc;

    // Initialize fabric container
    let options = {
      width: this.canvasWidth,
      height: this.canvasHeight,
      backgroundColor: null,
    };

    this.content = history.state;
    this.canvas = new fabric.Canvas("screenLayoutEditorCanvasWrap", options);

    this.canvas.setBackgroundImage(
      "./assets/background-white.png",
      this.canvas.renderAll.bind(this.canvas),
      {
        width: this.canvas.width,
        height: this.canvas.height,
        originX: "left",
        originY: "top",
      }
    );
  }

  constructor(
    private ref: ChangeDetectorRef,
    private _router: Router,
    private _service: ZoneEditorService,
    private spinner: NgxSpinnerService
  ) {}

  onAdd() {
    try {
      var id = ++this.canvasId;

      let rect = new fabric.Rect({
        width: 50,
        id: id,
        height: 50,
        left: 0,
        top: 0,
        hasRotatingPoint: false,
        transparentCorners: false,
        fill: "#ddd",
        stroke: "#333",
        cornerStyle: "circle",
        noScaleCache: false,
        cornerSize: 10,
        strokeUniform: true,
        backgroundColor: "#ffffff",
      });

      this.canvas.getObjects();
      this.canvas.add(rect);
      this.canvas.setActiveObject(this.canvas.item(id));
      this.canvas.selection = true;
      this.canvas.renderAll();
      this.canvas.calcOffset();
    } catch (error) {}
  }

  onObjectSelection() {
    this.activeObjectWidth = this.canvas.getActiveObject()
      ? Math.round(
          this.canvas.getActiveObject().width *
            this.canvas.getActiveObject().scaleX *
            4
        )
      : 0;
    this.activeObjectHeight = this.canvas.getActiveObject()
      ? Math.round(
          this.canvas.getActiveObject().height *
            this.canvas.getActiveObject().scaleY *
            4
        )
      : 0;
    this.activeObjectOffsetTop = this.canvas.getActiveObject()
      ? Math.round(this.canvas.getActiveObject().top * 4)
      : 0;
    this.activeObjectOffsetLeft = this.canvas.getActiveObject()
      ? Math.round(this.canvas.getActiveObject().left * 4)
      : 0;
    this.ref.markForCheck();
  }

  onDelete() {
    this.canvas.remove(this.canvas.getActiveObject());
    this.activeObjectWidth = 0;
    this.activeObjectHeight = 0;
    this.activeObjectOffsetTop = 0;
    this.activeObjectOffsetLeft = 0;
  }

  onZoomChange(zoomLevel) {
    if (zoomLevel > 0) {
      this.canvas.setZoom(zoomLevel * 0.5);
      this.canvas.setDimensions({
        width: this.canvasWidth * zoomLevel * 0.5,
        height: this.canvasHeight * zoomLevel * 0.5,
      });
    }
  }

  onWidthChange(event) {
    var shape = this.canvas.getActiveObject();
    var width = (parseInt(event.target.value) / shape.scaleX) * 0.25;
    shape.set({ width: width });
    shape.setCoords();
    this.canvas.renderAll();
  }

  onHeightChange(event) {
    var shape = this.canvas.getActiveObject();
    var height = (parseInt(event.target.value) / shape.scaleY) * 0.25;
    shape.set({ height: height });
    shape.setCoords();
    this.canvas.renderAll();
  }

  onLeftChange(event) {
    let shape = this.canvas.getActiveObject();
    shape.set({ left: event.target.value * 0.25 });
    shape.setCoords();
    this.canvas.renderAll();
  }

  onTopChange(event) {
    let shape = this.canvas.getActiveObject();
    shape.set({ top: event.target.value * 0.25 });
    shape.setCoords();
    this.canvas.renderAll();
  }

  onSave() {
    let canvasJson = this.canvas.toJSON();
    canvasJson.canvasWidth = this.canvasWidth;
    canvasJson.canvasHeight = this.canvasHeight;

    if (this.canvas.getObjects().length === 0) {
      this.alertMessage = {
        message: "No Canvas Info was found",
        error: true,
      };
    } else {
      this.alertMessage = {
        message: "Empty Canvas",
        error: false,
      };

      // Save this json to postBody passed from previous controller
      this.content["content_json"] = JSON.stringify(canvasJson);
      this.content["thumbnail_path"] = this.canvas.toDataURL("png");

      this.spinner.show();
      this._service.setZone(this.content).then((res) => {
        if (res["OK"]) {
          this._router.navigate(["/manage/zone-editor/"]);
        } else {
          this.alertMessage = {
            message: "Error occurred : " + res["message"],
            error: true,
          };
        }
        this.spinner.hide();
      });
    }
  }

  onCancel() {
    this._router.navigate(["/manage/zone-editor/"]);
  }
}
