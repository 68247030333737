<div class="groupContainer" *ngIf="value['playergroups']">
  <div class="row">
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-12">
          <span class="purple-dot pull-left"></span>
          <h3
            class="player-title"
            title="{{ value['playergroups'].name }}, {{
              value['playergroups'].location
            }}"
          >
            {{ value['playergroups'].name }}
          </h3>
        </div>
      </div>
    </div>
    <div class="col-sm-2 text-right">
      <mat-icon (click)="onDeleteEvent(value['_id'])" matSuffix>delete</mat-icon>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row mb10">
    <div class="col-sm-8">
      <small>{{ value['name'] }}</small>
    </div>
    <div class="col-sm-4 text-right">
      <small>{{ value['players'].length }} Players</small>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-sm-12">
      <div class="playlistItems">
        <div class="row" *ngFor="let playlist of value['playlists']">
          <div class="col-sm-9">
            <img src="assets/playlist-sm.png" alt="" class="pull-left" />
            <h3 class="pull-left playlist-item-title">{{ playlist.name }}</h3>
          </div>
          <div class="col-sm-3 text-right">
            <!-- <mat-icon
              (click)="
                onDeletePlaylist({
                  eventId: value['_id'],
                  playListId: playlist['_id']
                })
              "
              matSuffix
              >delete</mat-icon
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>